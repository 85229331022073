import { z } from 'zod';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { CollectibleType, CustomCollectible } from '@sportscardinvestor/schemas';
import { getCollectibleBoxTypeName } from '@sportscardinvestor/collectible-helpers';
import { CustomQueryField, customQueryFormSchema, getCustomQueryInitialValues } from './customQuery';
import { ImageUrlField, getImageUrlFormInitialValues, imageUrlFormSchema } from './imageUrl';
import { CurrentValueField, currentValueFormSchema, getCurrentValueInitialValues } from './currentValue';
import { SetField, getSetInitialValues, setFormSchema } from './set';
import { useOnValueChange } from '@/hooks/useOnValueChange';
import { EditForm, EditFormButtons } from '@/sci-ui-components/forms/EditForm';
import { ConvertPriceFn } from '@/hooks/useCurrencyFormatter';
import FormSection from '@/sci-ui-components/forms/FormSection/FormSection';
import FormFieldsRow from '@/sci-ui-components/forms/FormFieldsRow/FormFieldsRow';
import FieldLabelBox from '@/sci-ui-components/forms/FieldLabelBox/FieldLabelBox';
import { CardGradesAutoComplete } from '@/features/cardGrades/CardGradesAutoComplete';

const formSchema = z
  .object({
    boxTypeName: z
      .string({
        required_error: 'Please enter box type',
      })
      .trim()
      .min(1),
  })
  .and(customQueryFormSchema)
  .and(imageUrlFormSchema)
  .and(currentValueFormSchema)
  .and(setFormSchema);

type FormSchema = z.input<typeof formSchema>;
type FormPayload = z.output<typeof formSchema>;

export type OnEditSealedCustomCollectionItemFormSubmitFn = (payload: FormPayload) => void;

export function EditSealedCustomCollectionItemForm({
  collectibleId,
  collectibleType,
  initialValues,
  onSubmit,
  formId,
  onCancel,
  isLoading,
}: {
  collectibleType: CollectibleType;
  collectibleId: number | null;
  initialValues: FormSchema;
  onSubmit: OnEditSealedCustomCollectionItemFormSubmitFn;
  formId: string;
  onCancel: () => void;
  isLoading: boolean;
}) {
  const form = useForm<FormSchema, unknown, FormPayload>({
    resolver: zodResolver(formSchema),
    defaultValues: initialValues,
    mode: 'onSubmit',
  });
  useOnValueChange(initialValues, () => form.reset(initialValues));
  const handleSubmit = form.handleSubmit((payload: FormPayload) => onSubmit(payload));

  return (
    <FormProvider {...form}>
      <EditForm id={formId} onSubmit={handleSubmit}>
        <FormSection>
          <FormFieldsRow>
            <SetField />
            <Controller
              name="boxTypeName"
              control={form.control}
              render={({ field, fieldState }) => (
                <FieldLabelBox label="Box Type" fieldId="boxTypeName" error={fieldState.error?.message}>
                  <CardGradesAutoComplete
                    id="boxTypeName"
                    value={field.value}
                    onSelect={(data) => {
                      form.setValue('boxTypeName', data?.name ?? '');
                    }}
                    onChange={(value) => {
                      form.setValue('boxTypeName', value);
                    }}
                  />
                </FieldLabelBox>
              )}
            />
          </FormFieldsRow>
        </FormSection>
        <FormSection title="Optional Fields" variant="secondary" noPadding>
          <div className="flex items-start gap-5 flex-wrap">
            <FormSection noPadding className="flex-grow-0">
              <ImageUrlField collectibleType={collectibleType} initialImageUrl={initialValues.imageUrl} />
            </FormSection>
            <FormSection noPadding className="flex-grow">
              <FormFieldsRow>
                <CurrentValueField collectibleId={collectibleId} collectibleType={collectibleType} />
              </FormFieldsRow>
              <FormFieldsRow>
                <CustomQueryField />
              </FormFieldsRow>
            </FormSection>
          </div>
        </FormSection>
        <EditFormButtons className="m-4" onCancel={onCancel} isLoading={isLoading} />
      </EditForm>
    </FormProvider>
  );
}

export function getEditSealedCustomCollectionItemFormInitialValues({
  collectible,
  convertPriceFromUSD,
}: {
  collectible: CustomCollectible | null;
  convertPriceFromUSD: ConvertPriceFn;
}): FormSchema {
  if (!collectible) {
    return {
      boxTypeName: '',
      setName: '',
    };
  }
  return {
    ...getCustomQueryInitialValues({ collectible }),
    ...getImageUrlFormInitialValues({ collectible }),
    ...getCurrentValueInitialValues({
      collectible,
      convertPriceFromUSD,
    }),
    ...getSetInitialValues({ collectible }),
    boxTypeName: getCollectibleBoxTypeName(collectible) ?? '',
  };
}
