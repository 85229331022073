import React, { useMemo, useState } from 'react';
import { Tooltip } from 'antd';
import clsx from 'clsx';
import { CustomCollectible } from '@sportscardinvestor/schemas';
import { getCollectibleType, makeCollectibleDescriptions } from '@sportscardinvestor/collectible-helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import CollectibleImage from '../../../sci-ui-components/collectibles/CollectibleImage/CollectibleImage';
import CommonCollectibleDescription from '../../../sci-ui-components/collectibles/CommonCollectibleDescription/CommonCollectibleDescription';
import TextField from '../../../sci-ui-components/forms/TextField/TextField';
import { formatDate } from '../../../sci-ui-components/utils/date';
import PriceField from '../../../sci-ui-components/forms/PriceField/PriceField';
import Button from '../../../sci-ui-components/buttons/Button/Button';
import WidgetHeader from '../../../sci-ui-components/widgets/WidgetHeader/WidgetHeader';
import { useRawCompletedSalesSearch } from '../../sales/raw/useRawCompletedSalesSearch';
import classes from './EditManualCollectibleValueForm.module.scss';
import RawSalesMinimalistList from './RawSalesMinimalistList';
import useCurrencyFormatter from 'hooks/useCurrencyFormatter';
import { temp_convertCustomCollectibleToOldCustomCollectible } from '@/features/collectibles/helpers/temp_convertCustomCollectibleToOldCustomCollectible';
import { marketplaceHelpers } from '@/services/marketplaceHelpers';
import { useOnValueChange } from '@/hooks/useOnValueChange';
import { useLastDayAverageSalePrice } from '@/features/sales/raw/useLastDayAverageSalePrice';
import useDebouncedValue from '@/sci-ui-components/hooks/useDebouncedValue';

export type OnSubmitFn = (params: {
  newCurrentPriceInDisplayCurency?: number;
  newCurrentPrice?: number;
  newCustomQuery: string | null;
}) => void;

export function EditManualCollectibleValueForm({
  customCollectible,
  onCancel,
  isLoading,
  onSubmit,
  onUpdateCustomQuery,
}: {
  customCollectible: CustomCollectible;
  onCancel: () => void;
  onSubmit: OnSubmitFn;
  onUpdateCustomQuery: (params: { newCustomQuery: string | null }) => void;
  isLoading: boolean;
}) {
  const { convertPriceFromUSD } = useCurrencyFormatter();
  const collectibleType = getCollectibleType(customCollectible);
  const oldCustomCollectible = useMemo(
    () => temp_convertCustomCollectibleToOldCustomCollectible(customCollectible),
    [customCollectible]
  );
  const generatedQuery = useMemo(() => {
    const query = customCollectible.query;
    if (query) {
      // NOTE: should always be true
      return query;
    }
    const { fullDescription } = makeCollectibleDescriptions(customCollectible);
    return marketplaceHelpers.makeQueryFromDescriptionText(fullDescription, { marketplace: 'raw' });
  }, [customCollectible]);

  const [newCurrentPriceInDisplayCurency, setNewCurrentPriceInDisplayCurency] = useState<number | null>(null);
  const [newCustomQuery, setNewCustomQuery] = useState<string | null>(
    customCollectible?.customQuery || generatedQuery || null
  );
  useOnValueChange(customCollectible.id, () => {
    setNewCurrentPriceInDisplayCurency(null);
    setNewCustomQuery(customCollectible?.customQuery || generatedQuery || null);
  });

  const isUsingGeneratedQuery = useMemo(
    () => newCustomQuery?.trim()?.toLocaleLowerCase() === generatedQuery?.trim()?.toLocaleLowerCase(),
    [newCustomQuery, generatedQuery]
  );
  const isCustomQueryEmpty = !newCustomQuery?.trim()?.length;
  const [debouncedSearchQuery] = useDebouncedValue(newCustomQuery?.trim());
  const { items, isLoading: isLoadingSalesList } = useRawCompletedSalesSearch(
    {
      titleSearchQueryText: debouncedSearchQuery,
      sort: [
        {
          sortBy: 'saleDate',
          sortDirection: 'desc',
        },
        {
          sortBy: 'score',
          sortDirection: 'desc',
        },
      ],
      limit: 10,
    },
    { enabled: !!debouncedSearchQuery?.length }
  );
  const lastDayAverageSalePrice = useLastDayAverageSalePrice({
    items,
  });
  const lastDayAverageSalePriceInDisplayCurrency = convertPriceFromUSD({ value: lastDayAverageSalePrice });

  return (
    <>
      <div className="flex justify-between gap-2 mb-5">
        <div className={classes.imageContainer}>
          <CollectibleImage
            alt={'Collectible Image'}
            url={customCollectible?.imageUrl}
            size="small"
            collectibleType={collectibleType}
            className={clsx(classes.image)}
          />
          <CommonCollectibleDescription collectible={oldCustomCollectible} />
        </div>
        <div className={classes.valueContainer}>
          <WidgetHeader className={classes.headerTitles}>Current Value</WidgetHeader>
          <PriceField
            name="currentValue"
            value={convertPriceFromUSD({ value: customCollectible.price })}
            min={0}
            readOnly
            disabled
            minimumFractionDigits={2}
          />
          {!!customCollectible.priceDate && (
            <div className={classes.updatedAtLabel}>Updated: {formatDate(customCollectible.priceDate)}</div>
          )}
        </div>
        <div className={classes.valueContainer}>
          <WidgetHeader className={classes.headerTitles}>New Value</WidgetHeader>
          <PriceField
            name="newValue"
            value={newCurrentPriceInDisplayCurency || lastDayAverageSalePriceInDisplayCurrency}
            onChange={(value) => setNewCurrentPriceInDisplayCurency(value)}
            min={0}
            maximumFractionDigits={2}
            minimumFractionDigits={2}
          />
          {!newCurrentPriceInDisplayCurency && !!lastDayAverageSalePriceInDisplayCurrency ? (
            <div className={classes.updatedAtLabel}>
              <Tooltip
                title="The New Value is calculated based on the average of the last day's sales."
                placement={'bottom'}
              >
                <FontAwesomeIcon className={classes.infoIcon} icon={faCircleInfo} size="sm" />
                Last Day Average
              </Tooltip>
            </div>
          ) : null}
        </div>
        <div className={classes.saveButtonContainer}>
          <Button
            type={'primary'}
            onClick={() =>
              onSubmit({
                newCurrentPriceInDisplayCurency:
                  newCurrentPriceInDisplayCurency ?? lastDayAverageSalePriceInDisplayCurrency,
                newCustomQuery: isUsingGeneratedQuery || isCustomQueryEmpty ? null : newCustomQuery,
              })
            }
            className={classes.topButton}
            loading={isLoading}
            disabled={isLoading}
          >
            Save Value
          </Button>
          <Button type={'default'} onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </div>
      <div className={classes.queryContainer}>
        <b>Query:</b>
        <div className={classes.collapsible}>
          <TextField
            value={newCustomQuery}
            onChange={setNewCustomQuery}
            isMultiLine
            multilineContainerClassName={classes.queryTextFieldContainer}
          ></TextField>
          <div className="flex flex-col items-stretch gap-2">
            <Button
              onClick={() =>
                onUpdateCustomQuery({
                  newCustomQuery: isUsingGeneratedQuery || isCustomQueryEmpty ? null : newCustomQuery,
                })
              }
              loading={isLoading}
              disabled={!newCustomQuery?.length}
            >
              Save Query
            </Button>
            {!isUsingGeneratedQuery && (
              <Button onClick={() => setNewCustomQuery(generatedQuery)} loading={isLoading} type="link">
                Generate query
              </Button>
            )}
          </div>
        </div>
      </div>
      <RawSalesMinimalistList
        items={items || []}
        isLoading={isLoadingSalesList}
        searchQuery={debouncedSearchQuery}
        handleSaveValue={({ salePrice }) => onSubmit({ newCurrentPrice: salePrice, newCustomQuery })}
      />
    </>
  );
}
