import { CustomCollectible } from '../../../../sci-ui-components/types/collectible';
import { CollectibleType } from '../../../../sci-ui-components/types/collectibleType';
import { OnSwitchToManualFn } from '../types';
import CustomCollectibleForm, { getCustomCollectibleInitialValues } from './custom/CustomCollectibleForm';
import { CustomCollectibleFormValues } from './custom/types';
import NonCustomCollectibleForm from './nonCustom/NonCustomCollectibleForm';
import useCurrencyFormatter from '@/hooks/useCurrencyFormatter';

export default function CollectibleForm({
  collectibleType,
  customCollectibleToEdit,
  dialogId,
  onSwitchToManual,
  initialCustomCollectibleValues,
  onCancel,
  onSubmitCustom,
  onSubmitNonCustom,
  initialCollectibleId,
  initialCustomCollectible,
}: {
  collectibleType: CollectibleType;
  dialogId: string;
  onSwitchToManual: OnSwitchToManualFn;
  onCancel: () => void;
  // non-custom
  initialCollectibleId: number | null;
  onSubmitNonCustom: (collectibleId: number) => void;
  // custom
  customCollectibleToEdit: CustomCollectible | null;
  initialCustomCollectibleValues: CustomCollectibleFormValues | null;
  initialCustomCollectible: CustomCollectible | null;
  onSubmitCustom: (customCollectibleValues: CustomCollectibleFormValues) => void;
}) {
  const { convertPriceFromUSD } = useCurrencyFormatter();
  if (initialCustomCollectibleValues || initialCustomCollectible) {
    // custom collectible
    const initialValues =
      initialCustomCollectibleValues ??
      getCustomCollectibleInitialValues({
        collectible: initialCustomCollectible ?? null,
        collectibleType,
        convertPriceFromUSD,
      });
    return (
      <CustomCollectibleForm
        collectibleType={collectibleType}
        dialogId={dialogId}
        initialValues={initialValues}
        collectibleId={customCollectibleToEdit?.id ?? null}
        onSubmit={onSubmitCustom}
        onCancel={onCancel}
        customCollectibleId={customCollectibleToEdit?.id ?? null}
      />
    );
  }
  return (
    <NonCustomCollectibleForm
      onSwitchToManual={onSwitchToManual}
      initialCollectibleId={initialCollectibleId}
      dialogId={dialogId}
      collectibleType={collectibleType}
      onSubmit={onSubmitNonCustom}
      onCancel={onCancel}
    />
  );
}
