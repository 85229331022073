import { useMemo } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import {
  GetSetVariationFilterOptionsParams,
  SetVariationFilterOption,
  getSetVariationFilterOptions,
} from '../../services/sciApi/search/filters';
import { ApiError } from '../../utils/api';
import useAuth from '../auth/useAuth';
import useFilteredData from 'hooks/useFilteredData';

export type Params = GetSetVariationFilterOptionsParams & {
  searchText?: string | null;
};

export const keyPrefix = 'card-set-variations-filter-options';
type Key = [typeof keyPrefix, GetSetVariationFilterOptionsParams];

export default function useCardSetVariationFilterOptions(
  { searchText, collectibleType, isoEndDate, isoStartDate, cardSetIds, playerIds, sportIds }: Params,
  options: UseQueryOptions<SetVariationFilterOption[], ApiError, SetVariationFilterOption[], Key> = {}
) {
  const { isLoggedIn } = useAuth();
  const queryResult = useQuery(
    [
      keyPrefix,
      {
        collectibleType,
        isoEndDate,
        isoStartDate,
        cardSetIds,
        playerIds,
        sportIds,
      },
    ],
    () =>
      getSetVariationFilterOptions({
        collectibleType,
        isoEndDate,
        isoStartDate,
        cardSetIds,
        playerIds,
        sportIds,
      }),
    {
      staleTime: 1000 * 60 * 60 * 24, // 1 day
      ...options,
      enabled: !!isLoggedIn && (options?.enabled ?? true),
    }
  );

  // TODO: this should be done in API instead
  const filteredData = useFilteredData(queryResult.data, 'label', searchText, []);
  const uniqueData = useMemo(() => {
    const variationsMap = filteredData?.reduce<Map<number, SetVariationFilterOption>>((acc, variation) => {
      if (!acc.has(variation.value)) {
        acc.set(variation.value, variation);
      }
      return acc;
    }, new Map());
    return variationsMap ? Array.from(variationsMap.values()) : [];
  }, [filteredData]);

  return {
    ...queryResult,
    data: uniqueData,
  };
}
