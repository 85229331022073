import { useQuery, UseQueryOptions } from 'react-query';
import { getPlatformStats, GetPlatformStatsParams } from '../../services/sciApi/platformStats/index';
import { ApiError } from '../../utils/api';
import { PlatformStats } from 'sci-ui-components/types/platform';

export const keyPrefix = 'platform-stats';
type Key = [typeof keyPrefix, GetPlatformStatsParams];

export default function usePlatformStats(
  params: GetPlatformStatsParams,
  options: UseQueryOptions<PlatformStats, ApiError, PlatformStats, Key> = {}
) {
  const queryResult = useQuery([keyPrefix, params], async ({ signal }) => getPlatformStats(params, signal), {
    staleTime: 1000 * 60 * 60 * 24, // 1 day
    ...options,
    enabled: options?.enabled ?? true,
  });
  return queryResult;
}
