import { useCallback, useRef } from 'react';
import clsx from 'clsx';
import Cropper, { ReactCropperElement } from 'react-cropper';
import { faRotateLeft, faRotateRight } from '@fortawesome/pro-light-svg-icons';
import { aspectRatiosMap } from '../../collectibles/CollectibleImage/CollectibleImage';
import { CollectibleType } from '../../types/collectibleType';
import Button from '../../buttons/Button/Button';

import classes from './ImageCropper.module.scss';
import 'cropperjs/dist/cropper.css';

export type ImageCropperProps = {
  className?: string;
  imageUrl: string | null;
  onSubmit: (imageDataUrl: string | null) => void;
  collectibleType: CollectibleType | null;
  formId: string;
  aspectRatio?: number;
  autoCropArea?: number;
};

export interface ImageCropperControls {
  getCroppedValue: () => string | null;
}

export default function ImageCropper({
  className,
  imageUrl,
  onSubmit,
  formId,
  collectibleType,
  aspectRatio: aspectRatioProp,
  autoCropArea,
}: ImageCropperProps) {
  const cropperElement = useRef<ReactCropperElement | null>(null);
  const handleRotate = (angleInDegrees: number) => {
    cropperElement.current?.cropper?.rotate(angleInDegrees);
  };
  const handleSubmit = useCallback(() => {
    const cropper = cropperElement?.current?.cropper;
    if (!cropper) {
      // NOTE: should never happen
      return;
    }
    try {
      const imageDataUrl = cropper.getCroppedCanvas()?.toDataURL() ?? null;
      onSubmit(imageDataUrl);
    } catch (err) {
      console.error(err);
    }
  }, [onSubmit]);

  if (!imageUrl) {
    return null;
  }
  const collectibleAspectRatio = collectibleType ? aspectRatiosMap[collectibleType] : undefined;
  const aspectRatio =
    aspectRatioProp || (collectibleAspectRatio ? collectibleAspectRatio[0] / collectibleAspectRatio[1] : undefined);
  return (
    <form
      id={formId}
      className={clsx(classes.root, className)}
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Cropper
        ref={cropperElement}
        src={imageUrl}
        initialAspectRatio={aspectRatio}
        aspectRatio={aspectRatio}
        guides={true}
        autoCropArea={autoCropArea}
      />
      <div className={classes.buttons}>
        <Button faIcon={faRotateLeft} onClick={() => handleRotate(-90)}>
          Rotate left
        </Button>
        <Button faIconRight={faRotateRight} onClick={() => handleRotate(90)}>
          Rotate right
        </Button>
      </div>
    </form>
  );
}
