import { z } from 'zod';
import { Controller, useFormContext } from 'react-hook-form';
import { CollectibleType, CustomCollectible } from '@sportscardinvestor/schemas';
import ManualCollectibleValueTriggerSpan from '../../EditManualCollectibleValueDialog/ManualCollectibleValueTriggerSpan';
import FieldLabelBox from '@/sci-ui-components/forms/FieldLabelBox/FieldLabelBox';
import PriceField from '@/sci-ui-components/forms/PriceField/PriceField';
import { ConvertPriceFn } from '@/hooks/useCurrencyFormatter';

export const currentValueFormSchema = z.object({
  currentValueInDisplayCurrency: z.number().nullable().default(null),
});

type CurrentValueFormSchema = z.input<typeof currentValueFormSchema>;
type CurrentValueFormPayload = z.output<typeof currentValueFormSchema>;

export function CurrentValueField({
  className,
  collectibleId,
  collectibleType,
}: {
  className?: string;
  collectibleType: CollectibleType;
  collectibleId: number | null;
}) {
  const form = useFormContext<CurrentValueFormSchema, unknown, CurrentValueFormPayload>();

  return (
    <Controller
      name="currentValueInDisplayCurrency"
      control={form.control}
      render={({ field, fieldState }) => (
        <FieldLabelBox
          className={className}
          label="Current Value"
          fieldId="currentValueInDisplayCurrency"
          error={fieldState.error?.message}
          adornmentRight={
            collectibleId ? (
              <ManualCollectibleValueTriggerSpan
                collectibleId={collectibleId}
                collectibleType={collectibleType}
                className="text-sm normal-case"
              />
            ) : null
          }
        >
          <PriceField {...field} placeholder="Enter current value..." min={0} id="currentValueInDisplayCurrency" />
        </FieldLabelBox>
      )}
    />
  );
}

export function getCurrentValueInitialValues({
  collectible,
  convertPriceFromUSD,
}: {
  collectible: CustomCollectible | null;
  convertPriceFromUSD: ConvertPriceFn;
}): CurrentValueFormSchema {
  if (!collectible) {
    return {};
  }
  return {
    currentValueInDisplayCurrency: convertPriceFromUSD({ value: collectible?.price }),
  };
}
