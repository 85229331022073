import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts';
import { ValueType, NameType } from 'recharts/types/component/DefaultTooltipContent';
import { useShallowFlyoutStore } from './store';
import useChartData from '@/features/charts/useChartData';
import { DateRangeName, formatDateInTimeZone, getIsoStringDateRangeInDateRange } from '@/sci-ui-components/utils/date';
import useToday from '@/sci-ui-components/hooks/useToday';
import LoadingSpinner from '@/sci-ui-components/LoadingSpinner/LoadingSpinner';
import { GetCollectiblesChartDataResponse } from '@/services/sciApi/charts';
import DashboardStat from '@/sci-ui-components/stats/DashboardStat/DashboardStat';
import useFormatStatValue from '@/hooks/useFormatStatValue';
import useAuth from '@/features/auth/useAuth';

const CustomTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
  if (active && payload && payload.length) {
    return (
      <div className="flex flex-col gap-1 bg-white p-2 border border-gray-300">
        <span className="text-xs text-gray-muted">{formatDateInTimeZone(label)}</span>
        <div className="flex flex-row gap-2">
          <DashboardStat
            value={payload[0].value}
            type="price"
            variant="context"
            size="medium"
            textClassName="!font-semibold"
          />
          {!!payload[0].payload.avgSalePriceChangePercentage && (
            <DashboardStat
              value={payload[0].payload.avgSalePriceChangePercentage}
              type="percentage"
              variant="context"
              size="small"
              highlightChange
            />
          )}
        </div>
      </div>
    );
  }

  return null;
};

function InnerChart({ data }: { data: GetCollectiblesChartDataResponse }) {
  const { formatStatValue } = useFormatStatValue();
  const chartData = data.chartData.map((dayAvg) => ({
    name: dayAvg.date,
    avgSalePrice: dayAvg.metrics?.avgSalePrice,
    avgSalePriceChangePercentage: dayAvg.metrics?.avgSalePriceChangePercentage,
  }));

  const xFormatter = (v: string) => formatDateInTimeZone(v, 'M/dd') || '';

  return (
    <div className="flex min-h-[250px] items-center justify-center">
      <ResponsiveContainer width="100%" height={250}>
        <LineChart data={chartData}>
          <CartesianGrid vertical={false} stroke="#9A9A9A" />
          <XAxis dataKey="name" tickFormatter={xFormatter} tickMargin={8} />
          <YAxis
            axisLine={false}
            tickLine={{ stroke: '#9A9A9A' }}
            width={60}
            domain={['dataMin', 'auto']}
            tickFormatter={(value) => formatStatValue({ value, type: 'price' })}
          />
          <Tooltip content={<CustomTooltip />} />
          <Line
            type="monotone"
            connectNulls
            dataKey="avgSalePrice"
            stroke="#004AD4"
            strokeWidth={2}
            dot={false}
            activeDot={{ fill: '#004AD4' }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default function FlyoutChart() {
  const { collectibleId, dateRangeInDays, collectibleType } = useShallowFlyoutStore(
    'collectibleId',
    'dateRangeInDays',
    'collectibleType'
  );
  const { isLoggedIn } = useAuth();

  const dateRangeName: DateRangeName = `Last ${dateRangeInDays} days`;
  const today = useToday();

  const { data, isLoading } = useChartData(
    {
      collectibleIds: collectibleId ? [collectibleId] : [],
      advancedFilters: {},
      collectibleType,
      isPublic: !isLoggedIn,
      dateRange: getIsoStringDateRangeInDateRange(today, dateRangeName),
    },
    {
      enabled: !!collectibleId,
    }
  );

  if (isLoading)
    return (
      <div className="flex min-h-[250px] items-center justify-center">
        <LoadingSpinner size="large" />
      </div>
    );

  if (!data || !data.chartData.length)
    return (
      <div className="flex min-h-[250px] items-center justify-center">No chart data. Expand the date range below.</div>
    );

  return <InnerChart data={data} />;
}
