import { useCallback } from 'react';
import useLocalStorageValue from '../../../hooks/useLocalStorageValue';
import useAnalytics from '../../analytics/useAnalytics';
import useCollectibleType from '../../collectibles/useCollectibleType';
import { UntrackedCollectibleSalesAdvancedFilters } from './types';

const storageKey = 'sci_ucs_adv_filters';

const defaultFilters: UntrackedCollectibleSalesAdvancedFilters = {
  excludeBreaks: false,
  excludeLots: false,
  excludeSealedWaxTerms: false,
  removeSportsFromSetNames: true,
};

export default function useUntrackedCollectibleSalesAdvancedFilters() {
  const { trackEvent } = useAnalytics();
  const { collectibleType } = useCollectibleType();
  const [value, setValue] = useLocalStorageValue<UntrackedCollectibleSalesAdvancedFilters>(storageKey, true);
  const advancedFilters = value ?? defaultFilters;
  const setAdvancedFilters = useCallback(
    (newFilters: UntrackedCollectibleSalesAdvancedFilters) => {
      setValue(newFilters);
      trackEvent({
        eventName: 'CHARTS_ADVANCED_FILTERS_CHANGED',
        collectibleType,
        filters: newFilters,
      });
    },
    [collectibleType, setValue, trackEvent]
  );

  const toggleAdvancedFilter = useCallback(
    (type: keyof UntrackedCollectibleSalesAdvancedFilters) => {
      return setAdvancedFilters({
        ...advancedFilters,
        [type]: !advancedFilters[type],
      });
    },
    [advancedFilters, setAdvancedFilters]
  );

  return {
    advancedFilters,
    toggleAdvancedFilter,
  };
}
