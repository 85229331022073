import { DetailedHTMLProps, HTMLAttributes } from 'react';
import { Button } from '@sportscardinvestor/sci-ui/components/buttons/button';
import { faCircleXmark, faSpinner } from '@fortawesome/pro-light-svg-icons';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';

export type EditFormProps = DetailedHTMLProps<HTMLAttributes<HTMLFormElement>, HTMLFormElement>;
export function EditForm({ className, ...rest }: EditFormProps) {
  return <form className={clsx('p-4 lg:p-5 bg-lightBg-accentPrimary', className)} {...rest} />;
}

export type EditFormButtonsProps = {
  onCancel?: () => void;
  cancelText?: string;
  submitText?: string;
  isLoading?: boolean;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
export function EditFormButtons({
  className,
  submitText = 'Save',
  cancelText = 'Cancel',
  onCancel,
  isLoading,
  ...rest
}: EditFormButtonsProps) {
  return (
    <div className={clsx('twp flex items-end justify-stretch sm:justify-end gap-2 lg:gap-3', className)} {...rest}>
      {!!onCancel && (
        <Button
          onClick={onCancel}
          size="medium"
          variant="custom"
          shape="round"
          faIconLeft={faCircleXmark}
          className="border-lightFg-gray border-[1px] text-lightFg-gray uppercase font-semibold flex-grow md:max-w-40"
          isDisabled={isLoading}
        >
          {cancelText}
        </Button>
      )}
      <Button
        type="submit"
        size="medium"
        variant="primary"
        shape="round"
        faIconLeft={isLoading ? faSpinner : faCheck}
        iconLeftClassName={isLoading ? 'animate-spin' : undefined}
        className="flex-grow md:max-w-40"
        isDisabled={isLoading}
      >
        {submitText}
      </Button>
    </div>
  );
}
