import { useEffect } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import FieldLabelBox from '../../../sci-ui-components/forms/FieldLabelBox/FieldLabelBox';
import TextField from '../../../sci-ui-components/forms/TextField/TextField';
import ModalDialog from '../../../sci-ui-components/ModalDialog/ModalDialog';
import { FavoriteCategory } from '../../../sci-ui-components/types/favoriteCategory';
import useAnalytics from '../../analytics/useAnalytics';
import useFavoriteCollectibleCategoriesMutations from '../useFavoriteCollectibleCategoriesMutations';
import { AddOrEditFavoriteCategoryDialogInfoProps } from './types';

const formId = 'add-favorite-category';
const nameFieldId = `${formId}-name`;

type FormValues = {
  name: string;
};
const validationSchema: Yup.SchemaOf<FormValues> = Yup.object().shape({
  name: Yup.string().min(1).required('Required'),
});

export default function AddOrEditFavoriteCategoryDialog({
  isOpen,
  onClose,
  onCreate,
  collectibleType,
  categoryId,
  name: initialName,
}: AddOrEditFavoriteCategoryDialogInfoProps & {
  isOpen: boolean;
  onClose: () => void;
  onCreate?: (createdCategory: FavoriteCategory) => void;
}) {
  const { create, update } = useFavoriteCollectibleCategoriesMutations();

  const handleSubmit = async (values: FormValues, { setSubmitting, resetForm }: FormikHelpers<FormValues>) => {
    if (categoryId) {
      const updatedCategory = await update.mutateAsync({
        categoryId,
        name: values.name,
        collectibleType,
      });
      onCreate?.(updatedCategory);
    } else {
      const newCategory = await create.mutateAsync({
        categoryName: values.name,
        collectibleType,
      });
      onCreate?.(newCategory);
    }
    onClose();
    setSubmitting(false);
    resetForm();
  };

  const { trackEvent } = useAnalytics();
  useEffect(() => {
    if (isOpen) {
      trackEvent({
        eventName: 'FAVORITES_CATEGORY_ADD_DIALOG_OPENED',
        collectibleType,
      });
    }
  }, [trackEvent, isOpen, collectibleType]);

  return (
    <Formik
      initialValues={{
        name: initialName ?? '',
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnBlur={false}
    >
      {({ values, handleChange, errors, isSubmitting }) => (
        <ModalDialog
          visible={isOpen}
          title={categoryId ? 'Edit Favorite Category' : 'Add New Favorite Category'}
          okText={categoryId ? 'Update' : 'Create'}
          onCancel={onClose}
          cancelText="Cancel"
          okButtonProps={{
            htmlType: 'submit',
            form: formId,
            disabled: isSubmitting,
          }}
          cancelButtonProps={{
            disabled: isSubmitting,
          }}
        >
          <Form id={formId}>
            <FieldLabelBox label="New Category Name" fieldId={nameFieldId} error={errors.name}>
              <TextField
                id={nameFieldId}
                name="name"
                value={values.name}
                onChange={(value, name) => handleChange({ target: { value, name } })}
              />
            </FieldLabelBox>
          </Form>
        </ModalDialog>
      )}
    </Formik>
  );
}
