import qs from 'qs';
import { PlatformStats } from '../../../sci-ui-components/types/platform';
import authenticatedFetchFromSciApi from '../authenticatedFetchFromSciApi';

export interface GetPlatformStatsParams {
  dateRangeInDays: number;
}

export async function getPlatformStats(
  { dateRangeInDays }: GetPlatformStatsParams,
  signal: AbortSignal | undefined
): Promise<PlatformStats> {
  const apiItem = await authenticatedFetchFromSciApi<PlatformStats>(
    `/platform/stats${qs.stringify({ dateRangeInDays }, { addQueryPrefix: true })}`,
    {
      method: 'GET',
      signal,
    }
  );
  return apiItem;
}
