import { z } from 'zod';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/pro-light-svg-icons';
import { CollectibleType, CustomCollectible } from '@sportscardinvestor/schemas';
import {
  getCollectibleCardNumber,
  getCollectibleGradeName,
  getCollectiblePlayerName,
  getCollectibleSpecificQualifier,
  getCollectibleVariationName,
  isSportsCardCollectible,
} from '@sportscardinvestor/collectible-helpers';
import { CustomQueryField, customQueryFormSchema, getCustomQueryInitialValues } from './customQuery';
import { ImageUrlField, getImageUrlFormInitialValues, imageUrlFormSchema } from './imageUrl';
import { CurrentValueField, currentValueFormSchema, getCurrentValueInitialValues } from './currentValue';
import { SetField, getSetInitialValues, setFormSchema } from './set';
import { useOnValueChange } from '@/hooks/useOnValueChange';
import { EditForm, EditFormButtons } from '@/sci-ui-components/forms/EditForm';
import { ConvertPriceFn } from '@/hooks/useCurrencyFormatter';
import FormSection from '@/sci-ui-components/forms/FormSection/FormSection';
import FormFieldsRow from '@/sci-ui-components/forms/FormFieldsRow/FormFieldsRow';
import FieldLabelBox from '@/sci-ui-components/forms/FieldLabelBox/FieldLabelBox';
import { PlayersAutoComplete } from '@/features/players/PlayersAutoComplete';
import { CardGradesAutoComplete } from '@/features/cardGrades/CardGradesAutoComplete';
import TextField from '@/sci-ui-components/forms/TextField/TextField';

const formSchema = z
  .object({
    playerId: z.number().nullable().default(null),
    playerName: z.string().trim().nullable().default(null),
    variationName: z.string().trim().nullable().default(null),
    gradeName: z
      .string({
        required_error: 'Please enter grade name',
      })
      .trim()
      .min(1),
    cardNumber: z.string().trim().nullable().default(null),
    specificQualifier: z.string().trim().nullable().default(null),
  })
  .and(customQueryFormSchema)
  .and(imageUrlFormSchema)
  .and(currentValueFormSchema)
  .and(setFormSchema);

type FormSchema = z.input<typeof formSchema>;
type FormPayload = z.output<typeof formSchema>;

export type OnEditSportsCardCustomCollectionItemFormSubmitFn = (payload: FormPayload) => void;

export function EditSportsCardCustomCollectionItemForm({
  collectibleId,
  collectibleType,
  initialValues,
  onSubmit,
  formId,
  onCancel,
  isLoading,
}: {
  collectibleType: CollectibleType;
  collectibleId: number | null;
  initialValues: FormSchema;
  onSubmit: OnEditSportsCardCustomCollectionItemFormSubmitFn;
  formId: string;
  onCancel: () => void;
  isLoading: boolean;
}) {
  const form = useForm<FormSchema, unknown, FormPayload>({
    resolver: zodResolver(formSchema),
    defaultValues: initialValues,
    mode: 'onSubmit',
  });
  useOnValueChange(initialValues, () => form.reset(initialValues));
  const handleSubmit = form.handleSubmit((payload: FormPayload) => onSubmit(payload));

  return (
    <FormProvider {...form}>
      <EditForm id={formId} onSubmit={handleSubmit}>
        <FormSection>
          <FormFieldsRow>
            <SetField />
            <Controller
              name="playerName"
              control={form.control}
              render={({ field, fieldState }) => (
                <FieldLabelBox label="Player" fieldId="playerName" error={fieldState.error?.message}>
                  <PlayersAutoComplete
                    id="playerName"
                    value={field.value}
                    onSelect={(data) => {
                      form.setValue('playerId', data?.id ?? null);
                      form.setValue('playerName', data?.name ?? '');
                    }}
                    onChange={(value) => {
                      form.setValue('playerId', null);
                      form.setValue('playerName', value);
                    }}
                  />
                </FieldLabelBox>
              )}
            />
            <Controller
              name="gradeName"
              control={form.control}
              render={({ field, fieldState }) => (
                <FieldLabelBox label="Grade" fieldId="gradeName" error={fieldState.error?.message}>
                  <CardGradesAutoComplete
                    id="gradeName"
                    value={field.value}
                    onSelect={(data) => {
                      form.setValue('gradeName', data?.name ?? '');
                    }}
                    onChange={(value) => {
                      form.setValue('gradeName', value);
                    }}
                  />
                </FieldLabelBox>
              )}
            />
          </FormFieldsRow>
        </FormSection>
        <FormSection title="Optional Fields" variant="secondary" noPadding>
          <div className="flex items-start gap-5 flex-wrap">
            <FormSection noPadding className="flex-grow-0">
              <ImageUrlField collectibleType={collectibleType} initialImageUrl={initialValues.imageUrl} />
            </FormSection>
            <FormSection noPadding className="flex-grow">
              <FormFieldsRow>
                <Controller
                  name="variationName"
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <FieldLabelBox label="Variation" fieldId="variationName" error={fieldState.error?.message}>
                      <TextField {...field} id="variationName" />
                    </FieldLabelBox>
                  )}
                />
                <CurrentValueField collectibleId={collectibleId} collectibleType={collectibleType} />
              </FormFieldsRow>
              <FormFieldsRow>
                <Controller
                  name="cardNumber"
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <FieldLabelBox label="Card Number" fieldId="cardNumber" error={fieldState.error?.message}>
                      <TextField {...field} id="cardNumber" />
                    </FieldLabelBox>
                  )}
                />
                <Controller
                  name="specificQualifier"
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <FieldLabelBox
                      label={
                        <Tooltip
                          title="This field is optional and can be used to provide additional information about the card such as an image variation description (ex: “Bat Down”) or to describe a Base Subset (like “Stat Leaders”)"
                          trigger={'hover'}
                        >
                          <span>
                            Image Description &nbsp;
                            <FontAwesomeIcon icon={faCircleQuestion} />
                          </span>
                        </Tooltip>
                      }
                      fieldId="specificQualifier"
                      error={fieldState.error?.message}
                    >
                      <TextField {...field} id="specificQualifier" />
                    </FieldLabelBox>
                  )}
                />
              </FormFieldsRow>
              <FormFieldsRow>
                <CustomQueryField />
              </FormFieldsRow>
            </FormSection>
          </div>
        </FormSection>
        <EditFormButtons className="m-4" onCancel={onCancel} isLoading={isLoading} />
      </EditForm>
    </FormProvider>
  );
}

export function getEditSportsCardCustomCollectionItemFormInitialValues({
  collectible,
  convertPriceFromUSD,
}: {
  collectible: CustomCollectible | null;
  convertPriceFromUSD: ConvertPriceFn;
}): FormSchema {
  if (!collectible) {
    return {
      gradeName: '',
      setName: '',
    };
  }
  return {
    ...getCustomQueryInitialValues({ collectible }),
    ...getImageUrlFormInitialValues({ collectible }),
    ...getCurrentValueInitialValues({
      collectible,
      convertPriceFromUSD,
    }),
    ...getSetInitialValues({ collectible }),
    gradeName: getCollectibleGradeName(collectible) ?? '',
    cardNumber: getCollectibleCardNumber(collectible),
    playerId: isSportsCardCollectible(collectible) && collectible.player?.id ? Number(collectible.player?.id) : null,
    playerName: getCollectiblePlayerName(collectible),
    specificQualifier: getCollectibleSpecificQualifier(collectible),
    variationName: getCollectibleVariationName(collectible),
  };
}
