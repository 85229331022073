import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import QueryString from 'qs';
import Search, { QuickSearchType } from '../../sci-ui-components/Search/Search';
import {
  SportsCardSearch,
  SealedWaxSearch,
  isFeaturedCollectibleSearch,
  FeaturedCollectibleSearch,
} from '../../services/sciApi/search/types';
import SportsSearchItem from '../../sci-ui-components/Search/items/SearchItem/SportSearchItem';
import SealedWaxSearchItem from '../../sci-ui-components/Search/items/SearchItem/SealedWaxSearchItem';
import RawSaleSearchItem from '../../sci-ui-components/Search/items/SearchItem/RawSaleSearchItem';
import useDebouncedValue from '../../sci-ui-components/hooks/useDebouncedValue';
import { CollectibleType } from '../../sci-ui-components/types/collectibleType';
import useCollectibleType from '../collectibles/useCollectibleType';
import { useCommonCollectiblesActions } from '../collectibles/useCommonCollectiblesActions';
import useAnalytics from '../analytics/useAnalytics';
import {
  RawCompletedSaleSearchItem,
  useInfiniteRawCompletedSalesSearch,
} from '../sales/raw/useRawCompletedSalesSearch';
import { openSaleDetails } from '../sales-history/SaleDetailsDialog';
import TrySalesHistoryMessage from '../../sci-ui-components/Search/TrySalesHistoryMessage';
import { Route } from '../app/constants';
import useCollectiblesSearch from '../collectibles/useCollectiblesSearch';
import SelectedCards from './SelectedCards';
import useSelectedCollectibleIds from 'features/search/useSelectedCollectibleIds';

const placeholderByType: Record<CollectibleType, string> = {
  'sports-card': 'e.g. Luka Doncic Prizm',
  'sealed-wax-card': 'e.g. 2003 Topps Chrome Basketball Hobby Box',
};

const QuickSearch = ({
  onSearch,
  isSearching,
  className,
}: {
  onSearch: (isSearching: boolean) => void;
  isSearching: boolean;
  className?: string;
}) => {
  const [searchType, setSearchType] = useState<QuickSearchType>('featuredCards');
  const { collectibleType } = useCollectibleType();
  const [closeSearch, setCloseSearch] = useState(false);
  const { selectedCollectibleIds, isEmpty, toggleSelectCollectibleId } = useSelectedCollectibleIds();
  const [searchText, setSearchText] = useState('');
  const [debouncedSearchText] = useDebouncedValue(searchText, 200);
  const [isClickOutsideToCloseEnabled, setIsClickOutsideToCloseEnabled] = useState(true);
  const router = useRouter();

  const {
    allItems: featuredListItems,
    verifiedSpellCheckedAlternatives: featuredSpellCheckedAlternatives,
    hasNextPage: featuredHasNextPage,
    fetchNextPage: featuredFetchNextPage,
    isFetching: featuredIsFetching,
    isLoading: featuredIsLoading,
  } = useCollectiblesSearch(
    {
      filters: { collectibleTypes: [collectibleType] },
      searchQueryText: debouncedSearchText,
      limit: 10,
    },
    { enabled: isSearching && searchType === 'featuredCards' }
  );

  const {
    allItems: rawSaleListItems,
    extraItems: rawSaleExtraItems,
    verifiedSpellCheckedAlternatives: rawSaleSpellCheckedAlternatives,
    hasNextPage: rawSaleHasNextPage,
    fetchNextPage: rawSaleFetchNextPage,
    isFetching: rawSaleIsFetching,
    isLoading: rawSaleIsLoading,
  } = useInfiniteRawCompletedSalesSearch(
    {
      titleSearchQueryText: debouncedSearchText,
      sort: [
        {
          sortBy: 'saleDate',
          sortDirection: 'desc',
        },
      ],
    },
    { enabled: isSearching && searchType === 'rawSales' }
  );

  useEffect(() => {
    if (!searchText) {
      setSearchType('featuredCards');
    }
  }, [searchText]);

  useEffect(() => {
    if (
      !featuredIsLoading &&
      !featuredIsFetching &&
      !featuredListItems.length &&
      !featuredSpellCheckedAlternatives?.length &&
      searchType === 'featuredCards' &&
      searchText
    ) {
      setSearchType('rawSales');
    }
  }, [
    featuredIsFetching,
    featuredIsLoading,
    featuredListItems,
    setSearchType,
    searchType,
    searchText,
    featuredSpellCheckedAlternatives,
  ]);

  const actions = useCommonCollectiblesActions({ collectibleType });
  const { trackEvent } = useAnalytics();
  useEffect(() => {
    if (debouncedSearchText) {
      trackEvent({
        eventName: 'SEARCH_QUICK_SEARCH_USED',
        searchText: debouncedSearchText,
        collectibleType,
      });
    }
  }, [debouncedSearchText, trackEvent, collectibleType]);

  const closeSearchModal = () => {
    setCloseSearch(true);
  };

  const handleRawSaleItemClick = async (item: RawCompletedSaleSearchItem) => {
    setIsClickOutsideToCloseEnabled(false);
    try {
      await openSaleDetails({ sale: item, query: searchText });
    } finally {
      setIsClickOutsideToCloseEnabled(true);
    }
  };

  const handleChartCollectible = async (collectible: SportsCardSearch | SealedWaxSearch) => {
    if (selectedCollectibleIds.length) {
      toggleSelectCollectibleId(collectible.id);
    } else {
      setIsClickOutsideToCloseEnabled(false);
      try {
        const hasGoneToCharts = await actions.chartCollectible([collectible.id]);
        if (hasGoneToCharts) {
          setCloseSearch(true);
        }
      } finally {
        setIsClickOutsideToCloseEnabled(true);
      }
    }
  };

  const handleEnterPress = () => {
    if (searchType === 'rawSales') {
      router.push(
        `${Route.SalesHistory}${QueryString.stringify(
          { search: searchText },
          { addQueryPrefix: true, skipNulls: true }
        )}`
      );
      closeSearchModal();
    }
  };

  return (
    <Search<FeaturedCollectibleSearch | RawCompletedSaleSearchItem>
      className={className}
      searchTerm={searchText}
      isFetching={searchType === 'featuredCards' ? featuredIsFetching : rawSaleIsFetching}
      isLoading={searchType === 'featuredCards' ? featuredIsLoading : rawSaleIsLoading}
      hasSelectedCards={!isEmpty}
      collectibleType={collectibleType}
      listItems={searchType === 'featuredCards' ? featuredListItems : rawSaleListItems}
      extraItems={searchType === 'featuredCards' ? undefined : rawSaleHasNextPage ? [] : rawSaleExtraItems}
      closeSearch={closeSearch}
      spellCheckAlternatives={
        searchType === 'featuredCards' ? featuredSpellCheckedAlternatives : rawSaleSpellCheckedAlternatives
      }
      searchInputProps={{
        placeholder: placeholderByType[collectibleType],
      }}
      emptyMessage={
        searchType === 'featuredCards' ? (
          <TrySalesHistoryMessage searchText={searchText} noTextWrapper />
        ) : (
          <TrySalesHistoryMessage
            searchText={searchText}
            customTopText={'No Results Found'}
            customBottomText={''}
            noTextWrapper
          />
        )
      }
      onPressEnter={handleEnterPress}
      renderItem={(item, _i, isExtra) => {
        const key = getItemKey(item, isExtra);
        if (!isFeaturedCollectibleSearch(item)) {
          return <RawSaleSearchItem item={item} onClick={handleRawSaleItemClick} key={key} />;
        }
        const isSelected = selectedCollectibleIds.some((id) => id === item.id);
        switch (collectibleType) {
          case 'sports-card':
            const sportsCard = item as SportsCardSearch;
            return (
              <SportsSearchItem
                {...sportsCard}
                selected={isSelected}
                key={key}
                onChartButtonClick={() => toggleSelectCollectibleId(sportsCard.id)}
                onClick={() => handleChartCollectible(sportsCard)}
                onPopulationCountClick={() => actions.chartPopulationCount(item.id)}
              />
            );
          case 'sealed-wax-card':
            const wax = item as SealedWaxSearch;
            return (
              <SealedWaxSearchItem
                {...wax}
                selected={isSelected}
                key={key}
                onChartButtonClick={() => toggleSelectCollectibleId(wax.id)}
                onClick={() => handleChartCollectible(wax)}
              />
            );
          default:
            return <p> un mapped collectible card</p>;
        }
      }}
      onSearch={(value) => {
        if (value === null) {
          // Value comes null when a user clicks the clear icon, else it will just come as an empty string.
          setSearchType('featuredCards');
        }
        setSearchText(value ?? '');
      }}
      onSearchStarted={() => onSearch(true)}
      hasNextPage={searchType === 'featuredCards' ? !!featuredHasNextPage : !!rawSaleHasNextPage}
      fetchNextPage={searchType === 'featuredCards' ? featuredFetchNextPage : rawSaleFetchNextPage}
      onClose={(closeSearch = false) => {
        onSearch(false);
        setCloseSearch(closeSearch);
      }}
      listWrapper={{
        beforeBlock: searchType === 'featuredCards' ? <SelectedCards onCardsChart={closeSearchModal} /> : null,
      }}
      searchType={searchType}
      onChangeSearchType={setSearchType}
      closeOnClickAway={isClickOutsideToCloseEnabled}
    />
  );
};

function getItemKey(item: FeaturedCollectibleSearch | RawCompletedSaleSearchItem, isExtra: boolean): string {
  return isFeaturedCollectibleSearch(item)
    ? `${item.id}${isExtra ? '-extra' : ''}`
    : `${item._id}${isExtra ? '-extra' : ''}`;
}

export default QuickSearch;
