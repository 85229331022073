import { useMemo } from 'react';
import { CollectibleType } from '../../sci-ui-components/types/collectibleType';
import { CollectionItem } from '../../sci-ui-components/types/collectionItem';
import { Collectible } from '../../sci-ui-components/types/collectible';
import useAnalytics from '../analytics/useAnalytics';
import { collectionItemHasNotes } from './helpers/collectionItemHasNotes';
import { collectionItemNoteDialog } from './CollectionItemNoteDialog/index';
import { markAsSoldDialog } from './MarkAsSoldDialog/index';
import useCollectionItemMutations from './useCollectionItemMutations';
import { isCollectionItemSold } from './helpers/isCollectionItemSold';
import {
  showConvertCollectionItemGradeDialog,
  canConvertCollectionItemGrade,
} from './ConvertCollectionItemGradeDialog/store';
import { showEditCustomCollectionItemDialog } from './EditCustomCollectionItemDialog/store';
import { showEditCollectionItemPurchaseDetailsDialog } from './EditCollectionItemPurchaseDetailsDialog/store';
import { useCollectionItem } from './useListCollectionItems';
import { useCollectibleTypeValue } from 'features/collectibles/CollectibleTypeValueContext';
import useCurrencyFormatter from 'hooks/useCurrencyFormatter';

export function useCommonCollectionActions({
  collectibleType: collectibleTypeFromParam,
  isSold,
}: {
  collectibleType?: CollectibleType;
  isSold?: boolean;
} = {}) {
  const collectibleTypeFromContext = useCollectibleTypeValue();
  const collectibleType = collectibleTypeFromParam ?? collectibleTypeFromContext;
  const { trackEvent } = useAnalytics();
  const { deleteCollectionItem } = useCollectionItemMutations();
  const { convertPriceFromUSD } = useCurrencyFormatter();

  return useMemo(
    () => ({
      markAsSold: ({ collectionItem }: { collectionItem: CollectionItem }) => {
        if (isSold !== true) {
          markAsSoldDialog({
            initialValues: {
              ...collectionItem,
              purchasePricePerItem: convertPriceFromUSD({ value: collectionItem.purchasePricePerItem ?? 0 })!,
              purchasePriceTotal: convertPriceFromUSD({ value: collectionItem.purchasePriceTotal ?? 0 })!,
            },
            collectibleType,
          });
          trackEvent({
            eventName: 'COLLECTION_MARK_AS_SOLD_DIALOG_OPENED',
            collectionItemId: collectionItem.id,
            collectibleType,
          });
        }
      },
      updateNotes: ({ collectionItem }: { collectionItem: CollectionItem }) => {
        collectionItemNoteDialog({
          initialValues: collectionItem,
        });
        trackEvent({
          eventName: 'COLLECTION_ITEM_NOTE_DIALOG_OPENED',
          collectionItemId: collectionItem.id,
        });
      },
      checkIfHasNotes: ({ collectionItem }: { collectionItem: CollectionItem }) =>
        collectionItemHasNotes(collectionItem),
      deleteItem: (collectionItemId: number) => deleteCollectionItem(collectionItemId),
      showConvertCollectionItemGradeDialog,
      canConvertCollectionItemGrade,
      showEditCollectionItemPurchaseDetailsDialog,
      showEditCustomCollectionItemDialog,
    }),
    [collectibleType, deleteCollectionItem, trackEvent, isSold, convertPriceFromUSD]
  );
}

export interface CommonCollectionItemActions {
  markAsSold?: () => void;
  hasNotes: boolean;
  updateNotes: () => void;
  deleteItem: () => void;
  showConvertCollectionItemGradeDialog: (params?: { newGradeCollectibleId?: number }) => void;
  canConvertCollectionItemGrade: boolean;
  showEditCollectionItemPurchaseDetailsDialog: () => void;
  /**
   * Only defined for custom items
   */
  showEditCustomCollectionItemDialog: undefined | (() => void);
  isCustom: boolean;
}

export function useCommonCollectionItemActions({
  collectionItem,
  collectible,
}: {
  collectionItem: CollectionItem;
  collectible: Collectible;
}) {
  const collectibleType = collectionItem.collectibleType;
  const isSold = isCollectionItemSold(collectionItem);
  const actions = useCommonCollectionActions({
    collectibleType,
    isSold,
  });

  const { collectionItem: extendedCollectionItem } = useCollectionItem({
    collectionItemId: collectionItem.id,
    collectibleType: collectible.collectibleType,
  }); // NOTE: delete this once collectionItem is of CollectionItem type from multiverse

  return useMemo<CommonCollectionItemActions>(
    () => ({
      hasNotes: actions.checkIfHasNotes({ collectionItem }),
      markAsSold: isSold ? undefined : () => actions.markAsSold({ collectionItem }),
      updateNotes: () => actions.updateNotes({ collectionItem }),
      deleteItem: () => actions.deleteItem(collectionItem.id),
      showEditCollectionItemPurchaseDetailsDialog: () =>
        showEditCollectionItemPurchaseDetailsDialog({ collectibleType, collectionItemId: collectionItem.id }),
      isCustom: extendedCollectionItem?.isCustom ?? false,
      showEditCustomCollectionItemDialog: extendedCollectionItem?.isCustom
        ? () => showEditCustomCollectionItemDialog({ collectibleType, collectionItemId: collectionItem.id })
        : undefined,
      showConvertCollectionItemGradeDialog: (params) =>
        showConvertCollectionItemGradeDialog({
          collectibleType: collectible.collectibleType,
          collectionItemId: collectionItem.id,
          newGradeCollectibleId: params?.newGradeCollectibleId,
        }),
      canConvertCollectionItemGrade: extendedCollectionItem
        ? canConvertCollectionItemGrade({ collectionItem: extendedCollectionItem })
        : false,
    }),
    [collectionItem, actions, isSold, collectible, extendedCollectionItem, collectibleType]
  );
}
