import { useReducer, useEffect } from 'react';
import VerticalFormStepper, {
  FormStep,
} from '../../../sci-ui-components/forms/VerticalFormStepper/VerticalFormStepper';
import { collectibleNames } from '../../../sci-ui-components/collectibles/constants';
import ModalDialog from '../../../sci-ui-components/ModalDialog/ModalDialog';
import useCollectionItemMutations, {
  AddCollectibleToCollectionParams,
  AddCustomCollectibleToCollectionParams,
} from '../useCollectionItemMutations';
import { CustomCollectible } from '../../../sci-ui-components/types/collectible';
import { CollectibleType } from '../../../sci-ui-components/types/collectibleType';
import { CollectionItem } from '../../../sci-ui-components/types/collectionItem';
import { constructCustomCardQueryString } from '../../../services/sciApi/collections/utils/constructCustomCardQueryString';
import { isCollectionItemSold } from '../helpers/isCollectionItemSold';
import { AddOrEditCollectionItemDialogInfoProps, ItemToEdit, OnSwitchToManualFn } from './types';
import CollectibleSummary from './collectible/CollectibleSummary';
import {
  CustomCollectibleFormValues,
  CustomSealedWaxCollectibleFormValues,
  CustomSportsCardCollectibleFormValues,
} from './collectible/custom/types';
import { getCustomCollectibleInitialValues } from './collectible/custom/CustomCollectibleForm';
import PurchaseDetailsSummary from './purchaseDetails/PurchaseDetailsSummary';
import { PurchaseDetailsFormValues } from './purchaseDetails/types';
import PurchaseDetailsForm, {
  getPurchaseDetailsInitialValuesFromCollectionItem,
} from './purchaseDetails/PurchaseDetailsForm';
import NotesForm, { isNoteUpdated } from './NotesForm';
import CollectibleForm from './collectible/CollectibleForm';
import CategoryForm, { isCategoryUpdated } from './CategoryForm';
import SaleDetailsSummary from './saleDetails/SaleDetailsSummary';
import SaleDetailsForm, { getSaleDetailsInitialValuesFromCollectionItem } from './saleDetails/SaleDetailsForm';
import { SaleDetailsFormValues } from './saleDetails/types';
import { useIsGradedCollectible } from './collectible/useIsGradedCollectible';
import useCurrencyFormatter, { ConvertPriceFn } from '@/hooks/useCurrencyFormatter';

const dialogId = 'add-edit-collection-item';

export default function AddOrEditCollectionItemDialog({
  isOpen,
  onClose,
  onCreated,
  onEdited,
  collectibleType,
  initialCollectibleId,
  itemToEdit,
  initialCustomCollectibleValues,
  initialCustomCollectible,
  initialPurchaseDetails,
  onSwitchToManual,
  initialNote,
  initialCategoryId,
  openInCollectibleEditMode,
}: AddOrEditCollectionItemDialogInfoProps & {
  isOpen: boolean;
  onClose: () => void;
  onCreated?: () => void;
  onEdited?: () => void;
  onSwitchToManual: OnSwitchToManualFn;
  collectibleType: CollectibleType;
}) {
  const { convertPriceToUSD, convertPriceFromUSD } = useCurrencyFormatter();
  const isEdit = !!itemToEdit;
  const isCustom = !!initialCustomCollectibleValues || !!initialCustomCollectible || !!itemToEdit?.customCollectible;
  const isSold = isEdit ? isCollectionItemSold(itemToEdit.collectionItem) : false;
  const [state, dispatch] = useReducer(
    reducer(convertPriceFromUSD),
    getInitalState({
      initialCollectibleId: initialCollectibleId ?? null,
      itemToEdit: itemToEdit ?? null,
      initialCustomCollectibleValues: initialCustomCollectibleValues ?? null,
      initialCustomCollectible: initialCustomCollectible ?? null,
      convertPriceFromUSD,
    })
  );
  useEffect(() => {
    dispatch({
      type: 'initialize',
      initialCollectibleId: initialCollectibleId ?? null,
      itemToEdit: itemToEdit ?? null,
      initialCustomCollectibleValues: initialCustomCollectibleValues ?? null,
      initialCustomCollectible: initialCustomCollectible ?? null,
      convertPriceFromUSD,
    });
  }, [initialCustomCollectibleValues, initialCustomCollectible, initialCollectibleId, itemToEdit, convertPriceFromUSD]);

  useEffect(() => {
    if (openInCollectibleEditMode) {
      dispatch({ type: 'edit_collectible' });
    }
  }, [openInCollectibleEditMode]);

  useEffect(() => {
    if (initialNote) {
      dispatch({ type: 'set_note', note: initialNote });
    }
  }, [initialNote]);

  useEffect(() => {
    if (initialCategoryId) {
      dispatch({ type: 'set_category', categoryId: initialCategoryId });
    }
  }, [initialCategoryId]);

  useEffect(() => {
    if (initialPurchaseDetails) {
      dispatch({ type: 'set_purchase_details', purchaseDetails: initialPurchaseDetails, isEdit: true });
    }
  }, [initialPurchaseDetails]);

  const { updateCollectionItem, addCollectibleToCollection, addCustomCollectibleToCollection, isMutating } =
    useCollectionItemMutations();

  const handleSubmit = async () => {
    if (isEdit) {
      await updateCollectionItem.mutateAsync({
        collectibleType,
        note: isNoteUpdated(itemToEdit.collectionItem.note, state.note)
          ? {
              collectionItemId: itemToEdit.collectionItem.id,
              note: state.note?.trim() ?? '',
            }
          : null,
        category: isCategoryUpdated(getCollectionItemCategoryId(itemToEdit.collectionItem), state.categoryId)
          ? {
              collectionItemId: itemToEdit.collectionItem.id,
              collectionCategoryId: state.categoryId,
            }
          : null,
      });
      onEdited?.();
    } else {
      if (isCustom) {
        await addCustomCollectibleToCollection.mutateAsync(
          getAddCustomParams({
            categoryId: state.categoryId,
            note: state.note,
            collectibleType,
            customCollectibleFormValues: state.customCollectibleFormValues!,
            purchaseDetails: state.purchaseDetails!,
            convertPriceToUSD,
          })
        );
      } else {
        await addCollectibleToCollection.mutateAsync(
          getAddNonCustomParams({
            categoryId: state.categoryId,
            note: state.note,
            collectibleType,
            collectibleId: state.collectibleId!,
            purchaseDetails: state.purchaseDetails!,
            convertPriceToUSD,
          })
        );
      }
      onCreated?.();
    }
    dispatch({ type: 'reset' });
    onClose();
  };

  const hasCollectibleInfo = !!state.customCollectibleFormValues || !!state.collectibleId;
  const isGradedCollectible = useIsGradedCollectible({
    collectibleType,
    customCollectibleFormValues: isCustom ? state.customCollectibleFormValues : null,
    nonCustomCollectibleId: isCustom ? null : state.collectibleId,
  });

  const steps: (FormStep<Step> | null)[] = [
    {
      id: 'collectible',
      onEdit: isEdit && (!isCustom || isSold) ? undefined : () => dispatch({ type: 'edit_collectible' }),
      renderSummaryTitle: () => (
        <CollectibleSummary
          collectibleType={collectibleType}
          collectibleId={state.collectibleId}
          customCollectibleFormValues={state.customCollectibleFormValues}
          customCollectible={itemToEdit?.customCollectible ?? null}
          collectionItemId={itemToEdit?.collectionItem?.id ?? null}
        />
      ),
      renderActiveContent: () => (
        <CollectibleForm
          dialogId={dialogId}
          collectibleType={collectibleType}
          onSwitchToManual={onSwitchToManual}
          initialCollectibleId={state.collectibleId}
          onSubmitNonCustom={(collectibleId) => dispatch({ type: 'set_non_custom_collectible', collectibleId, isEdit })}
          customCollectibleToEdit={itemToEdit?.customCollectible ?? null}
          initialCustomCollectible={initialCustomCollectible ?? null}
          initialCustomCollectibleValues={state.customCollectibleFormValues ?? initialCustomCollectibleValues ?? null}
          onSubmitCustom={(customCollectibleFormValues) =>
            dispatch({ type: 'set_custom_collectible', customCollectibleFormValues, isEdit })
          }
          onCancel={() => {
            if (isEdit || (state.purchaseDetails && hasCollectibleInfo)) {
              dispatch({ type: 'cancel_edit' });
            } else if (hasCollectibleInfo) {
              dispatch({ type: 'edit_purchase_details' });
            } else {
              onClose();
            }
          }}
        />
      ),
    },
    {
      id: 'purchase_details',
      onEdit: hasCollectibleInfo && !isSold ? () => dispatch({ type: 'edit_purchase_details' }) : undefined,
      renderSummaryTitle: () => (
        <PurchaseDetailsSummary
          purchaseDetails={state.purchaseDetails}
          collectibleType={collectibleType}
          isCustom={isCustom}
        />
      ),
      renderActiveContent: () => (
        <PurchaseDetailsForm
          collectibleType={collectibleType}
          dialogId={dialogId}
          initialPurchaseDetails={state.purchaseDetails}
          collectionItemId={itemToEdit?.collectionItem?.id ?? null}
          isCustom={isCustom}
          isGradedCollectible={isGradedCollectible}
          onSave={(purchaseDetails) => {
            dispatch({ type: 'set_purchase_details', purchaseDetails });
          }}
          onCancel={() => {
            if (isEdit || state.purchaseDetails) {
              dispatch({ type: 'cancel_edit' });
            } else {
              dispatch({ type: 'edit_collectible' });
            }
          }}
        />
      ),
    },
    isSold
      ? {
          id: 'sale_details',
          onEdit: () => dispatch({ type: 'edit_sale_details' }),
          renderSummaryTitle: () => (
            <SaleDetailsSummary saleDetails={state.saleDetails} collectibleType={collectibleType} />
          ),
          renderActiveContent: () => (
            <SaleDetailsForm
              dialogId={dialogId}
              initialSaleDetails={state.saleDetails}
              collectionItemId={itemToEdit?.collectionItem?.id ?? null}
              onSave={(saleDetails) => {
                dispatch({ type: 'set_sale_details', saleDetails });
              }}
              onCancel={() => dispatch({ type: 'cancel_edit' })}
              ownedQuantity={itemToEdit?.collectionItem?.quantity ?? null}
              collectibleType={collectibleType}
              disableQuantity
            />
          ),
        }
      : null,
    {
      id: 'category',
      renderSummaryTitle: () => (
        <CategoryForm
          dialogId={dialogId}
          collectibleType={collectibleType}
          categoryId={state.categoryId}
          onSubmit={(categoryId) => dispatch({ type: 'set_category', categoryId })}
        />
      ),
    },
    {
      id: 'notes',
      renderSummaryTitle: () => (
        <NotesForm
          dialogId={dialogId}
          isEdit={isEdit}
          note={state.note}
          onSubmit={(note) => dispatch({ type: 'set_note', note })}
        />
      ),
    },
  ];

  const handleModalCancel = () => {
    onClose();
    dispatch({ type: 'reset' });
  };

  return (
    <ModalDialog
      visible={isOpen}
      title={
        isEdit
          ? `Edit ${isCustom ? 'Manual ' : ''}${collectibleNames[collectibleType]?.shortSingular} in Collection`
          : `Add ${isCustom ? 'Manual ' : ''}${collectibleNames[collectibleType]?.shortSingular} to Collection`
      }
      okText={isEdit ? 'Save' : 'Add'}
      onCancel={handleModalCancel}
      cancelText="Cancel"
      okButtonProps={{
        htmlType: 'button',
        disabled: isMutating || !canSave(state),
      }}
      onOk={handleSubmit}
      cancelButtonProps={{
        disabled: isMutating,
      }}
      closable={!isMutating}
      width={820}
    >
      <VerticalFormStepper steps={steps} activeStepId={state.stepId} editIsPrimary={isEdit} />
    </ModalDialog>
  );
}

interface State {
  stepId: Step | null;
  collectibleId: number | null;
  customCollectibleFormValues: CustomCollectibleFormValues | null;
  purchaseDetails: PurchaseDetailsFormValues | null;
  saleDetails: SaleDetailsFormValues | null;
  categoryId: number | null;
  note: string | null;
}

type Action =
  | ({
      type: 'initialize';
    } & InitializeParams)
  | { type: 'reset' }
  | { type: 'edit_collectible' }
  | { type: 'set_non_custom_collectible'; collectibleId: number; isEdit: boolean }
  | { type: 'set_custom_collectible'; customCollectibleFormValues: CustomCollectibleFormValues | null; isEdit: boolean }
  | { type: 'edit_purchase_details' }
  | { type: 'set_purchase_details'; purchaseDetails: PurchaseDetailsFormValues; isEdit?: boolean }
  | { type: 'cancel_edit' }
  | { type: 'set_category'; categoryId: number | null }
  | { type: 'set_note'; note: string }
  | { type: 'edit_sale_details' }
  | { type: 'set_sale_details'; saleDetails: SaleDetailsFormValues };

type Step = 'collectible' | 'purchase_details' | 'category' | 'notes' | 'sale_details';

function reducer(convertPriceFromUSD: ConvertPriceFn) {
  return (state: State, action: Action): State => {
    switch (action.type) {
      case 'initialize': {
        return getInitalState({
          initialCollectibleId: action.initialCollectibleId,
          itemToEdit: action.itemToEdit,
          initialCustomCollectibleValues: action.initialCustomCollectibleValues,
          initialCustomCollectible: action.initialCustomCollectible,
          convertPriceFromUSD,
        });
      }
      case 'reset': {
        return getInitalState({
          initialCollectibleId: null,
          itemToEdit: null,
          initialCustomCollectibleValues: null,
          initialCustomCollectible: null,
          convertPriceFromUSD,
        });
      }
      case 'edit_collectible': {
        return {
          ...state,
          stepId: 'collectible',
        };
      }
      case 'set_non_custom_collectible': {
        return {
          ...state,
          collectibleId: action.collectibleId,
          stepId: action.isEdit ? null : 'purchase_details',
        };
      }
      case 'set_custom_collectible': {
        return {
          ...state,
          customCollectibleFormValues: action.customCollectibleFormValues,
          stepId: action.isEdit ? null : 'purchase_details',
        };
      }
      case 'edit_purchase_details': {
        return {
          ...state,
          stepId: 'purchase_details',
        };
      }
      case 'set_purchase_details': {
        return {
          ...state,
          purchaseDetails: action.purchaseDetails,
          ...(action.isEdit ? {} : { stepId: null }),
        };
      }
      case 'cancel_edit': {
        return {
          ...state,
          stepId: null,
        };
      }
      case 'set_category': {
        return {
          ...state,
          categoryId: action.categoryId,
        };
      }
      case 'set_note': {
        return {
          ...state,
          note: action.note,
        };
      }
      case 'edit_sale_details': {
        return {
          ...state,
          stepId: 'sale_details',
        };
      }
      case 'set_sale_details': {
        return {
          ...state,
          saleDetails: action.saleDetails,
          stepId: null,
        };
      }
      default:
        return state;
    }
  };
}

interface InitializeParams {
  initialCollectibleId: number | null;
  initialCustomCollectible: CustomCollectible | null;
  initialCustomCollectibleValues: CustomCollectibleFormValues | null;
  itemToEdit: ItemToEdit | null;
  convertPriceFromUSD: ConvertPriceFn;
}

function getInitalState({
  initialCollectibleId,
  initialCustomCollectible,
  initialCustomCollectibleValues,
  itemToEdit,
  convertPriceFromUSD,
}: InitializeParams): State {
  if (itemToEdit) {
    const purchaseDetails = getPurchaseDetailsInitialValuesFromCollectionItem({
      collectionItem: itemToEdit.collectionItem,
      convertPriceFromUSD,
    });
    const saleDetails = getSaleDetailsInitialValuesFromCollectionItem({
      collectionItem: itemToEdit.collectionItem,
      convertPriceFromUSD,
    });
    const categoryId = getCollectionItemCategoryId(itemToEdit.collectionItem);
    const note = itemToEdit.collectionItem.note;
    if (itemToEdit.customCollectible) {
      return {
        stepId: null,
        collectibleId: null,
        customCollectibleFormValues: getCustomCollectibleInitialValues({
          collectible: itemToEdit.customCollectible,
          collectibleType: itemToEdit.customCollectible.collectibleType,
          convertPriceFromUSD,
        }),
        purchaseDetails,
        saleDetails,
        categoryId,
        note,
      };
    }
    return {
      stepId: null,
      collectibleId: itemToEdit.collectionItem.collectibleId ?? initialCollectibleId,
      customCollectibleFormValues: null,
      purchaseDetails,
      saleDetails,
      categoryId,
      note,
    };
  }
  if (!!initialCustomCollectibleValues || !!initialCustomCollectible) {
    return {
      stepId: 'collectible',
      collectibleId: null,
      customCollectibleFormValues: null,
      purchaseDetails: null,
      saleDetails: null,
      categoryId: null,
      note: null,
    };
  }
  return {
    stepId: initialCollectibleId ? 'purchase_details' : 'collectible',
    collectibleId: initialCollectibleId ?? null,
    customCollectibleFormValues: null,
    purchaseDetails: null,
    saleDetails: null,
    categoryId: null,
    note: null,
  };
}

function getCollectionItemCategoryId(collectionItem: CollectionItem) {
  return collectionItem.collectionCategories?.[0]?.id ?? null;
}

function canSave(state: State): boolean {
  return (!!state.collectibleId || !!state.customCollectibleFormValues) && !!state.purchaseDetails && !state.stepId;
}

function getAddCustomParams({
  customCollectibleFormValues,
  purchaseDetails,
  categoryId,
  collectibleType,
  note,
  convertPriceToUSD,
}: {
  collectibleType: CollectibleType;
  customCollectibleFormValues: CustomCollectibleFormValues;
  purchaseDetails: PurchaseDetailsFormValues;
  categoryId: number | null;
  note: string | null;
  convertPriceToUSD: ConvertPriceFn;
}): AddCustomCollectibleToCollectionParams {
  const customSportsCardCollectibleFormValues = customCollectibleFormValues as CustomSportsCardCollectibleFormValues;
  const customSealedWaxCollectibleFormValues = customCollectibleFormValues as CustomSealedWaxCollectibleFormValues;
  return {
    collectibleType,
    sportName: customCollectibleFormValues.sportName,
    cardSetId: customCollectibleFormValues.cardSetId,
    cardSetName: customCollectibleFormValues.cardSetName,
    cardSetYear: customCollectibleFormValues.cardSetYear,
    currentValue: convertPriceToUSD({ value: customCollectibleFormValues.currentValueInDisplayCurrency }),
    playerId: customSportsCardCollectibleFormValues.playerId,
    playerName: customSportsCardCollectibleFormValues.playerName,
    gradeName: customSportsCardCollectibleFormValues.gradeName,
    variationName: customSportsCardCollectibleFormValues.variationName,
    boxTypeName: customSealedWaxCollectibleFormValues.boxTypeName,
    query: constructCustomCardQueryString({
      queryYear: customCollectibleFormValues.cardSetYear ?? undefined,
      querySet: customCollectibleFormValues.cardSetName ?? undefined,
      queryPlayerName: customSportsCardCollectibleFormValues.playerName ?? undefined,
      queryVariation: customSportsCardCollectibleFormValues.variationName ?? undefined,
      queryGrade: customSportsCardCollectibleFormValues.gradeName ?? undefined,
      queryBoxTypeId: customSealedWaxCollectibleFormValues.boxTypeId ?? undefined,
      queryBoxTypeName: customSealedWaxCollectibleFormValues.boxTypeName ?? undefined,
    }),
    customQuery: customCollectibleFormValues.customQuery ?? undefined,
    purchaseDate: purchaseDetails.purchaseDate,
    purchasePricePerItem: convertPriceToUSD({ value: purchaseDetails.purchasePricePerItemInDisplayCurrency }),
    gradingPricePerItem: convertPriceToUSD({ value: purchaseDetails.gradingPricePerItemInDisplayCurrency }),
    quantity: purchaseDetails.purchaseQuantity ?? 1,
    note,
    categoryId,
    imageUrl: customCollectibleFormValues.image ?? undefined,
    cardNumber: customSportsCardCollectibleFormValues.cardNumber,
    specificQualifier: customSportsCardCollectibleFormValues.specificQualifier,
  };
}

function getAddNonCustomParams({
  collectibleId,
  purchaseDetails,
  categoryId,
  collectibleType,
  note,
  convertPriceToUSD,
}: {
  collectibleType: CollectibleType;
  collectibleId: number;
  purchaseDetails: PurchaseDetailsFormValues;
  categoryId: number | null;
  note: string | null;
  convertPriceToUSD: ConvertPriceFn;
}): AddCollectibleToCollectionParams {
  return {
    collectibleType,
    collectibleId,
    isCustom: false,
    purchaseDate: purchaseDetails.purchaseDate,
    purchasePricePerItem: convertPriceToUSD({ value: purchaseDetails.purchasePricePerItemInDisplayCurrency }),
    gradingPricePerItem: convertPriceToUSD({ value: purchaseDetails.gradingPricePerItemInDisplayCurrency }),
    quantity: purchaseDetails.purchaseQuantity ?? 1,
    note,
    categoryId,
  };
}
