import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons';
import FieldLabelBox from '../../../../../sci-ui-components/forms/FieldLabelBox/FieldLabelBox';
import { CustomSportsCardCollectible } from '../../../../../sci-ui-components/types/collectible';
import PriceField from '../../../../../sci-ui-components/forms/PriceField/PriceField';
import TextField from '../../../../../sci-ui-components/forms/TextField/TextField';
import FormSection from '../../../../../sci-ui-components/forms/FormSection/FormSection';
import FormFieldsRow from '../../../../../sci-ui-components/forms/FormFieldsRow/FormFieldsRow';
import { CollectibleType } from '../../../../../sci-ui-components/types/collectibleType';
import { CardGradesAutoComplete } from '../../../../cardGrades/CardGradesAutoComplete';
import { CardSetsAutoComplete } from '../../../../cardSets/CardSetsAutoComplete';
import { PlayersAutoComplete } from '../../../../players/PlayersAutoComplete';
import FormButtons from '../../FormButtons';
import { CustomCollectibleQueryFields } from './CustomCollectibleQueryFields';
import { commonValidationSchema, getCommonCustomCollectibleInitialValuesFromCollectible } from './common';
import { CustomSportsCardCollectibleFormValues } from './types';
import { CustomCollectiblePhotoSelector } from './CustomCollectiblePhotoSelector';
import ManualCollectibleValueTriggerSpan from '@/features/collection/EditManualCollectibleValueDialog/ManualCollectibleValueTriggerSpan';
import useCurrencyFormatter, { ConvertPriceFn } from '@/hooks/useCurrencyFormatter';

const collectibleType: CollectibleType = 'sports-card';

export default function CustomSportsCardCollectibleForm({
  initialValues,
  onSubmit,
  formId,
  isEdit,
  isLoading,
  onCancel,
  customCollectibleId,
}: {
  initialValues: CustomSportsCardCollectibleFormValues | null;
  onSubmit: (values: CustomSportsCardCollectibleFormValues) => void;
  formId: string;
  isEdit: boolean;
  isLoading: boolean;
  onCancel: () => void;
  customCollectibleId: number | null;
}) {
  const cardSetFieldId = `${formId}-cardSet`;
  const currentValueFieldId = `${formId}-currentValue`;
  const playerFieldId = `${formId}-player`;
  const variationNameFieldId = `${formId}-variationName`;
  const gradeFieldId = `${formId}-grade`;
  const cardNumberFieldId = `${formId}-cardNumber`;
  const specificQualifierFieldId = `${formId}-specificQualifier`;
  const { convertPriceFromUSD } = useCurrencyFormatter();

  return (
    <Formik<CustomSportsCardCollectibleFormValues>
      initialValues={
        initialValues ??
        getCustomSportsCardCollectibleInitialValuesFromCollectible({ collectible: null, convertPriceFromUSD })
      }
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ values, handleChange, errors }) => (
        <Form id={formId}>
          <FormSection title="Required Fields" variant="primary">
            <FormFieldsRow>
              <FieldLabelBox label="Set" fieldId={cardSetFieldId} error={errors.cardSetName}>
                <CardSetsAutoComplete
                  id={cardSetFieldId}
                  value={values.cardSetName}
                  onSelect={(data) => {
                    handleChange({
                      target: { value: data?.year ?? null, name: 'cardSetYear' },
                    });
                    handleChange({
                      target: { value: data?.sport?.name ?? null, name: 'sportName' },
                    });
                    handleChange({ target: { value: data?.sport?.id ?? null, name: 'sportId' } });
                    handleChange({
                      target: { value: data?.displayText, name: 'cardSetName' },
                    });
                    handleChange({ target: { value: data?.id ?? null, name: 'cardSetId' } });
                  }}
                  onChange={(value) => {
                    handleChange({
                      target: { value: null, name: 'cardSetYear' },
                    });
                    handleChange({
                      target: { value: null, name: 'sportName' },
                    });
                    handleChange({ target: { value: null, name: 'sportId' } });
                    handleChange({
                      target: { value, name: 'cardSetName' },
                    });
                    handleChange({ target: { value: null, name: 'cardSetId' } });
                  }}
                />
              </FieldLabelBox>
              <FieldLabelBox label="Player" fieldId={playerFieldId} error={errors.playerName}>
                <PlayersAutoComplete
                  id={playerFieldId}
                  value={values.playerName}
                  onSelect={(data) => {
                    handleChange({ target: { value: data?.name, name: 'playerName' } });
                    handleChange({ target: { value: data?.id, name: 'playerId' } });
                  }}
                  onChange={(value) => {
                    handleChange({ target: { value, name: 'playerName' } });
                    handleChange({ target: { value: null, name: 'playerId' } });
                  }}
                />
              </FieldLabelBox>
              <FieldLabelBox label="Grade" fieldId={gradeFieldId} error={errors.gradeName}>
                <CardGradesAutoComplete
                  id={gradeFieldId}
                  value={values.gradeName}
                  onChange={(value) => {
                    handleChange({
                      target: { value, name: 'gradeName' },
                    });
                    handleChange({ target: { value: null, name: 'gradeId' } });
                  }}
                  onSelect={(data) => {
                    handleChange({
                      target: { value: data?.name, name: 'gradeName' },
                    });
                    handleChange({ target: { value: data?.id, name: 'gradeId' } });
                  }}
                />
              </FieldLabelBox>
            </FormFieldsRow>
          </FormSection>
          <FormSection title="Optional Fields" variant="secondary" noPadding>
            <CustomCollectiblePhotoSelector
              collectibleType={collectibleType}
              currentImageUrl={initialValues?.image ?? null}
              formId={formId}
            >
              <FormFieldsRow>
                <FieldLabelBox label="Variation" fieldId={variationNameFieldId} error={errors.variationName}>
                  <TextField
                    id={variationNameFieldId}
                    name="variationName"
                    value={values.variationName}
                    onChange={(value, name) => handleChange({ target: { value, name } })}
                  />
                </FieldLabelBox>
                <FieldLabelBox
                  label={`Current Value`}
                  fieldId={currentValueFieldId}
                  error={errors.currentValueInDisplayCurrency}
                  adornmentRight={
                    customCollectibleId ? (
                      <ManualCollectibleValueTriggerSpan
                        collectibleId={customCollectibleId}
                        collectibleType={collectibleType}
                        className="text-sm normal-case"
                      />
                    ) : null
                  }
                >
                  <PriceField
                    name="currentValueInDisplayCurrency"
                    value={values.currentValueInDisplayCurrency}
                    onChange={(value, name) => handleChange({ target: { value, name } })}
                    placeholder="Enter current value..."
                    min={0}
                    id={currentValueFieldId}
                  />
                </FieldLabelBox>
              </FormFieldsRow>
              <FormFieldsRow>
                <FieldLabelBox label="Card Number" fieldId={cardNumberFieldId} error={errors.cardNumber}>
                  <TextField
                    id={cardNumberFieldId}
                    name="cardNumber"
                    value={values.cardNumber}
                    onChange={(value, name) => handleChange({ target: { value, name } })}
                  />
                </FieldLabelBox>
                <FieldLabelBox
                  label={
                    <Tooltip
                      title="This field is optional and can be used to provide additional information about the card such as an image variation description (ex: “Bat Down”) or to describe a Base Subset (like “Stat Leaders”)"
                      trigger={'hover'}
                    >
                      <span>
                        Image Description &nbsp;
                        <FontAwesomeIcon icon={faCircleQuestion} />
                      </span>
                    </Tooltip>
                  }
                  fieldId={specificQualifierFieldId}
                  error={errors.specificQualifier}
                >
                  <TextField
                    id={specificQualifierFieldId}
                    name="specificQualifier"
                    value={values.specificQualifier}
                    onChange={(value, name) => handleChange({ target: { value, name } })}
                  />
                </FieldLabelBox>
              </FormFieldsRow>
              <CustomCollectibleQueryFields formId={formId} />
            </CustomCollectiblePhotoSelector>
          </FormSection>
          <FormButtons isEdit={isEdit} isLoading={isLoading} onCancel={onCancel} isFirstStep />
        </Form>
      )}
    </Formik>
  );
}

const validationSchema: Yup.SchemaOf<CustomSportsCardCollectibleFormValues> = Yup.object()
  .shape({
    sportName: Yup.string().nullable(),
    sportId: Yup.number().nullable(),
    playerId: Yup.number().nullable(),
    playerName: Yup.string().nullable(),
    cardSetYear: Yup.string().nullable(),
    cardSetName: Yup.string().nullable().required('Required'),
    cardSetId: Yup.number().nullable(),
    variationName: Yup.string().nullable(),
    gradeName: Yup.string().nullable().required('Required'),
    gradeId: Yup.number().nullable(),
    cardNumber: Yup.string().nullable(),
    specificQualifier: Yup.string().nullable(),
  })
  .concat(commonValidationSchema);

export function getCustomSportsCardCollectibleInitialValuesFromCollectible({
  collectible,
  convertPriceFromUSD,
}: {
  collectible: CustomSportsCardCollectible | null;
  convertPriceFromUSD: ConvertPriceFn;
}): CustomSportsCardCollectibleFormValues {
  if (!collectible) {
    return {
      sportName: null,
      playerName: null,
      playerId: null,
      variationName: null,
      gradeName: null,
      gradeId: null,
      cardSetYear: null,
      cardSetName: null,
      cardSetId: null,
      cardNumber: null,
      specificQualifier: null,
      ...getCommonCustomCollectibleInitialValuesFromCollectible({ collectible: null, convertPriceFromUSD }),
    };
  }
  const cardSetName = collectible?.cardSet?.name ?? collectible?.customCardSetName ?? null;
  const cardSetYear = collectible?.cardSet?.year ?? collectible?.customCardSetYear ?? null;
  const sportName = collectible?.cardSet?.sport?.name ?? collectible?.sportName ?? null;
  return {
    sportId: collectible?.cardSet?.sport?.id ?? null,
    sportName,
    playerName: collectible?.playerName ?? collectible?.player?.name ?? null,
    playerId: collectible?.player?.id ?? collectible?.playerId ?? null,
    variationName: collectible?.variationName ?? null,
    gradeName: collectible?.gradeName ?? null,
    gradeId: null,
    cardSetYear,
    cardSetName: [cardSetYear, cardSetName, sportName].filter(Boolean).join(' '),
    cardSetId: collectible?.cardSet?.id ?? collectible?.customCardSetId ?? null,
    cardNumber: collectible.cardNumber ?? null,
    specificQualifier: collectible.specificQualifier ?? null,
    ...getCommonCustomCollectibleInitialValuesFromCollectible({ collectible, convertPriceFromUSD }),
  };
}
