import { useEffect, useState } from 'react';
import { withErrorBoundary } from '../../ErrorBoundary/ErrorBoundary';
import ImageCropperDialog from './ImageCropperDialog';
import { registerDialog, ImageCropperDialogProviderProps } from './index';

type State = Partial<ImageCropperDialogProviderProps> & {
  isOpen: boolean;
};

export default withErrorBoundary(function ImageCropperDialogProvider({ id }: { id: string }) {
  const [
    { isOpen, resolve, imageDataUrl, collectibleType = 'sports-card', aspectRatio, modalWidth, autoCropArea },
    setState,
  ] = useState<State>({
    isOpen: false,
  });

  useEffect(
    () =>
      registerDialog(id, (props) => {
        setState({
          isOpen: true,
          ...props,
        });
      }),
    [id]
  );

  return (
    <ImageCropperDialog
      isOpen={isOpen}
      collectibleType={collectibleType}
      aspectRatio={aspectRatio}
      modalWidth={modalWidth}
      autoCropArea={autoCropArea}
      imageDataUrl={imageDataUrl ?? null}
      onClose={() => setState((s) => ({ ...s, isOpen: false }))}
      onConfirm={(imageDataUrl: string | null) => {
        resolve?.(imageDataUrl);
      }}
      onCancel={() => {
        resolve?.(null);
      }}
    />
  );
});
