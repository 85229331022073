import { ReactNode, useEffect, useMemo, useState } from 'react';
import { AutoComplete as AntdAutoComplete } from 'antd';
import clsx from 'clsx';
import { useInView } from 'react-intersection-observer';
import { useSelectPopupContainer } from '../SimpleSelect/useSelectPopupContainer';
import classes from './AutoComplete.module.scss';
import useStableFunctionIdentity from '@/sci-ui-components/hooks/useStableFunctionIdentity';

type Props<TData extends any = any> = AutoCompleteProps<TData> & {
  options: TData[];
  pageSize?: number;
};

export function AutoComplete<TData = any>({
  id,
  value,
  className,
  onChange,
  onSelect,
  options,
  disabled,
  placeholder,
  makeOption,
  pageSize = 50,
}: Props<TData>) {
  const { getPopupContainer } = useSelectPopupContainer();
  const filteredOptions = useMemo(() => {
    if (!value) {
      return options.map(makeOption);
    }
    const keywords = value.toLowerCase().trim().replace(/\s+/g, ' ')?.split(' ');
    return options
      .map(makeOption)
      .filter((option) => keywords.every((word) => option.label.toLowerCase().includes(word)));
  }, [value, options, makeOption]);

  const [maxItemsToDisplay, setMaxItemsToDisplay] = useState(pageSize);
  useEffect(() => setMaxItemsToDisplay(pageSize), [filteredOptions.length, pageSize]);
  const handleInViewChange = useStableFunctionIdentity((isInView: boolean) => {
    if (isInView) {
      setMaxItemsToDisplay((v) => v + pageSize);
    }
  });
  const { ref: inViewRef } = useInView({
    skip: maxItemsToDisplay >= filteredOptions.length,
    initialInView: false,
    triggerOnce: false,
    onChange: handleInViewChange,
    delay: 100,
  });

  return (
    <div className={clsx(classes.selectWrapper, className)}>
      <AntdAutoComplete
        id={id}
        value={value}
        options={filteredOptions.slice(0, maxItemsToDisplay).map((option, i) => ({
          ...option,
          label: <span ref={i === maxItemsToDisplay - 1 ? inViewRef : undefined}>{option.label}</span>,
        }))}
        className={classes.select}
        onSearch={onChange}
        searchValue={value ?? ''}
        onSelect={(_: any, option: AutoCompleteOption<TData, ReactNode>) => onSelect(option.data)}
        allowClear
        onClear={() => onSelect(null)}
        disabled={disabled}
        placeholder={placeholder}
        dropdownMatchSelectWidth={false}
        virtual
        getPopupContainer={getPopupContainer}
      />
    </div>
  );
}

export interface AutoCompleteOption<TData = any, TLabel = string> {
  value: string;
  label: TLabel;
  key: string;
  data: TData;
}

export interface AutoCompleteProps<TData = any> {
  id?: string;
  className?: string;
  value?: string | null;
  onSelect: (option: TData | null) => void;
  onChange: (value: string) => void;
  disabled?: boolean;
  placeholder?: string;
  makeOption: (data: TData) => AutoCompleteOption<TData, string>;
}
