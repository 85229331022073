import { useEffect, useMemo } from 'react';
import { getCollectionCollectible } from '@sportscardinvestor/collection-helpers';
import {
  getCollectibleId,
  getCollectibleType,
  isCustomCollectible,
  isSealedCollectible,
  isSportsCardCustomCollectible,
} from '@sportscardinvestor/collectible-helpers';
import ModalDialog from '../../../sci-ui-components/ModalDialog/ModalDialog';
import useCollectionItemMutations from '../useCollectionItemMutations';
import { useCollectionItem } from '../useListCollectionItems';
import { useEditCustomCollectionItemDialogStore } from './store';
import {
  EditSportsCardCustomCollectionItemForm,
  getEditSportsCardCustomCollectionItemFormInitialValues,
} from './forms/EditSportsCardCustomCollectionItemForm';
import {
  EditSealedCustomCollectionItemForm,
  getEditSealedCustomCollectionItemFormInitialValues,
} from './forms/EditSealedCustomCollectionItemForm';
import useAnalytics from '@/features/analytics/useAnalytics';
import useCurrencyFormatter from '@/hooks/useCurrencyFormatter';
import { collectibleNames } from '@/sci-ui-components/collectibles/constants';
import { withErrorBoundary } from '@/sci-ui-components/ErrorBoundary/ErrorBoundary';

const formId = 'EditCustomCollectionItemForm';

export const EditCustomCollectionItemDialog = withErrorBoundary(function EditCustomCollectionItemDialog() {
  const { collectionItemId, collectibleType, close } = useEditCustomCollectionItemDialogStore();
  const { collectionItem } = useCollectionItem({
    collectibleType,
    collectionItemId,
  });
  const collectible = collectionItem ? getCollectionCollectible(collectionItem) : null;
  const customCollectible = collectible && isCustomCollectible(collectible) ? collectible : null;
  const { updateCollectionItem } = useCollectionItemMutations();
  const { convertPriceToUSD, convertPriceFromUSD } = useCurrencyFormatter();
  const { trackEvent } = useAnalytics();
  useEffect(() => {
    if (customCollectible?.id) {
      trackEvent({
        eventName: 'CUSTOM_COLLECTIBLE_DETAILS_STARTED',
        collectibleId: Number(customCollectible.id),
      });
    }
  }, [trackEvent, customCollectible?.id]);

  const initialValuesSportsCard = useMemo(
    () =>
      getEditSportsCardCustomCollectionItemFormInitialValues({
        collectible: customCollectible ?? null,
        convertPriceFromUSD,
      }),
    [customCollectible, convertPriceFromUSD]
  );
  const initialValuesSealed = useMemo(
    () =>
      getEditSealedCustomCollectionItemFormInitialValues({
        collectible: customCollectible ?? null,
        convertPriceFromUSD,
      }),
    [customCollectible, convertPriceFromUSD]
  );

  const collectibleName = collectibleNames[collectibleType].shortSingular;

  return (
    <ModalDialog
      open={!!collectionItem}
      title={`Edit Manual ${collectibleName}`}
      onCancel={close}
      footer={null}
      variant="primary"
      width={960}
      withHeaderUnderline
    >
      {!!customCollectible && isSportsCardCustomCollectible(customCollectible) && (
        <EditSportsCardCustomCollectionItemForm
          collectibleId={getCollectibleId(customCollectible)}
          formId={formId}
          initialValues={initialValuesSportsCard}
          collectibleType={getCollectibleType(customCollectible)}
          isLoading={updateCollectionItem.isLoading}
          onCancel={close}
          onSubmit={({
            cardNumber,
            currentValueInDisplayCurrency,
            customQuery,
            gradeName,
            imageUrl,
            playerId,
            playerName,
            setId,
            setName,
            setYear,
            specificQualifier,
            sportName,
            variationName,
          }) => {
            updateCollectionItem.mutate(
              {
                customCollectible: {
                  collectibleId: getCollectibleId(customCollectible),
                  collectibleType,
                  variationName,
                  sportName,
                  playerName,
                  playerId,
                  gradeName,
                  cardSetYear: setYear,
                  cardSetName: setName,
                  cardSetId: setId,
                  imageUrl: imageUrl ?? undefined,
                  currentValue: convertPriceToUSD({ value: currentValueInDisplayCurrency }),
                  customQuery: customQuery,
                  cardNumber,
                  specificQualifier,
                },
                collectibleType,
              },
              {
                onSuccess: close,
              }
            );
          }}
        />
      )}
      {!!customCollectible && isSealedCollectible(customCollectible) && (
        <EditSealedCustomCollectionItemForm
          collectibleId={getCollectibleId(customCollectible)}
          formId={formId}
          initialValues={initialValuesSealed}
          collectibleType={getCollectibleType(customCollectible)}
          isLoading={updateCollectionItem.isLoading}
          onCancel={close}
          onSubmit={({
            currentValueInDisplayCurrency,
            customQuery,
            imageUrl,
            setId,
            setName,
            setYear,
            sportName,
            boxTypeName,
          }) => {
            updateCollectionItem.mutate(
              {
                customCollectible: {
                  collectibleId: getCollectibleId(customCollectible),
                  collectibleType,
                  boxTypeName,
                  sportName,
                  cardSetYear: setYear,
                  cardSetName: setName,
                  cardSetId: setId,
                  imageUrl: imageUrl ?? undefined,
                  currentValue: convertPriceToUSD({ value: currentValueInDisplayCurrency }),
                  customQuery: customQuery,
                },
                collectibleType,
              },
              {
                onSuccess: close,
              }
            );
          }}
        />
      )}
    </ModalDialog>
  );
});
