import { ReactNode } from 'react';
import clsx from 'clsx';
import { faPencil } from '@fortawesome/pro-light-svg-icons';
import SimpleCollapsible from '../../SimpleCollapsible/SimpleCollapsible';
import Button from '../../buttons/Button/Button';

import classes from './VerticalFormStepper.module.scss';

export interface FormStep<TStepId extends string> {
  id: TStepId;
  renderActiveContent?: () => ReactNode;
  renderSummaryTitle: () => ReactNode;
  renderSummaryDetails?: () => ReactNode;
  onEdit?: () => void;
}

export default function VerticalFormStepper<TStepId extends string>({
  className,
  steps,
  activeStepId,
  editIsPrimary = false,
}: {
  className?: string;
  steps: (FormStep<TStepId> | null)[];
  activeStepId: TStepId | null;
  editIsPrimary?: boolean;
}) {
  return (
    <div className={clsx(classes.root, className)}>
      <ul className={classes.list}>
        {steps.map((step) => {
          if (!step) {
            return null;
          }
          const { id, renderActiveContent, renderSummaryTitle, renderSummaryDetails, onEdit } = step;
          const isActive = activeStepId === id;
          const isEditable = !!renderActiveContent && !!onEdit;
          return (
            <li key={id} className={classes.listItem}>
              <section className={classes.section}>
                <SimpleCollapsible isExpanded={!isActive}>
                  <header className={classes.header}>
                    <div className={classes.summaryTitle}>{renderSummaryTitle()}</div>
                    {isEditable && (
                      <Button
                        className={classes.editButton}
                        onClick={onEdit}
                        type={editIsPrimary ? 'primary' : 'link'}
                        faIcon={faPencil}
                        size="middle"
                        minWidth={140}
                      >
                        Edit
                      </Button>
                    )}
                  </header>
                  {renderSummaryDetails?.()}
                </SimpleCollapsible>
                {isEditable && <SimpleCollapsible isExpanded={isActive}>{renderActiveContent()}</SimpleCollapsible>}
              </section>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
