import FieldLabelBox from '../../../sci-ui-components/forms/FieldLabelBox/FieldLabelBox';
import TextField from '../../../sci-ui-components/forms/TextField/TextField';
import FormSection from '../../../sci-ui-components/forms/FormSection/FormSection';
import FormFieldsRow from '../../../sci-ui-components/forms/FormFieldsRow/FormFieldsRow';

export default function NotesForm({
  note,
  onSubmit,
  dialogId,
  isEdit,
}: {
  note: string | null;
  onSubmit: (note: string) => void;
  dialogId: string;
  isEdit: boolean;
}) {
  const noteFieldId = `${dialogId}-note`;
  return (
    <FormSection>
      <FormFieldsRow>
        <FieldLabelBox label="Note" fieldId={noteFieldId} extraInfo="Do not use commas if you plan to Download to CSV">
          <TextField id={noteFieldId} name="note" value={note} onChange={onSubmit} isMultiLine rows={isEdit ? 5 : 3} />
        </FieldLabelBox>
      </FormFieldsRow>
    </FormSection>
  );
}

export function isNoteUpdated(initial: string | null, updated: string | null) {
  const trimmedInitial = initial?.trim() ?? null;
  const trimmedUpdated = updated?.trim() ?? null;
  return trimmedInitial !== trimmedUpdated;
}
