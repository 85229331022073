import { CollectibleType } from '@sportscardinvestor/schemas';
import { collectibleNames } from '../collectibles/constants';
import ManualCollectibleValueTriggerSpan from './EditManualCollectibleValueDialog/ManualCollectibleValueTriggerSpan';
import CollectibleImage from '@/sci-ui-components/collectibles/CollectibleImage/CollectibleImage';
import {
  CommonCollectibleFieldsDescription,
  CommonCollectibleFieldsDescriptionProps,
} from '@/sci-ui-components/collectibles/CommonCollectibleDescription/CommonCollectibleDescription';
import BigStat from '@/sci-ui-components/stats/BigStat/BigStat';
import { formatDate } from '@/sci-ui-components/utils/date';

export type CollectionItemCollectibleInfoProps = {
  imageUrl: string | null | undefined;
  collectibleType: CollectibleType;
  gradeName: string | null | undefined;
  doNotConvertToDisplayCurrency?: boolean;
  currentPrice: number | null | undefined;
  currentPriceDate: string | null | undefined;
  collectibleId: number | null;
} & Omit<
  CommonCollectibleFieldsDescriptionProps,
  | 'statSlot'
  | 'grade'
  | 'descriptionClassName'
  | 'detailsRowClassName'
  | 'gradeIconClassName'
  | 'gradeTextClassName'
  | 'rookieIndicatorClassName'
  | 'titleClassName'
>;

export function CollectionItemCollectibleInfo({
  className,
  imageUrl,
  collectibleType,
  gradeName,
  doNotConvertToDisplayCurrency,
  currentPrice,
  currentPriceDate,
  collectibleId,
  isManual,
  descriptionLine1,
  descriptionLine2,
  title,
  alternateGrades,
  isRookie,
  onPopulationCountClick,
  populationCount,
  onGradeChange,
}: CollectionItemCollectibleInfoProps) {
  const statTooltipText = `We currently do not have any recorded sales for this ${collectibleNames[
    collectibleType
  ].singular.toLowerCase()}. We will automatically update its market value once the first sale comes in, until then we will assign its value to the price you paid for it.`;

  return (
    <div className={`flex flex-row gap-3 items-start ${className}`}>
      <CollectibleImage
        url={imageUrl}
        collectibleType={collectibleType}
        className="flex grow-0 shrink-0 w-20"
        alt={descriptionLine1}
      />
      <CommonCollectibleFieldsDescription
        className="flex grow shrink"
        title={title}
        descriptionLine1={descriptionLine1}
        descriptionLine2={descriptionLine2}
        grade={gradeName}
        isManual={isManual}
        isRookie={isRookie}
        populationCount={populationCount}
        onPopulationCountClick={onPopulationCountClick}
        onGradeChange={onGradeChange}
        alternateGrades={alternateGrades}
        statSlot={
          <div>
            <BigStat
              label="Current Price"
              value={currentPrice}
              defaultValue="N/A"
              doNotConvertToDisplayCurrency={doNotConvertToDisplayCurrency}
              type="price"
              appendedLabel={
                isManual && !!collectibleId ? (
                  <ManualCollectibleValueTriggerSpan collectibleId={collectibleId} collectibleType={collectibleType} />
                ) : null
              }
              tooltipText={!isManual && !currentPrice ? statTooltipText : undefined}
            />
            {!!currentPriceDate && (
              <p className="mt-1 text-gray-primary text-sm font-normal text-right">
                Last Sale {formatDate(currentPriceDate)}
              </p>
            )}
          </div>
        }
      />
    </div>
  );
}
