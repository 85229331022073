import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FieldLabelBox from '../../../../../sci-ui-components/forms/FieldLabelBox/FieldLabelBox';
import { CustomSealedWaxCollectible } from '../../../../../sci-ui-components/types/collectible';
import PriceField from '../../../../../sci-ui-components/forms/PriceField/PriceField';
import FormSection from '../../../../../sci-ui-components/forms/FormSection/FormSection';
import FormFieldsRow from '../../../../../sci-ui-components/forms/FormFieldsRow/FormFieldsRow';
import { CollectibleType } from '../../../../../sci-ui-components/types/collectibleType';
import { SealedWaxBoxTypeAutoComplete } from '../../../../sealedWaxBoxTypes/SealedWaxBoxTypesAutoComplete';
import { CardSetsAutoComplete } from '../../../../cardSets/CardSetsAutoComplete';
import FormButtons from '../../FormButtons';
import { CustomCollectibleQueryFields } from './CustomCollectibleQueryFields';
import { commonValidationSchema, getCommonCustomCollectibleInitialValuesFromCollectible } from './common';
import { CustomSealedWaxCollectibleFormValues } from './types';
import { CustomCollectiblePhotoSelector } from './CustomCollectiblePhotoSelector';
import ManualCollectibleValueTriggerSpan from '@/features/collection/EditManualCollectibleValueDialog/ManualCollectibleValueTriggerSpan';
import useCurrencyFormatter, { ConvertPriceFn } from '@/hooks/useCurrencyFormatter';

const collectibleType: CollectibleType = 'sealed-wax-card';

export default function CustomSealedWaxCollectibleForm({
  initialValues,
  onSubmit,
  formId,
  isEdit,
  isLoading,
  onCancel,
  customCollectibleId,
}: {
  initialValues: CustomSealedWaxCollectibleFormValues | null;
  onSubmit: (values: CustomSealedWaxCollectibleFormValues) => void;
  formId: string;
  isEdit: boolean;
  isLoading: boolean;
  onCancel: () => void;
  customCollectibleId: number | null;
}) {
  const cardSetFieldId = `${formId}-cardSet`;
  const currentValueFieldId = `${formId}-currentValue`;
  const boxTypeFieldId = `${formId}-boxType`;
  const { convertPriceFromUSD } = useCurrencyFormatter();

  return (
    <Formik<CustomSealedWaxCollectibleFormValues>
      initialValues={
        initialValues ??
        getCustomSealedWaxCollectibleInitialValuesFromCollectible({ collectible: null, convertPriceFromUSD })
      }
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ values, handleChange, errors }) => (
        <Form id={formId}>
          <FormSection title="Required Fields" variant="primary">
            <FormFieldsRow>
              <FieldLabelBox label="Set" fieldId={cardSetFieldId} error={errors.cardSetName}>
                <CardSetsAutoComplete
                  id={cardSetFieldId}
                  value={values.cardSetName}
                  onChange={(value) => {
                    handleChange({
                      target: { value: null, name: 'cardSetYear' },
                    });
                    handleChange({
                      target: { value: null, name: 'sportName' },
                    });
                    handleChange({ target: { value: null, name: 'sportId' } });
                    handleChange({
                      target: { value, name: 'cardSetName' },
                    });
                    handleChange({ target: { value: null, name: 'cardSetId' } });
                  }}
                  onSelect={(data) => {
                    handleChange({
                      target: { value: data?.year, name: 'cardSetYear' },
                    });
                    handleChange({
                      target: { value: data?.sport?.name ?? null, name: 'sportName' },
                    });
                    handleChange({ target: { value: data?.sport?.id ?? null, name: 'sportId' } });
                    handleChange({
                      target: { value: data?.displayText, name: 'cardSetName' },
                    });
                    handleChange({ target: { value: data?.id, name: 'cardSetId' } });
                  }}
                />
              </FieldLabelBox>
              <FieldLabelBox label="Box Type" fieldId={boxTypeFieldId} error={errors.boxTypeName}>
                <SealedWaxBoxTypeAutoComplete
                  id={boxTypeFieldId}
                  value={values.boxTypeName}
                  onChange={(value) => {
                    handleChange({ target: { value: value, name: 'boxTypeName' } });
                    handleChange({ target: { value: null, name: 'boxTypeId' } });
                  }}
                  onSelect={(data) => {
                    handleChange({ target: { value: data?.name, name: 'boxTypeName' } });
                    handleChange({ target: { value: data?.id, name: 'boxTypeId' } });
                  }}
                />
              </FieldLabelBox>
            </FormFieldsRow>
          </FormSection>
          <FormSection title="Optional Fields" variant="secondary" noPadding>
            <CustomCollectiblePhotoSelector
              collectibleType={collectibleType}
              currentImageUrl={initialValues?.image ?? null}
              formId={formId}
            >
              <FormFieldsRow isSingleItemRow>
                <FieldLabelBox
                  label={`Current Value`}
                  fieldId={currentValueFieldId}
                  error={errors.currentValueInDisplayCurrency}
                  adornmentRight={
                    customCollectibleId ? (
                      <ManualCollectibleValueTriggerSpan
                        collectibleId={customCollectibleId}
                        collectibleType={collectibleType}
                        className="text-sm normal-case"
                      />
                    ) : null
                  }
                >
                  <PriceField
                    name="currentValueInDisplayCurrency"
                    value={values.currentValueInDisplayCurrency}
                    onChange={(value, name) => handleChange({ target: { value, name } })}
                    placeholder="Enter current value..."
                    min={0}
                    id={currentValueFieldId}
                  />
                </FieldLabelBox>
              </FormFieldsRow>
              <CustomCollectibleQueryFields formId={formId} />
            </CustomCollectiblePhotoSelector>
          </FormSection>
          <FormButtons isEdit={isEdit} isLoading={isLoading} onCancel={onCancel} isFirstStep />
        </Form>
      )}
    </Formik>
  );
}

const validationSchema: Yup.SchemaOf<CustomSealedWaxCollectibleFormValues> = Yup.object()
  .shape({
    sportName: Yup.string().nullable(),
    sportId: Yup.number().nullable(),
    cardSetYear: Yup.string().nullable(),
    cardSetName: Yup.string().nullable().required('Required'),
    cardSetId: Yup.number().nullable(),
    boxTypeName: Yup.string().nullable().required('Required'),
    boxTypeId: Yup.string().optional().nullable(),
  })
  .concat(commonValidationSchema);

export function getCustomSealedWaxCollectibleInitialValuesFromCollectible({
  collectible,
  convertPriceFromUSD,
}: {
  collectible: CustomSealedWaxCollectible | null;
  convertPriceFromUSD: ConvertPriceFn;
}): CustomSealedWaxCollectibleFormValues {
  if (!collectible) {
    return {
      sportName: null,
      cardSetYear: null,
      cardSetName: null,
      cardSetId: null,
      boxTypeName: null,
      boxTypeId: null,
      ...getCommonCustomCollectibleInitialValuesFromCollectible({ collectible: null, convertPriceFromUSD }),
    };
  }
  const cardSetName = collectible?.cardSet?.name ?? collectible?.customCardSetName ?? null;
  const cardSetYear = collectible?.cardSet?.year ?? collectible?.customCardSetYear ?? null;
  const sportName = collectible?.cardSet?.sport?.name ?? collectible?.sportName ?? null;
  return {
    sportId: collectible?.cardSet?.sport?.id ?? null,
    sportName,
    cardSetYear,
    cardSetName: [cardSetYear, cardSetName, sportName].filter(Boolean).join(' '),
    cardSetId: collectible?.cardSet?.id ?? collectible?.customCardSetId ?? null,
    boxTypeId: null,
    boxTypeName: collectible?.boxTypeName,
    ...getCommonCustomCollectibleInitialValuesFromCollectible({ collectible, convertPriceFromUSD }),
  };
}
