import authenticatedFetchFromSciApi from '../authenticatedFetchFromSciApi';
import { ApiAssignedCardSetVariation, ApiCardSet, ApiCardSetVariationExtended } from '../types';
import cardSetFromApi from '../collectibles/transformers/cardSetFromApi';
import assignedCardSetVariationFromApi from './transformers/assignedCardSetVariationFromApi';
import cardSetVariationExtendedFromApi from './transformers/cardSetVariationExtendedFromApi';

export async function getCardSets(signal: AbortSignal | undefined) {
  const apiItems = await authenticatedFetchFromSciApi<ApiCardSet[]>('/sets', {
    method: 'GET',
    signal,
  });
  return apiItems?.map(cardSetFromApi);
}

export async function getAssignedCardSetVariations(signal: AbortSignal | undefined) {
  const apiItems = await authenticatedFetchFromSciApi<ApiAssignedCardSetVariation[]>('/set-variations/assigned', {
    method: 'GET',
    signal,
  });
  return apiItems?.map(assignedCardSetVariationFromApi);
}

export async function getCardSetVariations(signal: AbortSignal | undefined) {
  const apiItems = await authenticatedFetchFromSciApi<ApiCardSetVariationExtended[]>('/set-variations', {
    method: 'GET',
    signal,
  });
  return apiItems?.map(cardSetVariationExtendedFromApi);
}
