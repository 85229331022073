import {
  CollectibleType,
  isSealedWaxCardCollectibleType,
  isSportsCardCollectibleType,
} from '../../../../../sci-ui-components/types/collectibleType';
import {
  CustomCollectible,
  CustomSealedWaxCollectible,
  CustomSportsCardCollectible,
} from '../../../../../sci-ui-components/types/collectible';
import { constructCustomCardQueryString } from '../../../../../services/sciApi/collections/utils/constructCustomCardQueryString';
import useCollectionItemMutations from '../../../../collection/useCollectionItemMutations';
import {
  CustomCollectibleFormValues,
  CustomSealedWaxCollectibleFormValues,
  CustomSportsCardCollectibleFormValues,
} from './types';
import CustomSportsCardCollectibleForm, {
  getCustomSportsCardCollectibleInitialValuesFromCollectible,
} from './CustomSportsCardCollectibleForm';
import CustomSealedWaxCollectibleForm, {
  getCustomSealedWaxCollectibleInitialValuesFromCollectible,
} from './CustomSealedWaxCollectibleForm';
import useCurrencyFormatter, { ConvertPriceFn } from '@/hooks/useCurrencyFormatter';

export default function CustomCollectibleForm({
  collectibleId,
  collectibleType,
  initialValues,
  onSubmit,
  dialogId,
  onCancel,
  customCollectibleId,
}: {
  collectibleType: CollectibleType;
  collectibleId: number | null;
  initialValues: CustomCollectibleFormValues | null;
  onSubmit: (values: CustomCollectibleFormValues) => void;
  dialogId: string;
  onCancel: () => void;
  customCollectibleId: number | null;
}) {
  const formId = `${dialogId}-collectible`;
  const isEdit = !!collectibleId;

  const { updateCollectionItem } = useCollectionItemMutations();
  const { convertPriceToUSD } = useCurrencyFormatter();

  const handleSubmit = async (values: CustomCollectibleFormValues) => {
    if (!isEdit) {
      return onSubmit(values);
    }
    const boxTypeId = (values as CustomSealedWaxCollectibleFormValues).boxTypeId ?? undefined;
    const boxTypeName = (values as CustomSealedWaxCollectibleFormValues).boxTypeName ?? undefined;
    const playerName = (values as CustomSportsCardCollectibleFormValues).playerName ?? undefined;
    const playerId = (values as CustomSportsCardCollectibleFormValues).playerId ?? undefined;
    const variationName = (values as CustomSportsCardCollectibleFormValues).variationName ?? undefined;
    const gradeName = (values as CustomSportsCardCollectibleFormValues).gradeName ?? undefined;
    const cardNumber = (values as CustomSportsCardCollectibleFormValues).cardNumber ?? undefined;
    const specificQualifier = (values as CustomSportsCardCollectibleFormValues).specificQualifier ?? undefined;
    // custom card sport can't be empty
    const sportName = values.sportName ? values.sportName : collectibleType === 'sealed-wax-card' ? null : '';
    const cardSetYear = values.cardSetYear ?? null;
    const cardSetName = values.cardSetName ?? undefined;
    await updateCollectionItem.mutateAsync({
      customCollectible: {
        collectibleId: collectibleId! ?? undefined,
        collectibleType,
        variationName,
        sportName,
        playerName,
        playerId,
        gradeName,
        cardSetYear,
        cardSetName,
        cardSetId: values.cardSetId,
        boxTypeName,
        imageUrl: values.image ?? undefined,
        currentValue: convertPriceToUSD({ value: values.currentValueInDisplayCurrency }),
        customQuery: values.customQuery ?? undefined,
        cardNumber,
        specificQualifier,
        // TODO: move this into api function
        query: constructCustomCardQueryString({
          queryYear: cardSetYear ?? undefined,
          querySet: cardSetName,
          querySport: sportName && sportName?.length > 0 ? sportName : undefined,
          queryBoxTypeId: boxTypeId,
          queryBoxTypeName: boxTypeName,
          queryPlayerName: playerName,
          queryVariation: variationName,
          queryGrade: gradeName,
        }),
      },
      collectibleType,
    });
    return onSubmit(values);
  };

  if (isSportsCardCollectibleType(collectibleType)) {
    return (
      <CustomSportsCardCollectibleForm
        formId={formId}
        isEdit={isEdit}
        onSubmit={handleSubmit}
        initialValues={initialValues as CustomSportsCardCollectibleFormValues}
        onCancel={onCancel}
        isLoading={updateCollectionItem.isLoading}
        customCollectibleId={customCollectibleId}
      />
    );
  }
  if (isSealedWaxCardCollectibleType(collectibleType)) {
    return (
      <CustomSealedWaxCollectibleForm
        formId={formId}
        isEdit={isEdit}
        onSubmit={handleSubmit}
        initialValues={initialValues as CustomSealedWaxCollectibleFormValues}
        onCancel={onCancel}
        isLoading={updateCollectionItem.isLoading}
        customCollectibleId={customCollectibleId}
      />
    );
  }
  return null;
}

export function getCustomCollectibleInitialValues({
  collectible,
  collectibleType,
  convertPriceFromUSD,
}: {
  collectible: CustomCollectible | null;
  collectibleType: CollectibleType;
  convertPriceFromUSD: ConvertPriceFn;
}): CustomCollectibleFormValues | null {
  if (isSportsCardCollectibleType(collectibleType)) {
    return getCustomSportsCardCollectibleInitialValuesFromCollectible({
      collectible: collectible as CustomSportsCardCollectible | null,
      convertPriceFromUSD,
    });
  }
  if (isSealedWaxCardCollectibleType(collectibleType)) {
    return getCustomSealedWaxCollectibleInitialValuesFromCollectible({
      collectible: collectible as CustomSealedWaxCollectible | null,
      convertPriceFromUSD,
    });
  }
  return null;
}
