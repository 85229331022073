import { useMemo } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { AlternateCardGrade, CardGrade, ExtendedAlternateCardGrade } from '../../sci-ui-components/types/cardGrade';
import { GetAlternateCardGradesParams, getAlternateCardGrades } from '../../services/sciApi/cardGrades/index';
import { ApiError } from '../../utils/api';
import useAuth from '../auth/useAuth';
import useCardGrades from './useCardGrades';

export const keyPrefix = 'available-card-grades';
type Key = [typeof keyPrefix, GetAlternateCardGradesParams];

export default function useAlternateCardGrades(
  { collectibleId, excludeCollectibleIds }: { collectibleId?: number; excludeCollectibleIds?: number[] },
  options: UseQueryOptions<AlternateCardGrade[], ApiError, AlternateCardGrade[], Key> = {}
) {
  const { isLoggedIn } = useAuth();
  const { data, ...queryResult } = useQuery(
    [keyPrefix, { collectibleId: collectibleId! }],
    ({ signal }) => getAlternateCardGrades({ collectibleId: collectibleId! }, signal),
    {
      staleTime: 1000 * 60 * 60 * 24, // 1 day
      ...options,
      enabled: !!isLoggedIn && !!collectibleId && (options?.enabled ?? true),
    }
  );
  const { data: allCardGrades } = useCardGrades();
  const orderedAlternateGrades = useMemo(() => {
    if (!data?.length || !allCardGrades?.length) {
      return [];
    }
    const gradesMap = allCardGrades.reduce<Map<string, CardGrade>>((acc, grade) => {
      acc.set(grade.name, grade);
      return acc;
    }, new Map());
    const extendedAlternateCardGrades = data.reduce<ExtendedAlternateCardGrade[]>((acc, alternateGrade) => {
      const grade = gradesMap.get(alternateGrade.gradeName);
      if (!grade) {
        console.error(`Alternate grade "${alternateGrade.gradeName}" is missing in grades list`);
        return acc;
      }
      if (excludeCollectibleIds?.includes(alternateGrade.collectibleId)) {
        return acc;
      }
      acc.push({
        ...alternateGrade,
        grade,
      });
      return acc;
    }, []);
    return extendedAlternateCardGrades.sort((g1, g2) => g1.grade.order - g2.grade.order);
  }, [allCardGrades, data, excludeCollectibleIds]);

  return {
    ...queryResult,
    data: orderedAlternateGrades,
  };
}
