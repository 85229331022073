import authenticatedFetchFromSciApi from '../authenticatedFetchFromSciApi';
import { CollectibleType, isSportsCardCollectibleType } from '../../../sci-ui-components/types/collectibleType';
import getCollectibleTypeParam from '../utils/getCollectibleTypeParam';

export type GetYearFilterOptionsParams = GenericRequiredGetFiltersParams &
  Pick<GenericGetFiltersParams, 'playerIds' | 'sportIds'>;

export type YearFilterOption = GenericFilterOption;

interface ApiSetYearFiltersResponse {
  filters: {
    set_years: {
      label: string | number;
      value: string | number;
    }[];
  };
}

export async function getYearFilterOptions(
  { isoEndDate, isoStartDate, sportIds, playerIds, collectibleType }: GetYearFilterOptionsParams,
  signal: AbortSignal | undefined
) {
  const response = await getFilters<ApiSetYearFiltersResponse>(
    {
      searchType: 'SYC',
      isoEndDate,
      isoStartDate,
      collectibleType,
      sportIds,
      playerIds,
    },
    signal
  );
  return (
    response?.filters?.set_years
      ?.map<YearFilterOption>(mapToOption)
      .sort(({ value: year1 }, { value: year2 }) => year2 - year1) ?? []
  );
}

export type GetSetFilterOptionsParams = GenericRequiredGetFiltersParams &
  Pick<GenericGetFiltersParams, 'playerIds' | 'sportIds' | 'years'>;

export interface SetFilterOption extends GenericFilterOption {
  setYear: number;
}

interface ApiSetFiltersResponseSportsCards {
  filters: {
    set_name_years: {
      label: string;
      name: string;
      sport: {
        id: number;
        name: string;
      };
      value: string | number;
      year: string | number;
    }[];
  };
  rookieYears: (string | number)[];
}

interface ApiSetFiltersResponseSealedWaxes {
  filters: {
    set_names: {
      label: string;
      name: string;
      sport: {
        id: number;
        name: string;
      };
      value: string | number;
      year: string | number;
    }[];
  };
}

export async function getSetFilterOptions(
  { isoEndDate, isoStartDate, sportIds, playerIds, years, collectibleType }: GetSetFilterOptionsParams,
  signal: AbortSignal | undefined
) {
  const response = await getFilters<ApiSetFiltersResponseSportsCards | ApiSetFiltersResponseSealedWaxes>(
    {
      searchType: 'PCC',
      collectibleType,
      isoEndDate,
      isoStartDate,
      sportIds,
      playerIds,
      years,
    },
    signal
  );
  const sets = isSportsCardCollectibleType(collectibleType)
    ? (response as ApiSetFiltersResponseSportsCards).filters.set_name_years
    : (response as ApiSetFiltersResponseSealedWaxes).filters.set_names;
  return (
    sets?.map<SetFilterOption>(({ label, value, year }) => ({
      setYear: Number(year),
      label,
      value: Number(value),
    })) ?? []
  );
}

export type GetSetVariationFilterOptionsParams = GenericRequiredGetFiltersParams &
  Pick<GenericGetFiltersParams, 'cardSetIds' | 'playerIds' | 'sportIds'>;

export type SetVariationFilterOption = GenericFilterOption;

interface ApiSetVariationFiltersResponse {
  filters: {
    set_variations: {
      label: string;
      value: string | number;
    }[];
  };
}

export async function getSetVariationFilterOptions(
  { isoEndDate, isoStartDate, cardSetIds, playerIds, sportIds, collectibleType }: GetSetVariationFilterOptionsParams,
  signal?: AbortSignal
) {
  const response = await getFilters<ApiSetVariationFiltersResponse>(
    {
      searchType: 'PCC',
      collectibleType,
      isoStartDate,
      isoEndDate,
      sportIds,
      playerIds,
      cardSetIds,
    },
    signal
  );
  return response?.filters?.set_variations?.map<SetVariationFilterOption>(mapToOption) ?? [];
}

export type GetGradeFilterOptionsParams = GenericRequiredGetFiltersParams &
  Pick<GenericGetFiltersParams, 'cardSetIds' | 'cardSetVariationIds' | 'sportIds' | 'playerIds'> & {
    cardSetVariationIds: number[];
  };

export type GradeFilterOption = GenericFilterOption;

interface ApiGradeFiltersResponse {
  filters: {
    grades: {
      label: string;
      order: number;
      value: string | number;
    }[];
  };
}

export async function getGradeFilterOptions(
  {
    isoEndDate,
    isoStartDate,
    cardSetIds,
    cardSetVariationIds,
    playerIds,
    sportIds,
    collectibleType,
  }: GetGradeFilterOptionsParams,
  signal?: AbortSignal
) {
  const response = await getFilters<ApiGradeFiltersResponse>(
    {
      searchType: 'PCC',
      collectibleType,
      isoEndDate,
      isoStartDate,
      sportIds,
      playerIds,
      cardSetIds,
      cardSetVariationIds,
    },
    signal
  );
  return (
    response?.filters?.grades?.sort(({ order: o1 }, { order: o2 }) => o1 - o2).map<GradeFilterOption>(mapToOption) ?? []
  );
}

export type GetPlayerFilterOptionsParams = GenericRequiredGetFiltersParams &
  Pick<
    GenericGetFiltersParams,
    'cardSetIds' | 'cardSetVariationIds' | 'sportIds' | 'cardGradeIds' | 'cardVariationIds'
  >;

export interface PlayerFilterOption extends GenericFilterOption {
  rookieYears: number[];
}

interface ApiPlayerFiltersResponse {
  filters: {
    players: {
      label: string;
      notes: string[] | null;
      rookie_years: (string | number)[];
      value: number | string;
    }[];
  };
}

export async function getPlayerFilterOptions(
  {
    isoEndDate,
    isoStartDate,
    cardSetIds,
    cardSetVariationIds,
    cardVariationIds,
    cardGradeIds,
    sportIds,
    collectibleType,
  }: GetPlayerFilterOptionsParams,
  signal?: AbortSignal
) {
  const response = await getFilters<ApiPlayerFiltersResponse>(
    {
      searchType: 'PCC',
      collectibleType,
      isoStartDate,
      isoEndDate,
      sportIds,
      cardGradeIds,
      cardSetIds,
      cardSetVariationIds,
      cardVariationIds,
    },
    signal
  );
  return (
    response?.filters?.players?.map<PlayerFilterOption>(({ label, value, rookie_years }) => ({
      label,
      value: Number(value),
      rookieYears: rookie_years?.map((y) => Number(y)) ?? [],
    })) ?? []
  );
}

export type GetCardSetRookieYearsParams = GenericRequiredGetFiltersParams &
  Pick<GenericGetFiltersParams, 'cardSetIds' | 'sportIds' | 'cardSetVariationIds'> & {
    cardSetVariationIds: number[];
  };

interface ApiGradeFiltersResponse {
  rookieYears: (string | number)[];
}

export async function getCardSetRookieYears(
  { isoEndDate, isoStartDate, cardSetIds, cardSetVariationIds, sportIds, collectibleType }: GetCardSetRookieYearsParams,
  signal?: AbortSignal
) {
  const response = await getFilters<ApiGradeFiltersResponse>(
    {
      collectibleType,
      searchType: 'PCC',
      isoEndDate,
      isoStartDate,
      sportIds,
      cardSetIds,
      cardSetVariationIds,
    },
    signal
  );
  return response?.rookieYears?.map((y) => Number(y)) ?? [];
}

export type GetSealedWaxBoxTypeFilterOptionsParams = Omit<GenericRequiredGetFiltersParams, 'collectibleType'> &
  Pick<GenericGetFiltersParams, 'cardSetIds' | 'playerIds' | 'sportIds'>;

export type SealedWaxBoxTypeFilterOption = GenericFilterOption;

interface ApiBoxTypeFiltersResponse {
  filters: {
    box_types: {
      label: string;
      value: string | number;
    }[];
  };
}

export async function getSealedWaxBoxTypeFilterOptions(
  { isoEndDate, isoStartDate, sportIds, cardSetIds, playerIds }: GetSealedWaxBoxTypeFilterOptionsParams,
  signal?: AbortSignal
) {
  const response = await getFilters<ApiBoxTypeFiltersResponse>(
    {
      searchType: 'PCC',
      collectibleType: 'sealed-wax-card',
      isoEndDate,
      isoStartDate,
      sportIds,
      cardSetIds,
      playerIds,
    },
    signal
  );
  return response?.filters?.box_types?.map<SealedWaxBoxTypeFilterOption>(mapToOption) ?? [];
}

interface GenericRequiredGetFiltersParams {
  isoStartDate: string;
  isoEndDate: string;
  collectibleType: CollectibleType;
}

interface GenericGetFiltersParams {
  sportIds?: number[] | null;
  playerIds?: number[] | null;
  years?: number[] | null;
  cardSetIds?: number[] | null;
  cardVariationIds?: number[] | null;
  cardSetVariationIds?: number[] | null;
  cardGradeIds?: number[] | null;
}

interface GenericFilterOption {
  value: number;
  label: string;
}

async function getFilters<TPayload>(
  {
    collectibleType,
    isoEndDate,
    isoStartDate,
    playerIds,
    sportIds,
    cardSetIds,
    years,
    searchType,
    cardVariationIds,
    cardSetVariationIds,
    cardGradeIds,
  }: GenericRequiredGetFiltersParams &
    GenericGetFiltersParams & {
      searchType: 'PCC' | 'SYC';
    },
  signal: AbortSignal | undefined
) {
  const type = getCollectibleTypeParam(collectibleType);
  const setKey = isSportsCardCollectibleType(collectibleType) ? 'setNameYears' : 'sets';
  return await authenticatedFetchFromSciApi<TPayload>(`/${type}/filters`, {
    method: 'POST',
    body: JSON.stringify({
      searchType: isSportsCardCollectibleType(collectibleType) ? searchType : undefined,
      endDate: isoEndDate,
      startDate: isoStartDate,
      sports: sportIds?.map((id) => String(id)) ?? undefined,
      players: playerIds?.map((id) => String(id)) ?? undefined,
      [setKey]: cardSetIds?.map((id) => String(id)) ?? undefined,
      years: years?.map((id) => String(id)) ?? undefined,
      set_variations: cardSetVariationIds?.map((id) => String(id)) ?? undefined,
      variations: cardVariationIds?.map((id) => String(id)) ?? undefined,
      grades: cardGradeIds?.map((id) => String(id)) ?? undefined,
    }),
    signal,
  });
}

function mapToOption({ label, value }: { label: string | number; value: string | number }) {
  return {
    label: String(label),
    value: Number(value),
  };
}
