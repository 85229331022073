import React, { SetStateAction, useMemo, useState, useEffect } from 'react';
import clsx from 'clsx';
import { Dropdown, DropdownProps } from 'antd';
import { faRotateRight } from '@fortawesome/pro-regular-svg-icons';
import { faArrowRotateLeft } from '@fortawesome/pro-light-svg-icons';
import { SportsCardSearch, SealedWaxSearch, SearchItem } from '../../../services/sciApi/search/types';
import SportsSearchItem from '../../../sci-ui-components/Search/items/SearchItem/SportSearchItem';
import SealedWaxSearchItem from '../../../sci-ui-components/Search/items/SearchItem/SealedWaxSearchItem';
import useCollectiblesSearch from '../../collectibles/useCollectiblesSearch';
import Button from '../../../sci-ui-components/buttons/Button/Button';
import useCollectibleType from '../useCollectibleType';
import { useFuzzySearch, UseMultiSearchInputFilters } from '../../quick-search/useMultiSearch';
import classes from './CollectibleSelect.module.scss';
import {
  CollectibleType,
  isSealedWaxCardCollectibleType,
  isSportsCardCollectibleType,
} from 'sci-ui-components/types/collectibleType';
import VerticalTilesList from 'sci-ui-components/VerticalTilesList/VerticalTilesList';
import useDebouncedValue from 'sci-ui-components/hooks/useDebouncedValue';
import TextSearchField from 'sci-ui-components/forms/TextSearchField/TextSearchField';

interface Props {
  className?: string;
  id?: string;
  selectedCollectibleId: number | null;
  onSelect?: (collectibleId: number | null) => void;
  onSelectCollectible?: (collectible: SearchItem) => void;
  noResults?: React.ReactElement;
  noReset?: boolean;
  onCancel?: () => void;
  selectedCollectibleIds?: number[];
  collectibleType: CollectibleType;
  trigger?: DropdownProps['trigger'];
  onVisibleChange?: DropdownProps['onVisibleChange'];
  initialSearchText?: string;
}

export interface CollectibleSelectUIProps extends Omit<Props, 'initialSearchText'> {
  isLoading: boolean;
  searchItems: (SportsCardSearch | SealedWaxSearch)[];
  fetchNextPage?: () => void;
  hasNextPage: boolean;
  searchText: string;
  setSearchText: (val: SetStateAction<string>) => void;
  onTextSearchClick?: () => void;
  visibleByDefault?: boolean;
  collapseOnSelect?: boolean;
}

function CollectibleSelectUI({
  selectedCollectibleId,
  onSelect,
  onSelectCollectible,
  noResults,
  noReset,
  onCancel,
  isLoading,
  fetchNextPage,
  searchItems,
  searchText,
  setSearchText,
  onTextSearchClick,
  className,
  selectedCollectibleIds,
  collectibleType,
  id,
  onVisibleChange,
  trigger = ['click'],
  visibleByDefault = false,
  collapseOnSelect = false,
  hasNextPage,
}: CollectibleSelectUIProps) {
  const showNoResults = !isLoading && searchItems.length === 0 && noResults;
  const [isDropdownVisible, setIsDropdownVisible] = useState(visibleByDefault);

  const handleVisibilityChange = (value: boolean) => {
    setIsDropdownVisible(value);
    onVisibleChange?.(value);
  };

  const overlay = (
    <div className={classes.overlay}>
      {showNoResults ? (
        noResults
      ) : (
        <VerticalTilesList onApproachingEnd={hasNextPage ? fetchNextPage : undefined} isLoading={isLoading}>
          {searchItems.map((searchItem) => {
            return (
              <div key={searchItem.id}>
                <CollectibleSelectItem
                  className={classes.collectibleSelectItem}
                  onSelect={() => {
                    onSelect?.(searchItem.id);
                    onSelectCollectible?.(searchItem);
                    setSearchText('');
                    collapseOnSelect && setIsDropdownVisible(false);
                  }}
                  searchItem={searchItem}
                  isSelected={selectedCollectibleIds?.includes(searchItem.id)}
                  collectibleType={collectibleType}
                />
              </div>
            );
          })}
        </VerticalTilesList>
      )}
    </div>
  );

  return (
    <>
      <Dropdown
        visible={isDropdownVisible}
        onVisibleChange={handleVisibilityChange}
        overlay={overlay}
        trigger={trigger}
        className={clsx(classes.root, className)}
      >
        <div>
          <TextSearchField
            onChange={(v) => setSearchText(v ?? '')}
            value={searchText}
            id={id}
            onClick={onTextSearchClick}
          />
        </div>
      </Dropdown>
      {selectedCollectibleId && !noReset && (
        <Button
          className={classes.resetButton}
          size="small"
          type="text"
          onClick={() => onSelect?.(null)}
          faIconRight={faRotateRight}
          minWidth={100}
        >
          RESET
        </Button>
      )}
      {!!onCancel && (
        <Button
          className={classes.resetButton}
          size="small"
          type="text"
          onClick={onCancel}
          faIcon={faArrowRotateLeft}
          minWidth={100}
        >
          Cancel
        </Button>
      )}
    </>
  );
}

export default function CollectibleSelect({ initialSearchText = '', ...props }: Props) {
  const [searchText, setSearchText] = useState(initialSearchText);
  useEffect(() => {
    setSearchText(initialSearchText);
  }, [initialSearchText]);
  const [debouncedSearchText] = useDebouncedValue(searchText, 200);

  const {
    allItems: data,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useCollectiblesSearch({
    filters: {
      collectibleTypes: [props.collectibleType],
    },
    searchQueryText: debouncedSearchText,
    limit: 10,
    disableSpellCheck: true,
  });

  const searchItems = useMemo(
    () => data?.reduce<(SportsCardSearch | SealedWaxSearch)[]>((acc, item) => acc.concat(item), []) ?? [],
    [data]
  );

  return (
    <CollectibleSelectUI
      searchItems={searchItems}
      searchText={searchText}
      setSearchText={setSearchText}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage ?? false}
      isLoading={isLoading}
      collapseOnSelect
      {...props}
    />
  );
}

interface FuzzyCollectibleSelectProps extends Props {
  filters?: UseMultiSearchInputFilters;
  visibleByDefault?: boolean;
}

export function FuzzyCollectibleSelect({ filters, visibleByDefault = false, ...props }: FuzzyCollectibleSelectProps) {
  const { collectibleType } = useCollectibleType();
  const [searchText, setSearchText] = useState('');
  const [debouncedSearchText] = useDebouncedValue(searchText, 400);
  const [searchEnabled, setSearchEnabled] = useState(visibleByDefault ?? false);

  const { items, fetchNextPageIfAvailable, isLoading, hasNextPage } = useFuzzySearch(
    {
      collectibleType,
      filters: [
        {
          ...filters,
          searchText: debouncedSearchText,
        },
      ],
      limit: 10,
    },
    {
      enabled: searchEnabled,
    }
  );

  return (
    <CollectibleSelectUI
      searchItems={items}
      searchText={searchText}
      setSearchText={setSearchText}
      fetchNextPage={fetchNextPageIfAvailable}
      isLoading={isLoading}
      onTextSearchClick={() => setSearchEnabled(true)}
      visibleByDefault={visibleByDefault}
      hasNextPage={hasNextPage ?? false}
      {...props}
    />
  );
}

interface ItemProps {
  className: string;
  onSelect?: () => void;
  searchItem: SportsCardSearch | SealedWaxSearch;
  isSelected?: boolean;
  collectibleType: CollectibleType;
}

export function CollectibleSelectItem({ className, onSelect, searchItem, isSelected, collectibleType }: ItemProps) {
  if (isSportsCardCollectibleType(collectibleType))
    return (
      <div className={className}>
        <SportsSearchItem
          onClick={onSelect}
          key={searchItem.id}
          {...(searchItem as SportsCardSearch)}
          selected={isSelected}
        />
      </div>
    );

  if (isSealedWaxCardCollectibleType(collectibleType))
    return (
      <div className={className}>
        <SealedWaxSearchItem
          onClick={onSelect}
          key={searchItem.id}
          {...(searchItem as SealedWaxSearch)}
          selected={isSelected}
        />
      </div>
    );

  return null;
}
