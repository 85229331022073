import { collectibleNames } from '../../../../sci-ui-components/collectibles/constants';
import { CollectibleType } from '../../../../sci-ui-components/types/collectibleType';
import FormSection from '../../../../sci-ui-components/forms/FormSection/FormSection';
import CollectionItemPurchaseDetails from '../../CollectionItemPurchaseDetails';
import { PurchaseDetailsFormValues } from './types';
import classes from './PurchaseDetailsSummary.module.scss';

// shouldn't convert prices because inputs will be converted by the provider
export default function PurchaseDetailsSummary({
  collectibleType,
  purchaseDetails,
  isCustom,
}: {
  collectibleType: CollectibleType;
  purchaseDetails: PurchaseDetailsFormValues | null;
  isCustom: boolean;
}) {
  return (
    <FormSection title="Purchase Details">
      {purchaseDetails?.purchaseDate ? (
        <CollectionItemPurchaseDetails
          className={classes.text}
          collectibleType={collectibleType}
          datePurchased={purchaseDetails.purchaseDate}
          quantity={purchaseDetails.purchaseQuantity ?? 1}
          totalPriceOfOwnershipPerItem={
            (purchaseDetails.purchasePricePerItemInDisplayCurrency ?? 0) +
            (purchaseDetails.gradingPricePerItemInDisplayCurrency ?? 0)
          }
          totalPriceOfOwnershipTotal={
            (purchaseDetails.purchasePriceTotalInDisplayCurrency ?? 0) +
            (purchaseDetails.gradingPriceTotalInDisplayCurrency ?? 0)
          }
          doNotConvertToDisplayCurrency
        />
      ) : (
        <p className={classes.text}>
          {isCustom
            ? `Please enter ${collectibleNames[collectibleType].singular} details first`
            : `Please select ${collectibleNames[collectibleType].singular} first`}
        </p>
      )}
    </FormSection>
  );
}
