import classes from './ManualCardPrompt.module.scss';
import useCollectibleType from 'features/collectibles/useCollectibleType';
import Button from 'sci-ui-components/buttons/Button/Button';
import { collectibleNames } from 'features/collectibles/constants';

interface Props {
  onClick: () => void;
}

export function ManualCardPrompt({ onClick }: Props) {
  const { collectibleType } = useCollectibleType();

  return (
    <div className={classes.wrapper}>
      <h2 className={classes.text}>{`Can't find what you're looking for?`}</h2>
      <Button type="primary" size="small" onClick={onClick}>
        {`Manually add a ${collectibleNames[collectibleType].shortSingular}`}
      </Button>
    </div>
  );
}
