import ImageUpload, { ImageUploadProps } from '../ImageUpload/ImageUpload';
import { CollectibleType } from '../../types/collectibleType';
import CollectibleImage from '../../collectibles/CollectibleImage/CollectibleImage';

interface CollectibleImageUploadProps extends Omit<ImageUploadProps, 'ImageComponent'> {
  collectibleType: CollectibleType;
}

export default function CollectibleImageUpload({ collectibleType, ...props }: CollectibleImageUploadProps) {
  return (
    <ImageUpload
      ImageComponent={
        <CollectibleImage
          url={props.imageUrl ?? props.defaultImageUrl}
          collectibleType={collectibleType}
          alt={props.imageUrl ? 'New image' : 'Current image'}
        />
      }
      {...props}
    />
  );
}
