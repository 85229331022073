import InfoBarUi from '../../sci-ui-components/InfoBar/InfoBar';
import useCollectibleType from '../collectibles/useCollectibleType';
import usePlatformNotifications from '../platform/usePlatformNotifications';
import usePlatformStats from '../platform/usePlatformStats';

export default function InfoBar({ className, isPublic }: { className?: string; isPublic?: boolean }) {
  const { collectibleType } = useCollectibleType();
  const { data: notifications } = usePlatformNotifications(isPublic, { enabled: true });
  const { data: stats } = usePlatformStats({
    dateRangeInDays: 30,
  });
  return (
    <InfoBarUi
      className={className}
      collectibleType={collectibleType}
      notifications={notifications ?? []}
      platformStats={stats ?? null}
    />
  );
}
