import ModalDialog from '../../ModalDialog/ModalDialog';
import ImageCropper from '../ImageCropper/ImageCropper';
import { ImageCropperDialogInfoProps } from './types';

export type ImageCropperDialogProps = ImageCropperDialogInfoProps & {
  isOpen: boolean;
  onConfirm: (imageDataUrl: string | null) => void;
  onCancel: () => void;
  onClose: () => void;
};

const formId = 'crop-image-dialog';

export default function ImageCropperDialog({
  isOpen,
  onCancel,
  onClose,
  onConfirm,
  imageDataUrl,
  aspectRatio,
  collectibleType,
  modalWidth = 720,
  autoCropArea,
}: ImageCropperDialogProps) {
  return (
    <ModalDialog
      visible={isOpen}
      okText="Use this"
      cancelText="Cancel"
      onCancel={() => {
        onCancel();
        onClose();
      }}
      variant="secondary"
      okButtonProps={{
        autoFocus: true,
        htmlType: 'submit',
        form: formId,
      }}
      width={modalWidth}
    >
      <ImageCropper
        imageUrl={imageDataUrl}
        collectibleType={collectibleType}
        onSubmit={(imageDataUrl) => {
          onConfirm(imageDataUrl);
          onClose();
        }}
        formId={formId}
        autoCropArea={autoCropArea}
        aspectRatio={aspectRatio}
      />
    </ModalDialog>
  );
}
