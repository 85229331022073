import { ReactNode } from 'react';
import { useFormikContext } from 'formik';
import CollectibleImageUpload from '../../../../../sci-ui-components/forms/CollectibleImageUpload/CollectibleImageUpload';
import { CollectibleType } from '../../../../../sci-ui-components/types/collectibleType';
import FormSection from '../../../../../sci-ui-components/forms/FormSection/FormSection';
import FieldLabelBox from '../../../../../sci-ui-components/forms/FieldLabelBox/FieldLabelBox';
import { showError } from '../../../../../services/toaster';
import { CustomCollectibleFormValues } from './types';

import classes from './CustomCollectiblePhotoSelector.module.scss';
import { cropImage } from 'sci-ui-components/forms/ImageCropperDialog';
import { isDataUrl } from '@/sci-ui-components/utils/image';

export function CustomCollectiblePhotoSelector({
  formId,
  collectibleType,
  currentImageUrl,
  children,
}: {
  formId: string;
  collectibleType: CollectibleType;
  currentImageUrl: string | null;
  children: ReactNode;
}) {
  const { values, handleChange } = useFormikContext<CustomCollectibleFormValues>();
  const customQueryFieldId = `${formId}-photo`;

  return (
    <div className={classes.root}>
      <FormSection noPadding>
        <FieldLabelBox className={classes.imageLabel} label="Image" fieldId={customQueryFieldId}>
          <CollectibleImageUpload
            className={classes.image}
            collectibleType={collectibleType}
            id={customQueryFieldId}
            defaultImageUrl={currentImageUrl}
            imageUrl={values.image ?? null}
            onChange={async (selection) => {
              if (!selection) {
                return;
              }
              const croppedImage = await cropImage({
                collectibleType,
                imageDataUrl: selection.imageDataUrl,
              });
              if (croppedImage) {
                handleChange({ target: { value: croppedImage, name: 'image' } });
              }
            }}
            onReCrop={
              !!currentImageUrl && isDataUrl(currentImageUrl)
                ? async (imageUrl) => {
                    const croppedImage = await cropImage({
                      collectibleType,
                      imageDataUrl: imageUrl,
                    });
                    if (croppedImage) {
                      handleChange({ target: { value: croppedImage, name: 'image' } });
                    }
                  }
                : undefined
            }
            onError={(error) => {
              showError({
                description: error?.message ?? 'Failed to select an image',
              });
            }}
          />
        </FieldLabelBox>
      </FormSection>
      <FormSection className={classes.rightContent} noPadding>
        {children}
      </FormSection>
    </div>
  );
}
