import React from 'react';
import { CollectibleDescriptions, makeCollectibleDescriptionsFromParts } from '@sportscardinvestor/collectible-helpers';
import {
  getPopulationCount,
  getSportsCardGrade,
  isRookieSportsCard,
  makeCollectibleDescription,
} from '../../../../sci-ui-components/utils/collectibleDescription';
import { CollectibleType } from '../../../../sci-ui-components/types/collectibleType';
import { useCommonCollectibleActions } from '../../../collectibles/useCommonCollectiblesActions';
import FormSection from '../../../../sci-ui-components/forms/FormSection/FormSection';
import { useCollectible } from '../../../collectibles/useCollectible';
import { useCollectibleStats } from '../../../stats/useCollectibleStats';
import { useCommonCollectionActions } from '../../useCommonCollectionActions';
import { useCollectionItem } from '../../useListCollectionItems';
import { CollectionItemCollectibleInfo } from '../../CollectionItemCollectibleInfo';
import {
  CustomCollectibleFormValues,
  CustomSealedWaxCollectibleFormValues,
  CustomSportsCardCollectibleFormValues,
} from './custom/types';

import { CustomCollectible } from '@/sci-ui-components/types/collectible';
import useAlternateCardGrades from '@/features/cardGrades/useAlternateCardGrades';

export default function CollectibleSummary({
  className,
  collectibleType,
  collectibleId,
  customCollectibleFormValues,
  customCollectible,
  collectionItemId,
}: {
  className?: string;
  collectibleType: CollectibleType;
  collectibleId: number | null;
  customCollectible: CustomCollectible | null;
  customCollectibleFormValues: CustomCollectibleFormValues | null;
  collectionItemId: number | null;
}) {
  return (
    <FormSection>
      {customCollectibleFormValues ? (
        <CustomCollectibleSummary
          className={className}
          collectibleType={collectibleType}
          customCollectibleFormValues={customCollectibleFormValues}
          customCollectible={customCollectible}
        />
      ) : (
        <NonCustomCollectibleSummary
          className={className}
          collectibleType={collectibleType}
          collectibleId={collectibleId}
          collectionItemId={collectionItemId}
        />
      )}
    </FormSection>
  );
}

function NonCustomCollectibleSummary({
  className,
  collectibleId,
  collectibleType,
  collectionItemId,
}: {
  className?: string;
  collectibleId: number | null;
  collectibleType: CollectibleType;
  collectionItemId: number | null;
}) {
  const { data: collectible = null } = useCollectible(
    {
      collectibleType,
      id: collectibleId!,
    },
    { enabled: !!collectibleId }
  );
  const { data: stats } = useCollectibleStats(
    {
      collectibleId: collectibleId!,
      collectibleType,
    },
    { enabled: !!collectibleId }
  );
  const actions = useCommonCollectibleActions({ collectibleType, collectibleId, query: collectible?.query });
  const { collectionItem } = useCollectionItem({
    collectibleType,
    collectionItemId,
  });
  const collectionActions = useCommonCollectionActions();

  const { title, descriptionLine1, descriptionLine2 } = makeCollectibleDescription(collectible);
  const { data: alternateGrades } = useAlternateCardGrades(
    {
      collectibleId: collectibleId!,
    },
    {
      enabled: !!collectibleId,
    }
  );

  if (!collectibleId) {
    return null;
  }

  return (
    <CollectionItemCollectibleInfo
      className={className}
      collectibleType={collectibleType}
      imageUrl={collectible?.imageUrl}
      descriptionLine1={descriptionLine1}
      descriptionLine2={descriptionLine2}
      title={title}
      gradeName={getSportsCardGrade(collectible)}
      collectibleId={collectibleId}
      currentPrice={stats?.all.endAvg}
      currentPriceDate={stats?.lastSale}
      isRookie={isRookieSportsCard(collectible)}
      isManual={false}
      populationCount={getPopulationCount(collectible)}
      onPopulationCountClick={actions.chartPopulationCount}
      alternateGrades={alternateGrades}
      onGradeChange={
        collectionItem &&
        collectionActions.canConvertCollectionItemGrade({
          collectionItem,
        })
          ? ({ collectibleId }) =>
              collectionActions.showConvertCollectionItemGradeDialog({
                collectionItemId: collectionItem.id,
                collectibleType: collectionItem.collectibleType,
                newGradeCollectibleId: collectibleId,
              })
          : undefined
      }
    />
  );
}

function CustomCollectibleSummary({
  className,
  customCollectibleFormValues,
  collectibleType,
  customCollectible,
}: {
  className?: string;
  collectibleType: CollectibleType;
  customCollectibleFormValues: CustomCollectibleFormValues;
  customCollectible: CustomCollectible | null;
}) {
  const { title, descriptionLine1, descriptionLine2 } = makeCollectibleDescriptionFromFormValues(
    customCollectibleFormValues,
    collectibleType
  );
  return (
    <CollectionItemCollectibleInfo
      className={className}
      collectibleType={collectibleType}
      imageUrl={customCollectibleFormValues?.image}
      descriptionLine1={descriptionLine1}
      descriptionLine2={descriptionLine2}
      title={title}
      gradeName={(customCollectibleFormValues as CustomSportsCardCollectibleFormValues).gradeName}
      collectibleId={customCollectible?.id ?? null}
      currentPrice={customCollectibleFormValues?.currentValueInDisplayCurrency}
      doNotConvertToDisplayCurrency
      currentPriceDate={null}
      isManual
    />
  );
}

function makeCollectibleDescriptionFromFormValues(
  customCollectibleFormValues: CustomCollectibleFormValues,
  collectibleType: CollectibleType
): CollectibleDescriptions {
  switch (collectibleType) {
    case 'sports-card': {
      const customSportsCardFormValues = customCollectibleFormValues as CustomSportsCardCollectibleFormValues;
      return makeCollectibleDescriptionsFromParts({
        collectibleType: 'sports-card',
        playerName: customSportsCardFormValues?.playerName,
        cardNumber: customSportsCardFormValues.cardNumber,
        setYear: customSportsCardFormValues.cardSetYear,
        setName: customSportsCardFormValues.cardSetName,
        variationName: customSportsCardFormValues.variationName,
        specificQualifier: customSportsCardFormValues.specificQualifier,
        printRun: null,
      });
    }
    case 'sealed-wax-card': {
      const customSportsCardFormValues = customCollectibleFormValues as CustomSealedWaxCollectibleFormValues;
      return makeCollectibleDescriptionsFromParts({
        collectibleType: 'sealed-wax-card',
        setName: customSportsCardFormValues.cardSetName,
        setYear: customSportsCardFormValues.cardSetName,
        boxType: customSportsCardFormValues.boxTypeName,
        sportName: customSportsCardFormValues.sportName,
      });
    }
  }
}
