import { useEffect } from 'react';
import type { AppProps } from 'next/app';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { Page } from '../../types';
import CollectibleTypeContextProvider from '../../../collectibles/CollectibleTypeContextProvider';
import ConfirmationDialogProvider from '../../../../sci-ui-components/ConfirmationDialog/ConfirmationDialogProvider';
import ImageCropperDialogProvider from '../../../../sci-ui-components/forms/ImageCropperDialog/ImageCropperDialogProvider';
import AddOrEditFavoriteCategoryDialogProvider from '../../../favorites/AddOrEditFavoriteCategoryDialog/AddOrEditFavoriteCategoryDialogProvider';
import AddOrEditFavoriteDialogProvider from '../../../favorites/AddOrEditFavoriteDialog/AddOrEditFavoriteDialogProvider';
import AddFavoritePlayersCategoryDialogProvider from '../../../favorites/AddFavoritePlayersCategoryDialog/AddFavoritePlayersCategoryDialogProvider';
import AddOrEditFavoritePlayerDialogProvider from '../../../favorites/AddOrEditFavoritePlayerDialog/AddOrEditFavoritePlayerDialogProvider';
import AddOrEditCollectionCategoryDialogProvider from '../../../collection/AddOrEditCollectionCategoryDialog/AddOrEditCollectionCategoryDialogProvider';
import ChartsDialogProvider from '../../../charts/ChartsDialog/ChartsDialogProvider';
import ChartUntrackedCollectiblesDialogProvider from '../../../charts/ChartUntrackedCollectiblesDialog/ChartUntrackedCollectiblesDialogProvider';
import PriceAlertDialogProvider from '../../../alerts/PriceAlertDialog/PriceAlertDialogProvider';
import AddSavedSearchDialogProvider from '../../../search/saved-searches/AddSavedSearchDialog/AddSavedSearchDialogProvider';
import EditSavedSearchCategoryDialogProvider from '../../../search/saved-searches/EditSavedSearchCategoryDialog/EditSavedSearchCategoryDialogProvider';
import EditSavedSearchDialogProvider from '../../../search/saved-searches/EditSavedSearchDialog/EditSavedSearchDialogProvider';
import PopulationCountChartDialogProvider from '../../../charts/PopulationCountChartDialog/PopulationCountChartDialogProvider';
import CollectionItemNoteDialogProvider from '../../../collection/CollectionItemNoteDialog/CollectionItemNoteDialogProvider';
import AddOrEditCollectionItemDialogProvider from '../../../collection/AddOrEditCollectionItemDialog/AddOrEditCollectionItemDialogProvider';
import SignupIncentiveProvider from '../../../signupIncentive/SignupIncentiveProvider';
import AuthenticationGuard from '../../../../guards/AuthenticationGuard';
import PermissionGuard from '../../../../guards/PermissionGuard';
import YoutubeIntroDialogProvider from '../../../youtube-intro/YoutubeIntroDialogProvider';
import SaleDetailsDialogProvider from '../../../sales-history/SaleDetailsDialog/SaleDetailsDialogProvider';
import UserPreferencesModalProvider from '../../../user/UserPreferencesModal/UserPreferencesModalProvider';
import PageViewAnalytics from '../../../analytics/PageViewAnalytics';
import ClientSideOnly from '../../ClientSideOnly';
import DefaultLayout from './DefaultLayout/DefaultLayout';
import publicConfig from 'publicConfig';
import useUser from 'features/user/useUser';
import useUserSettings from 'features/settings/useUserSettings';
import { TylerModeContextProvider } from 'features/0401/useTylerMode';
import MarkAsSoldDialogProvider from '@/features/collection/MarkAsSoldDialog/MarkAsSoldDialogProvider';
import CollectibleDetailsFlyoutContainer from '@/features/collectibles/CollectibleDetailsFlyout/CollectibleDetailsFlyoutContainer';
import { ConvertCollectionItemGradeDialog } from '@/features/collection/ConvertCollectionItemGradeDialog/ConvertCollectionItemGradeDialog';
import { EditCollectionItemPurchaseDetailsDialog } from '@/features/collection/EditCollectionItemPurchaseDetailsDialog/EditCollectionItemPurchaseDetailsDialog';
import { EditCustomCollectionItemDialog } from '@/features/collection/EditCustomCollectionItemDialog/EditCustomCollectionItemDialog';
import EditManualCollectibleValueDialog from '@/features/collection/EditManualCollectibleValueDialog/EditManualCollectibleValueDialog';

type AppPropsWithLayout = AppProps & {
  Component: Page;
};

function MembersApp({ Component, pageProps }: AppPropsWithLayout) {
  const ldClient = useLDClient();
  const { data: user } = useUser();
  // to make user settings ready for other components
  useUserSettings();

  useEffect(() => {
    (async () => {
      await ldClient?.waitForInitialization();
      if (user?.email && ldClient?.getContext()?.email !== user?.email) {
        ldClient?.identify({
          kind: 'user',
          key: user.id, //without key it throws an error
          email: user.email,
        });
      }
    })();
  }, [ldClient, user]);

  return (
    <ClientSideOnly>
      <AuthenticationGuard skeleton={Component.skeleton} disabled={Component.isPublic}>
        <PageViewAnalytics />
        <PermissionGuard>
          <CollectibleTypeContextProvider>
            <TylerModeContextProvider>
              <DefaultLayout isPublic={Component.isPublic}>
                <Component {...pageProps} />
                <CollectibleDetailsFlyoutContainer />
                <MarkAsSoldDialogProvider id="mark-as-sold" />
                <AddOrEditFavoriteDialogProvider id="add-edit-favorite" />
                <AddOrEditFavoriteCategoryDialogProvider id="add-favorite-category" />
                <AddFavoritePlayersCategoryDialogProvider id="add-favorite-player-category" />
                <AddOrEditFavoritePlayerDialogProvider id="add-edit-favorite-player" />
                <AddOrEditCollectionCategoryDialogProvider id="add-collection-category" />
                <EditManualCollectibleValueDialog />
                <PriceAlertDialogProvider id="price-alert" />
                <ChartsDialogProvider id="chart" />
                <ChartUntrackedCollectiblesDialogProvider id="untacked-chart" />
                <PopulationCountChartDialogProvider id="pop-count" />
                <AddSavedSearchDialogProvider id="saved-search-add" />
                <EditSavedSearchCategoryDialogProvider id="saved-search-cat-edit" />
                <EditSavedSearchDialogProvider id="saved-search-edit" />
                <ConfirmationDialogProvider id="confirmation" />
                <CollectionItemNoteDialogProvider id="collection-item-notes" />
                <YoutubeIntroDialogProvider id="youtube-intro" />
                <SignupIncentiveProvider id="signup-incentive" />
                <AddOrEditCollectionItemDialogProvider id="add-edit-collection-item" />
                <ImageCropperDialogProvider id="image-cropper" />
                <SaleDetailsDialogProvider id="sale-details" />
                <UserPreferencesModalProvider id="user-preferences" />
                <EditCustomCollectionItemDialog />
                <EditCollectionItemPurchaseDetailsDialog />
                <ConvertCollectionItemGradeDialog />
              </DefaultLayout>
            </TylerModeContextProvider>
          </CollectibleTypeContextProvider>
        </PermissionGuard>
      </AuthenticationGuard>
    </ClientSideOnly>
  );
}

export default publicConfig.NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_ID
  ? withLDProvider<AppPropsWithLayout & JSX.IntrinsicAttributes>({
      clientSideID: publicConfig.NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_ID,
    })(MembersApp)
  : MembersApp;
