import { useMemo } from 'react';
import NextLink from 'next/link';
import clsx from 'clsx';
import { Button } from '@sportscardinvestor/sci-ui/src/components/ui/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretLeft } from '@fortawesome/pro-solid-svg-icons';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@sportscardinvestor/sci-ui/src/components/ui/collapsible';
import usePaginationState, { PaginationStateConfig } from '../../../hooks/usePaginationState';
import { sortByStringField } from '../../../utils/sort';
import { useShallowFlyoutStore } from './store';
import classes from './CollectiblesCompletedSales.module.scss';
import Pagination from '@/sci-ui-components/Pagination/Pagination';
import { formatDate } from '@/sci-ui-components/utils/date';
import useFormatStatValue from '@/hooks/useFormatStatValue';
import { useCollapsibleStateFromStorage } from '@/features/collectibles/CollectibleDetailsFlyout/helpers';
import VerticalTilesList from '@/sci-ui-components/VerticalTilesList/VerticalTilesList';
import useDateRangeState from '@/hooks/useDateRangeState';
import useChartData from '@/features/charts/useChartData';
import { generateCollectiblesChartHref } from '@/features/search/utils/collectibleChart';
import useAnalytics from '@/features/analytics/useAnalytics';
import { showSignupIncentiveModal } from '@/features/signupIncentive';
import useAuth from '@/features/auth/useAuth';

const paginationConfig: PaginationStateConfig = { limitOptions: [3], defaultLimit: 3 };

export interface CompletedSalesDaySummary {
  date: string;
  numberOfSales: number;
  average: number;
}

export default function CollectiblesCompletedSales() {
  const { collectibleId, collectibleType, onClose } = useShallowFlyoutStore(
    'collectibleId',
    'collectibleType',
    'onClose'
  );
  const { isLoggedIn } = useAuth();
  const isPublic = !isLoggedIn;

  const [open, setOpen] = useCollapsibleStateFromStorage('Sold');
  const [allDateRange] = useDateRangeState('All Data');
  const { trackEvent } = useAnalytics();

  const { data: chartData, isLoading } = useChartData(
    {
      collectibleIds: collectibleId ? [collectibleId] : [],
      advancedFilters: {},
      collectibleType,
      isPublic,
      dateRange: allDateRange,
    },
    {
      enabled: !!collectibleId,
    }
  );

  const viewAllHref = `${generateCollectiblesChartHref({
    collectibleIds: collectibleId ? [collectibleId] : [],
    collectibleType: collectibleType,
  })}#past_sales`;

  const { limit, setPage, page, offset } = usePaginationState(paginationConfig);

  const completedSalesSummary = useMemo<CompletedSalesDaySummary[]>(() => {
    const salesByDate =
      chartData?.chartData?.reduce<CompletedSalesDaySummary[]>((acc, { date, metrics }) => {
        if (metrics?.salesVolume) {
          acc.push({
            date,
            numberOfSales: metrics?.salesVolume ?? 0,
            average: metrics?.avgSalePrice ?? 0,
          });
        }
        return acc;
      }, []) ?? [];
    return sortByStringField(salesByDate, (item) => item.date, 'desc');
  }, [chartData]);

  const completedSalesSummaryPageData = useMemo(
    () => completedSalesSummary.slice(offset, offset + limit),
    [completedSalesSummary, limit, offset]
  );

  const { formatStatValue } = useFormatStatValue();

  const hasListingResults = useMemo(() => {
    if (completedSalesSummaryPageData !== null && completedSalesSummaryPageData.length > 0) {
      return true;
    }
    return false;
  }, [completedSalesSummaryPageData]);

  return (
    <Collapsible open={open} onOpenChange={setOpen}>
      <div className="flex flex-col">
        <CollapsibleTrigger asChild>
          <Button
            variant="ghost"
            size="lg"
            className="pl-0 pr-0 mb-4 -mt-1 font-black uppercase text-[24px] h-[32px] hover:bg-transparent"
          >
            <div className="flex flex-row gap-4 mr-auto items-center">
              <span>Completed Sales</span>
              {hasListingResults && !!viewAllHref && !!collectibleId && (
                <NextLink href={viewAllHref} legacyBehavior>
                  <a
                    className="font-bold text-lg mr-auto uppercase text-primary"
                    color="primary"
                    onClick={(e) => {
                      if (isPublic) {
                        showSignupIncentiveModal();
                        e.preventDefault();
                      } else {
                        onClose();
                        trackEvent({
                          eventName: 'SALES_NAVIGATED_TO_VIEW_ALL_COMPLETED_SALES',
                          collectibleId,
                          collectibleType: collectibleType,
                        });
                      }
                    }}
                  >
                    View All
                  </a>
                </NextLink>
              )}
            </div>
            <FontAwesomeIcon icon={open ? faCaretDown : faCaretLeft} />
          </Button>
        </CollapsibleTrigger>
        <CollapsibleContent>
          <header className="flex mb-3 items-center gap-2 text-gray-muted text-sm">
            {hasListingResults && (
              <div className="flex justify-between w-full">
                <span className="text-center basis-1/2">Date</span>
                <span className="text-end basis-1/2">Number of Sales</span>
                <span className="text-end basis-1/2">Avg $</span>
              </div>
            )}
          </header>
          <VerticalTilesList isLoading={isLoading} emptyMessage="No Listings Found" listClassName={classes.list}>
            {completedSalesSummaryPageData?.map(({ date, numberOfSales, average }) => (
              <div className={clsx(classes.row, classes.item)} key={date}>
                <span className={classes.date}>{formatDate(date)}</span>
                <span className={clsx(classes.amount, classes.numberOfSales)}>
                  {formatStatValue({
                    value: numberOfSales,
                    type: 'count',
                    defaultValue: 'N/A',
                  })}
                </span>
                <span className={classes.amount}>
                  {formatStatValue({
                    value: average,
                    type: 'price',
                    defaultValue: 'N/A',
                  })}
                </span>
              </div>
            )) ?? []}
          </VerticalTilesList>
          {completedSalesSummary.length && completedSalesSummary.length > limit ? (
            <Pagination
              className="mt-4"
              onPageChange={setPage}
              totalItems={completedSalesSummary.length}
              page={page}
              limit={limit}
              noPageJumps
              justify="right"
            />
          ) : null}
        </CollapsibleContent>
      </div>
    </Collapsible>
  );
}
