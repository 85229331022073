import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FieldLabelBox from '../../../../sci-ui-components/forms/FieldLabelBox/FieldLabelBox';
import FormFieldsRow from '../../../../sci-ui-components/forms/FormFieldsRow/FormFieldsRow';
import QuantityField from '../../../../sci-ui-components/forms/QuantityField/QuantityField';
import DateSelector from '../../../../sci-ui-components/forms/DateSelector/DateSelector';
import PriceField from '../../../../sci-ui-components/forms/PriceField/PriceField';
import useToday from '../../../../sci-ui-components/hooks/useToday';
import { formatISODate } from '../../../../sci-ui-components/utils/date';
import { CollectionItem } from '../../../../sci-ui-components/types/collectionItem';
import FormSection from '../../../../sci-ui-components/forms/FormSection/FormSection';
import { CollectibleType } from '../../../../sci-ui-components/types/collectibleType';
import useCollectionItemMutations from '../../useCollectionItemMutations';
import FormButtons from '../FormButtons';
import { SaleDetailsFormValues } from './types';
import useCurrencyFormatter, { ConvertPriceFn } from '@/hooks/useCurrencyFormatter';

export default function SaleDetailsForm({
  initialSaleDetails,
  onSave,
  onCancel,
  dialogId,
  collectionItemId,
  ownedQuantity,
  collectibleType,
  disableQuantity,
}: {
  initialSaleDetails: SaleDetailsFormValues | null;
  onSave: (values: SaleDetailsFormValues) => void;
  onCancel: () => void;
  dialogId: string;
  collectionItemId: number | null;
  ownedQuantity: number | null;
  collectibleType: CollectibleType;
  disableQuantity?: boolean;
}) {
  const today = useToday();
  const { convertPriceToUSD } = useCurrencyFormatter();
  const { updateCollectionItem } = useCollectionItemMutations();

  const formId = `${dialogId}-sale`;
  const saleDateFieldId = `${formId}-saleDate`;
  const saleQuantityFieldId = `${formId}-saleQuantity`;
  const salePricePerItemFieldId = `${formId}-salePricePerItem`;
  const salePriceTotalFieldId = `${formId}-salePriceTotal`;
  const feesPerItemFieldId = `${formId}-feesPerItem`;
  const feesTotalFieldId = `${formId}-feesTotal`;

  const handleSubmit = async (values: SaleDetailsFormValues) => {
    if (collectionItemId) {
      await updateCollectionItem.mutateAsync({
        saleDetails: {
          collectionItemId,
          quantity: values.saleQuantity!,
          saleDate: values.saleDate!,
          salePricePerItem: convertPriceToUSD({ value: values.salePricePerItemInDisplayCurrency }) ?? 0,
          feesPerItem: convertPriceToUSD({ value: values.feesPerItemInDisplayCurrency }) ?? 0,
        },
        collectibleType,
      });
    }
    onSave(values);
  };

  const isEdit = !!collectionItemId;

  return (
    <Formik<SaleDetailsFormValues>
      initialValues={
        initialSaleDetails ?? {
          saleDate: formatISODate(today),
          saleQuantity: 1,
          salePricePerItemInDisplayCurrency: null,
          salePriceTotalInDisplayCurrency: null,
          feesPerItemInDisplayCurrency: null,
          feesTotalInDisplayCurrency: null,
        }
      }
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ values, handleChange, errors }) => (
        <Form id={formId}>
          <FormSection title="Sale Details">
            <FormFieldsRow>
              <FieldLabelBox label="Sale Date" fieldId={saleDateFieldId} error={errors.saleDate}>
                <DateSelector
                  id={saleDateFieldId}
                  name="saleDate"
                  value={values.saleDate ?? ''}
                  onChange={(value, name) => handleChange({ target: { value, name } })}
                />
              </FieldLabelBox>
              <FieldLabelBox label="Quantity" fieldId={saleQuantityFieldId} error={errors.saleQuantity}>
                <QuantityField
                  id={saleQuantityFieldId}
                  name="saleQuantity"
                  value={values.saleQuantity}
                  disabled={ownedQuantity === 1 || disableQuantity}
                  min={1}
                  max={ownedQuantity ?? undefined}
                  onChange={(value, name) => {
                    const quantity = value ?? 0;
                    handleChange({ target: { name, value } });
                    handleChange({
                      target: {
                        name: 'salePriceTotalInDisplayCurrency',
                        value: values?.salePricePerItemInDisplayCurrency
                          ? values?.salePricePerItemInDisplayCurrency * quantity
                          : 0,
                      },
                    });
                    handleChange({
                      target: {
                        name: 'feesTotalInDisplayCurrency',
                        value: values?.feesPerItemInDisplayCurrency
                          ? values?.feesPerItemInDisplayCurrency * quantity
                          : 0,
                      },
                    });
                  }}
                />
              </FieldLabelBox>
            </FormFieldsRow>
            <FormFieldsRow>
              <FieldLabelBox
                label="Sale Price Per Item"
                fieldId={salePricePerItemFieldId}
                error={errors.salePricePerItemInDisplayCurrency}
              >
                <PriceField
                  id={salePricePerItemFieldId}
                  name="salePricePerItemInDisplayCurrency"
                  value={values.salePricePerItemInDisplayCurrency ?? 0}
                  min={0}
                  onChange={(value, name) => {
                    const quantity = values.saleQuantity ?? 0;
                    handleChange({
                      target: { name: 'salePriceTotalInDisplayCurrency', value: value ? value * quantity : 0 },
                    });
                    handleChange({ target: { name, value } });
                  }}
                />
              </FieldLabelBox>
              <FieldLabelBox
                label={`Sale Price Total`}
                fieldId={salePriceTotalFieldId}
                error={errors.salePriceTotalInDisplayCurrency}
              >
                <PriceField
                  value={values.salePriceTotalInDisplayCurrency ?? 0}
                  name="salePriceTotalInDisplayCurrency"
                  onChange={(value, name) => handleChange({ target: { value, name } })}
                  min={0}
                  id={salePriceTotalFieldId}
                />
              </FieldLabelBox>
            </FormFieldsRow>
            <FormFieldsRow>
              <FieldLabelBox
                label="Fees Per Item"
                fieldId={feesPerItemFieldId}
                error={errors.feesPerItemInDisplayCurrency}
              >
                <PriceField
                  onChange={(value, name) => {
                    const quantity = values.saleQuantity ?? 0;
                    handleChange({ target: { name, value } });
                    handleChange({
                      target: { name: 'feesTotalInDisplayCurrency', value: value ? value * quantity : 0 },
                    });
                  }}
                  value={values.feesPerItemInDisplayCurrency ?? 0}
                  min={0}
                  id={feesPerItemFieldId}
                  name="feesPerItemInDisplayCurrency"
                />
              </FieldLabelBox>
              <FieldLabelBox label={`Fees Total`} fieldId={feesTotalFieldId} error={errors.feesTotalInDisplayCurrency}>
                <PriceField
                  value={values.feesTotalInDisplayCurrency ?? 0}
                  name="feesTotalInDisplayCurrency"
                  onChange={(value, name) => handleChange({ target: { value, name } })}
                  min={0}
                  id={feesTotalFieldId}
                />
              </FieldLabelBox>
            </FormFieldsRow>
          </FormSection>
          <FormButtons isEdit={isEdit} isLoading={updateCollectionItem.isLoading} onCancel={onCancel} />
        </Form>
      )}
    </Formik>
  );
}

const validationSchema: Yup.SchemaOf<SaleDetailsFormValues> = Yup.object().shape({
  saleDate: Yup.string().required('Required'),
  saleQuantity: Yup.number().required('Required').min(0, 'Must be a positive number'),
  salePricePerItemInDisplayCurrency: Yup.number().required('Required').min(0, 'Must be a positive number'),
  salePriceTotalInDisplayCurrency: Yup.number().required('Required').min(0, 'Must be a positive number'),
  feesPerItemInDisplayCurrency: Yup.number().required('Required').min(0, 'Must be a positive number'),
  feesTotalInDisplayCurrency: Yup.number().required('Required').min(0, 'Must be a positive number'),
});

export function getSaleDetailsInitialValuesFromCollectionItem({
  collectionItem,
  convertPriceFromUSD,
}: {
  collectionItem: CollectionItem | null;
  convertPriceFromUSD: ConvertPriceFn;
}): SaleDetailsFormValues | null {
  if (!collectionItem) {
    return null;
  }
  const saleQuantity = collectionItem.quantity;
  const feesPerItemInDisplayCurrency = convertPriceFromUSD({ value: collectionItem.soldFeesPerItem }) ?? null;
  const salePricePerItemInDisplayCurrency = convertPriceFromUSD({ value: collectionItem.soldPricePerItem }) ?? null;
  return {
    feesPerItemInDisplayCurrency,
    feesTotalInDisplayCurrency:
      feesPerItemInDisplayCurrency !== null ? feesPerItemInDisplayCurrency * saleQuantity : null,
    saleDate: collectionItem.dateSold,
    saleQuantity,
    salePricePerItemInDisplayCurrency,
    salePriceTotalInDisplayCurrency:
      salePricePerItemInDisplayCurrency !== null ? salePricePerItemInDisplayCurrency * saleQuantity : null,
  };
}
