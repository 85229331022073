import * as Yup from 'yup';
import { CustomCardCollectible } from '../../../../../sci-ui-components/types/collectible';
import {
  customCollectibleQueryValidationSchema,
  getCustomCollectibleQueryInitialValues,
} from './CustomCollectibleQueryFields';
import { CommonCustomCollectibleFormValues } from './types';
import { ConvertPriceFn } from '@/hooks/useCurrencyFormatter';

export function getTerms(
  terms: (string | undefined)[] | null | undefined,
  term: string | undefined | null // NOTE: if user types a value in terms field, but forgets to add it, include it anyway
): string[] | undefined {
  const cleanTerms = terms?.map((v) => v?.trim()).filter((v): v is string => !!v) ?? [];
  const cleanTerm = term?.trim();
  if (cleanTerm) {
    cleanTerms.push(cleanTerm);
  }
  return cleanTerms?.length ? cleanTerms : undefined;
}

export function getCommonCustomCollectibleInitialValuesFromCollectible({
  collectible,
  convertPriceFromUSD,
}: {
  collectible: CustomCardCollectible | null;
  convertPriceFromUSD: ConvertPriceFn;
}): CommonCustomCollectibleFormValues {
  if (!collectible) {
    return {
      image: null,
      currentValueInDisplayCurrency: null,
      ...getCustomCollectibleQueryInitialValues(null),
    };
  }
  return {
    currentValueInDisplayCurrency: convertPriceFromUSD({ value: collectible?.currentPrice }),
    image: collectible.imageUrl,
    ...getCustomCollectibleQueryInitialValues(collectible),
  };
}

export const commonValidationSchema: Yup.SchemaOf<CommonCustomCollectibleFormValues> = Yup.object()
  .shape({
    image: Yup.string().nullable(),
    currentValueInDisplayCurrency: Yup.number().nullable().min(0, 'Must be a positive number'),
  })
  .concat(customCollectibleQueryValidationSchema);
