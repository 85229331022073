import { useMemo } from 'react';
import { z } from 'zod';
import clsx from 'clsx';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { getCollectionCollectible } from '@sportscardinvestor/collection-helpers';
import { getIsRawGrade } from '@sportscardinvestor/collectible-helpers';
import { CollectibleType, CollectionItem } from '@sportscardinvestor/schemas';
import CollectionItemCollectible from '../CollectionItemCollectible';
import { useConvertCollectionItemGradeDialogStore } from './store';
import { useOnValueChange } from '@/hooks/useOnValueChange';
import FormSection from '@/sci-ui-components/forms/FormSection/FormSection';
import FormFieldsRow from '@/sci-ui-components/forms/FormFieldsRow/FormFieldsRow';
import NumberField from '@/sci-ui-components/forms/NumberField/NumberField';
import FieldLabelBox from '@/sci-ui-components/forms/FieldLabelBox/FieldLabelBox';
import { collectibleNames } from '@/sci-ui-components/collectibles/constants';
import PriceField from '@/sci-ui-components/forms/PriceField/PriceField';
import useAlternateCardGrades from '@/features/cardGrades/useAlternateCardGrades';
import SingleSelect, { Option } from '@/sci-ui-components/forms/SingleSelect/SingleSelect';
import { EditForm, EditFormButtons } from '@/sci-ui-components/forms/EditForm';

const formSchema = z.object({
  newGradeCollectibleId: z.number({
    required_error: 'Please select a Grade',
  }),
  quantity: z
    .number({
      required_error: 'Please enter quantity',
    })
    .min(1, 'Must convert at least 1'),
  gradingPricePerCardInDisplayCurrency: z.number().default(0),
});

type FormSchema = z.input<typeof formSchema>;
type FormPayload = z.output<typeof formSchema>;

export function ConvertCollectionItemGradeForm({
  onSubmit,
  onCancel,
  formId,
  collectionItem,
  className,
  isLoading,
}: {
  onSubmit: (payload: FormPayload, collectionItemId: number) => void;
  onCancel: () => void;
  formId: string;
  collectionItem: CollectionItem;
  className?: string;
  isLoading?: boolean;
}) {
  const { initialNewGradeCollectibleId } = useConvertCollectionItemGradeDialogStore();
  const collectible = getCollectionCollectible(collectionItem);
  const { data: alternateGrades } = useAlternateCardGrades({
    collectibleId: Number(collectible.id),
  });
  const gradeOptions = useMemo(
    () =>
      alternateGrades.reduce<Option<number>[]>((acc, { collectibleId, gradeName }) => {
        if (!getIsRawGrade(gradeName)) {
          acc.push({
            label: gradeName,
            value: collectibleId,
          });
        }
        return acc;
      }, []),
    [alternateGrades]
  );

  const form = useForm<FormSchema, unknown, FormPayload>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      newGradeCollectibleId: initialNewGradeCollectibleId ?? undefined,
      quantity: 1,
      gradingPricePerCardInDisplayCurrency: 0,
    },
    mode: 'onSubmit',
  });
  useOnValueChange(collectionItem, () =>
    form.reset({
      newGradeCollectibleId: initialNewGradeCollectibleId ?? undefined,
      quantity: 1,
      gradingPricePerCardInDisplayCurrency: 0,
    })
  );

  const handleSubmit = form.handleSubmit((payload: FormPayload) => onSubmit(payload, collectionItem.id));

  return (
    <EditForm onSubmit={handleSubmit} id={formId} className={clsx('flex flex-col gap-6', className)}>
      <CollectionItemCollectible collectible={collectible} />
      <FormSection noPadding>
        <FormFieldsRow>
          <Controller
            name="newGradeCollectibleId"
            control={form.control}
            render={({ field, fieldState }) => (
              <FieldLabelBox
                label="Convert to Grade:"
                fieldId="newGradeCollectibleId"
                error={fieldState.error?.message}
                variant="neater"
              >
                <SingleSelect
                  {...field}
                  options={gradeOptions}
                  onChange={field.onChange}
                  allowClear={false}
                  id="newGradeCollectibleId"
                />
              </FieldLabelBox>
            )}
          />
          <Controller
            name="quantity"
            control={form.control}
            render={({ field, fieldState }) => (
              <FieldLabelBox label="Quantity:" fieldId="quantity" error={fieldState.error?.message} variant="neater">
                <NumberField {...field} id="quantity" max={collectionItem.quantity} min={1} />
              </FieldLabelBox>
            )}
          />
        </FormFieldsRow>
        <FormFieldsRow>
          <Controller
            name="gradingPricePerCardInDisplayCurrency"
            control={form.control}
            render={({ field, fieldState }) => (
              <FieldLabelBox
                label={`Grading Cost per ${
                  collectibleNames[collectible.collectibleType as CollectibleType].shortSingular
                }:`}
                fieldId="gradingPricePerCardInDisplayCurrency"
                error={fieldState.error?.message}
                variant="neater"
              >
                <PriceField {...field} id="gradingPricePerCardInDisplayCurrency" />
              </FieldLabelBox>
            )}
          />

          <EditFormButtons onCancel={onCancel} isLoading={isLoading} />
        </FormFieldsRow>
      </FormSection>
    </EditForm>
  );
}
