import { CardSetVariationExtended } from '../../../../sci-ui-components/types/cardSetVariationExtended';
import cardSetFromApi from '../../collectibles/transformers/cardSetFromApi';
import cardVariationFromApi from '../../collectibles/transformers/cardVariationFromApi';
import { ApiCardSetVariationExtended } from '../../types';

export default function cardSetVariationExtendedFromApi(
  apiItem: ApiCardSetVariationExtended
): CardSetVariationExtended {
  const variation = cardVariationFromApi(apiItem.variation);
  return {
    setVariationId: apiItem.id,
    printRun: apiItem.print_run,
    query: apiItem.query !== 'FOR WAX USE ONLY' ? apiItem.query : apiItem.variation.name,
    cardSetId: apiItem.set_id,
    variationId: apiItem.variation_id,
    cardSet: cardSetFromApi(apiItem.card_set),
    variation: variation,
    displayText: `${variation?.name}${apiItem.print_run ? ` ${apiItem.print_run}` : ''}`,
  };
}
