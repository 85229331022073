import FormFieldsRow from '../../../sci-ui-components/forms/FormFieldsRow/FormFieldsRow';
import Button from '../../../sci-ui-components/buttons/Button/Button';
import FormSection from '../../../sci-ui-components/forms/FormSection/FormSection';

export default function FormButtons({
  onCancel,
  isEdit,
  isLoading,
  isFirstStep,
}: {
  onCancel: () => void;
  isEdit: boolean;
  isLoading: boolean;
  isFirstStep?: boolean;
}) {
  return (
    <FormSection>
      <FormFieldsRow>
        <Button disabled={isLoading} htmlType="button" type="default" size="middle" onClick={onCancel}>
          {isEdit || isFirstStep ? 'Cancel' : 'Go Back'}
        </Button>
        <Button disabled={isLoading} htmlType="submit" type="primary" size="middle">
          {isEdit ? 'Save' : 'Next'}
        </Button>
      </FormFieldsRow>
    </FormSection>
  );
}
