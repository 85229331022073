import { collectibleNames } from '../../../../sci-ui-components/collectibles/constants';
import { CollectibleType } from '../../../../sci-ui-components/types/collectibleType';
import FormSection from '../../../../sci-ui-components/forms/FormSection/FormSection';
import { formatDate } from '../../../../sci-ui-components/utils/date';
import { SaleDetailsFormValues } from './types';
import classes from './SaleDetailsSummary.module.scss';
import useFormatStatValue from 'hooks/useFormatStatValue';

// shouldn't convert prices because inputs will be converted by the provider
export default function SaleDetailsSummary({
  collectibleType,
  saleDetails,
}: {
  collectibleType: CollectibleType;
  saleDetails: SaleDetailsFormValues | null;
}) {
  const { formatStatValue } = useFormatStatValue();
  return (
    <FormSection title="Sale Details">
      {saleDetails ? (
        <>
          <p className={classes.text}>
            {'Total sale price: '}
            <strong>
              {`${formatStatValue({
                type: 'price',
                value: saleDetails.salePriceTotalInDisplayCurrency,
                doNotConvertToDisplayCurrency: true,
              })}`}
            </strong>
            {!!saleDetails.feesTotalInDisplayCurrency && (
              <>
                {', Total sale fees: '}
                <strong>
                  {`${formatStatValue({
                    type: 'price',
                    value: saleDetails.feesTotalInDisplayCurrency,
                    doNotConvertToDisplayCurrency: true,
                  })}`}
                </strong>
              </>
            )}
            <br />
            <strong>
              {formatStatValue({
                value: saleDetails.saleQuantity,
                type: 'count',
              })}
            </strong>
            {` ${
              collectibleNames[collectibleType][saleDetails.saleQuantity === 1 ? 'shortSingular' : 'shortPlural']
            } for `}
            <strong>
              {formatStatValue({
                type: 'price',
                value: saleDetails.salePricePerItemInDisplayCurrency,
                doNotConvertToDisplayCurrency: true,
              })}
            </strong>
            {saleDetails.saleDate ? (
              <>
                {' on '}
                <strong>{formatDate(saleDetails.saleDate)}</strong>
              </>
            ) : null}
          </p>
        </>
      ) : (
        <p className={classes.text}>{`${collectibleNames[collectibleType].singular} has not been sold yet`}</p>
      )}
    </FormSection>
  );
}
