import { useEffect, useRef } from 'react';

export default function useClickedOutside<TElementType extends HTMLElement = HTMLDivElement>(
  enabled: boolean,
  onOutsideClick: () => void
) {
  const ref = useRef<TElementType | null>(null);
  const cbRef = useRef<() => void>(onOutsideClick);

  useEffect(() => {
    cbRef.current = onOutsideClick;
  }, [onOutsideClick]);

  useEffect(() => {
    if (enabled) {
      const handler = (event: MouseEvent) => {
        if (cbRef.current && !ref.current?.contains(event.target as Node)) {
          cbRef.current();
        }
      };
      document.addEventListener('mousedown', handler);
      return () => document.removeEventListener('mousedown', handler);
    }
  }, [enabled]);

  return ref;
}
