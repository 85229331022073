import { useQuery, UseQueryOptions } from 'react-query';
import { useRouter } from 'next/router';
import {
  getPlatformNotifications,
  GetPlatformNotificationsParams,
} from '../../services/sciApi/platformNotifications/index';
import { ApiError } from '../../utils/api';
import useUser from '../user/useUser';
import { PlatformNotification } from 'sci-ui-components/types/platform';

export const keyPrefix = 'platform-notifications';
type Key = [typeof keyPrefix, GetPlatformNotificationsParams];

export default function usePlatformNotifications(
  isPublic?: boolean,
  options: UseQueryOptions<PlatformNotification[], ApiError, PlatformNotification[], Key> = {}
) {
  const { pathname } = useRouter();
  const user = useUser();
  const queryResult = useQuery(
    [
      keyPrefix,
      {
        url: pathname,
      },
    ],
    async ({ signal }) =>
      getPlatformNotifications(
        {
          url: pathname,
          isPublic: isPublic && !user.data,
        },
        signal
      ),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes
      ...options,
      enabled: options?.enabled ?? true,
      keepPreviousData: true,
    }
  );
  return queryResult;
}
