import { Collectible, CustomCollectible } from '@sportscardinvestor/schemas';
import {
  getCollectibleGradeName,
  isCustomCollectible,
  makeCollectibleDescriptions,
  getCollectibleCurrentPrice,
  getCollectibleImageUrl,
  isRookieSportsCard,
  getCollectibleType,
  getCollectibleId,
  getCollectiblePopulationCount,
} from '@sportscardinvestor/collectible-helpers';
import useAlternateCardGrades from '../cardGrades/useAlternateCardGrades';
import { useCommonCollectiblesActions } from '../collectibles/useCommonCollectiblesActions';
import { CollectionItemCollectibleInfo, CollectionItemCollectibleInfoProps } from './CollectionItemCollectibleInfo';

export default function CollectionItemCollectible({
  className,
  collectible,
  onGradeChange,
}: {
  className?: string;
  collectible: Collectible | CustomCollectible;
} & Pick<CollectionItemCollectibleInfoProps, 'onGradeChange'>) {
  const collectibleId = getCollectibleId(collectible);
  const { data: alternateGrades } = useAlternateCardGrades(
    {
      collectibleId,
    },
    {
      enabled: !isCustomCollectible(collectible) && !!onGradeChange,
    }
  );
  const actions = useCommonCollectiblesActions();
  const { descriptionLine1, descriptionLine2, title } = makeCollectibleDescriptions(collectible);
  const { currentPrice, currentPriceDate } = getCollectibleCurrentPrice(collectible);
  return (
    <CollectionItemCollectibleInfo
      className={className}
      collectibleType={getCollectibleType(collectible)}
      imageUrl={getCollectibleImageUrl(collectible)}
      descriptionLine1={descriptionLine1}
      descriptionLine2={descriptionLine2}
      title={title}
      gradeName={getCollectibleGradeName(collectible)}
      collectibleId={collectibleId}
      currentPrice={currentPrice}
      currentPriceDate={currentPriceDate}
      isManual={isCustomCollectible(collectible)}
      alternateGrades={alternateGrades}
      onGradeChange={onGradeChange}
      isRookie={isRookieSportsCard(collectible)}
      onPopulationCountClick={() => actions.chartPopulationCount(collectibleId)}
      populationCount={getCollectiblePopulationCount(collectible)}
    />
  );
}
