import { useCallback, useMemo } from 'react';
import QueryString from 'qs';
import useQueryString, { getStringParam, getNumericParam, getStringArrayParam } from '../../../hooks/useQueryString';
import useAnalytics from '../../analytics/useAnalytics';
import useCollectibleType from '../../collectibles/useCollectibleType';
import { UntrackedCollectibleSalesSearchFilters } from './types';

export default function useUntrackedCollectibleSalesSearchFilters() {
  const { query, setQueryParams } = useQueryString();
  const { collectibleType } = useCollectibleType();
  const { trackEvent } = useAnalytics();

  const filters = useMemo<UntrackedCollectibleSalesSearchFilters>(() => getFiltersFromQs(query), [query]);

  const setFilters = useCallback(
    (filters: UntrackedCollectibleSalesSearchFilters) => {
      setQueryParams(getQsFromFilters(filters));
      trackEvent({
        eventName: 'SEARCH_UNTRACKED_FILTERS_CHANGED',
        filters,
        collectibleType,
      });
    },
    [setQueryParams, trackEvent, collectibleType]
  );

  const resetFilters = useCallback(() => {
    setQueryParams(getQsFromFilters({}));
    trackEvent({
      eventName: 'SEARCH_UNTRACKED_FILTERS_RESET',
      collectibleType,
    });
  }, [setQueryParams, trackEvent, collectibleType]);
  return {
    filters,
    setFilters,
    resetFilters,
  };
}

const qsKeys: Record<keyof UntrackedCollectibleSalesSearchFilters, string> = {
  singleFieldQuery: 'single_field_query',
  sportName: 'sport',
  playerName: 'player',
  year: 'year',
  cardSetName: 'set',
  cardSetVariationName: 'variation',
  sealedWaxBoxTypeName: 'box_type',
  sportsCardGradeName: 'grade',
  termsToExclude: '-terms',
  termsToInclude: 'terms',
};

export function getQsFromFilters({
  singleFieldQuery,
  sportName,
  playerName,
  year,
  cardSetName,
  cardSetVariationName,
  sealedWaxBoxTypeName,
  sportsCardGradeName,
  termsToExclude,
  termsToInclude,
}: UntrackedCollectibleSalesSearchFilters) {
  return {
    [qsKeys.singleFieldQuery]: singleFieldQuery,
    [qsKeys.sportName]: sportName,
    [qsKeys.playerName]: playerName,
    [qsKeys.year]: year,
    [qsKeys.cardSetName]: cardSetName,
    [qsKeys.cardSetVariationName]: cardSetVariationName,
    [qsKeys.termsToExclude]: termsToExclude,
    [qsKeys.termsToInclude]: termsToInclude,
    [qsKeys.sealedWaxBoxTypeName]: sealedWaxBoxTypeName,
    [qsKeys.sportsCardGradeName]: sportsCardGradeName,
  };
}

function getFiltersFromQs(query: QueryString.ParsedQs): UntrackedCollectibleSalesSearchFilters {
  return {
    singleFieldQuery: getStringParam(query, qsKeys.singleFieldQuery, undefined),
    sportName: getStringParam(query, qsKeys.sportName, undefined),
    playerName: getStringParam(query, qsKeys.playerName, undefined),
    year: getNumericParam(query, qsKeys.year, undefined),
    cardSetName: getStringParam(query, qsKeys.cardSetName, undefined),
    cardSetVariationName: getStringParam(query, qsKeys.cardSetVariationName, undefined),
    sealedWaxBoxTypeName: getStringParam(query, qsKeys.sealedWaxBoxTypeName, undefined),
    sportsCardGradeName: getStringParam(query, qsKeys.sportsCardGradeName, undefined),
    termsToExclude: getStringArrayParam(query, qsKeys.termsToExclude, []),
    termsToInclude: getStringArrayParam(query, qsKeys.termsToInclude, []),
  };
}
