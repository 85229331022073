import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { CollectibleType } from '../collectibles/constants';
import {
  CustomCollectibleFormValues,
  CustomSportsCardCollectibleFormValues,
  CustomSealedWaxCollectibleFormValues,
} from './AddOrEditCollectionItemDialog/collectible/custom/types';
import { mmApiClient } from '@/services/mmApiX';
import useCurrencyFormatter from '@/hooks/useCurrencyFormatter';

export type ExtractCustomCollectionItemDataFromExternalDataFn = (
  params: ExternalDataForExtractCustomCollectionItem
) => Promise<CustomCollectibleFormValues>;
export interface ExternalDataForExtractCustomCollectionItem {
  text: string;
  imageUrl: string | null;
  collectibleType: CollectibleType;
  currentPrice: number | null;
  query: string | null;
}

export function useCustomCollectionItemDataFromExternalData() {
  const { convertPriceFromUSD } = useCurrencyFormatter();
  const { mutateAsync: getImageDataUrl, isLoading: isLoadingImage } = useMutation({
    mutationFn: mmApiClient.private.images.getBase64ImageDataUrl.query,
  });
  const { mutateAsync: extractStructuredDataFromText, isLoading: isLoadingExtractedData } = useMutation({
    mutationFn: mmApiClient.private.recognition.getStructuredDataFromText.query,
  });
  const extractCustomCollectionItemDataFromExternalData =
    useCallback<ExtractCustomCollectionItemDataFromExternalDataFn>(
      async ({ collectibleType, imageUrl, text, currentPrice, query }) => {
        const [extractedData, { base64ImageDataURL }] = await Promise.all([
          extractStructuredDataFromText({
            collectibleTypes: [collectibleType],
            text,
          }),
          imageUrl ? getImageDataUrl({ imageURL: imageUrl }) : { base64ImageDataURL: null },
        ]);
        const addYearAndSportToSetName = (setName: string | null) =>
          [
            extractedData.setYear && !setName?.includes(String(extractedData.setYear)) ? extractedData.setYear : null,
            setName,
            extractedData.sport?.text,
          ]
            .filter((v) => v)
            .join(' ');
        if (collectibleType === 'sealed-wax-card') {
          const waxResult: CustomSealedWaxCollectibleFormValues = {
            boxTypeId: extractedData.boxType?.id?.toString(),
            boxTypeName: extractedData.boxType?.text ?? null,
            sportId: extractedData.sport?.id,
            sportName: extractedData.sport?.text,
            cardSetId: extractedData.waxSet?.id,
            cardSetName: extractedData.waxSet?.text ?? extractedData.maybe.setName ?? null,
            cardSetYear: extractedData.setYear?.toString(),
            currentValueInDisplayCurrency: convertPriceFromUSD({ value: currentPrice }),
            customQuery: query?.trim() || null,
            image: base64ImageDataURL,
          };
          waxResult.cardSetName = addYearAndSportToSetName(waxResult.cardSetName);
          return waxResult;
        }
        const cardResult: CustomSportsCardCollectibleFormValues = {
          gradeId: extractedData.grade?.id,
          gradeName: extractedData.grade?.text ?? null,
          playerId: extractedData.player?.id,
          playerName: extractedData.player?.text ?? extractedData.maybe?.playerName,
          sportId: extractedData.sport?.id,
          sportName: extractedData.sport?.text,
          cardSetId: extractedData.set?.id,
          cardSetName: extractedData.set?.text ?? extractedData.maybe?.setName ?? null,
          cardSetYear: extractedData.setYear?.toString(),
          variationName: extractedData.setVariations?.length === 1 ? extractedData.setVariation?.text : null,
          cardNumber: extractedData.cardNumber,
          currentValueInDisplayCurrency: convertPriceFromUSD({ value: currentPrice }),
          customQuery: query?.trim() || null,
          image: base64ImageDataURL,
        };
        cardResult.cardSetName = addYearAndSportToSetName(cardResult.cardSetName);
        return cardResult;
      },
      [getImageDataUrl, extractStructuredDataFromText, convertPriceFromUSD]
    );

  return {
    extractCustomCollectionItemDataFromExternalData,
    isLoading: isLoadingExtractedData || isLoadingImage,
  };
}
