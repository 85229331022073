import { CollectibleType } from '@sportscardinvestor/schemas';
import { getIsRawGrade } from '@sportscardinvestor/collectible-helpers';
import { CustomCollectibleFormValues, CustomSportsCardCollectibleFormValues } from './custom/types';
import { useCollectible } from '@/features/collectibles/useCollectible';
import { NonCustomSportsCardCollectible } from '@/sci-ui-components/types/collectible';

export function useIsGradedCollectible({
  nonCustomCollectibleId,
  collectibleType,
  customCollectibleFormValues,
}: {
  nonCustomCollectibleId: number | null;
  collectibleType: CollectibleType;
  customCollectibleFormValues: CustomCollectibleFormValues | null;
}) {
  const { data: collectible = null } = useCollectible(
    {
      collectibleType,
      id: nonCustomCollectibleId!,
    },
    { enabled: !!nonCustomCollectibleId }
  );

  if (collectibleType === 'sealed-wax-card') {
    return false;
  }
  if (customCollectibleFormValues) {
    return !getIsRawGrade((customCollectibleFormValues as CustomSportsCardCollectibleFormValues).gradeName ?? '');
  }
  return !!collectible && !getIsRawGrade((collectible as NonCustomSportsCardCollectible).grade?.name ?? '');
}
