import { collectibleNames } from '../../../../../sci-ui-components/collectibles/constants';
import FieldLabelBox from '../../../../../sci-ui-components/forms/FieldLabelBox/FieldLabelBox';
import { CollectibleType } from '../../../../../sci-ui-components/types/collectibleType';
import { ManualCardPrompt } from '../../../../collection/ManualCardPrompt/ManualCardPrompt';
import CollectibleSelect from '../../../../collectibles/CollectibleSelect/CollectibleSelect';
import { OnSwitchToManualFn } from '../../types';
import FormSection from 'sci-ui-components/forms/FormSection/FormSection';
import AddNewAdornmentButton from 'sci-ui-components/buttons/AddNewAdornmentButton/AddNewAdornmentButton';

export default function NonCustomCollectibleForm({
  collectibleType,
  initialCollectibleId,
  onSubmit,
  onCancel,
  dialogId,
  onSwitchToManual,
}: {
  collectibleType: CollectibleType;
  initialCollectibleId: number | null;
  onSubmit: (collectibleId: number) => void;
  onCancel: () => void;
  dialogId: string;
  onSwitchToManual: OnSwitchToManualFn;
}) {
  const formId = `${dialogId}-collectible`;
  const collectibleFieldId = `${formId}-id`;

  return (
    <FormSection>
      <FieldLabelBox
        label={`Choose ${collectibleNames[collectibleType]?.singular}`}
        fieldId={collectibleFieldId}
        adornmentRight={<AddNewAdornmentButton onClick={onSwitchToManual}>Add Manual</AddNewAdornmentButton>}
      >
        <CollectibleSelect
          selectedCollectibleId={initialCollectibleId ?? null}
          onSelect={(collectibleId) => {
            onSubmit(collectibleId!); // NOTE: reset is disabled -> never null
          }}
          id={collectibleFieldId}
          noResults={<ManualCardPrompt onClick={onSwitchToManual} />}
          onCancel={initialCollectibleId ? onCancel : undefined}
          collectibleType={collectibleType}
          noReset
        />
      </FieldLabelBox>
    </FormSection>
  );
}
