import { useEffect } from 'react';
import ModalDialog from '../../../sci-ui-components/ModalDialog/ModalDialog';
import useCollectionItemMutations from '../useCollectionItemMutations';
import { useCollectionItem } from '../useListCollectionItems';
import { useConvertCollectionItemGradeDialogStore } from './store';
import { ConvertCollectionItemGradeForm } from './ConvertCollectionItemGradeForm';
import useAnalytics from '@/features/analytics/useAnalytics';
import useCurrencyFormatter from '@/hooks/useCurrencyFormatter';
import { withErrorBoundary } from '@/sci-ui-components/ErrorBoundary/ErrorBoundary';

const formId = 'ConvertCollectionItemGradeForm';

export const ConvertCollectionItemGradeDialog = withErrorBoundary(function ConvertCollectionItemGradeDialog() {
  const { collectionItemId, collectibleType, close, onSuccess } = useConvertCollectionItemGradeDialogStore();
  const { collectionItem } = useCollectionItem({
    collectibleType,
    collectionItemId,
  });
  const { convertCollectionItemGrade } = useCollectionItemMutations();
  const { convertPriceToUSD } = useCurrencyFormatter();
  const { trackEvent } = useAnalytics();
  useEffect(() => {
    if (collectionItem) {
      trackEvent({
        eventName: 'CONVERT_GRADE_STARTED',
        collectionItemId: collectionItem.id,
      });
    }
  }, [trackEvent, collectionItem]);

  return (
    <ModalDialog
      open={!!collectionItem}
      title="Convert Grade"
      onCancel={close}
      footer={null}
      variant="primary"
      width={568}
      withHeaderUnderline
    >
      {!!collectionItem && (
        <ConvertCollectionItemGradeForm
          formId={formId}
          collectionItem={collectionItem}
          onSubmit={({ gradingPricePerCardInDisplayCurrency, newGradeCollectibleId, quantity }, collectionItemId) =>
            convertCollectionItemGrade.mutate(
              {
                cards: {
                  [newGradeCollectibleId]: quantity,
                },
                collectionItemId,
                gradingPricePerCard: convertPriceToUSD({ value: gradingPricePerCardInDisplayCurrency }) ?? 0,
              },
              {
                onSuccess: () => {
                  close();
                  onSuccess?.({
                    collectibleType,
                    newGradeCollectibleId,
                  });
                },
              }
            )
          }
          onCancel={close}
          isLoading={convertCollectionItemGrade.isLoading}
        />
      )}
    </ModalDialog>
  );
});
