import { useCallback } from 'react';
import { useEffect, useState } from 'react';
import { withErrorBoundary } from '../../../sci-ui-components/ErrorBoundary/ErrorBoundary';
import AddOrEditCollectionItemDialog from './AddOrEditCollectionItemDialog';
import { getCustomCollectibleInitialValues } from './collectible/custom/CustomCollectibleForm';
import { getInitialPurchaseDetailValues } from './purchaseDetails/PurchaseDetailsForm';
import { OnSwitchToManualFn } from './types';
import { registerDialog, AddOrEditCollectionItemDialogProviderProps } from './index';
import useCurrencyFormatter from '@/hooks/useCurrencyFormatter';

type State = Partial<AddOrEditCollectionItemDialogProviderProps> & {
  isOpen: boolean;
};

export default withErrorBoundary(function AddOrEditCollectionItemDialogProvider({ id }: { id: string }) {
  const { convertPriceFromUSD } = useCurrencyFormatter();
  const [
    {
      isOpen,
      resolve,
      collectibleType = 'sports-card',
      itemToEdit,
      initialCollectibleId,
      initialCustomCollectibleValues,
      initialCustomCollectible,
      initialPurchaseDetails,
      openInCollectibleEditMode,
      initialNote,
      initialCategoryId,
    },
    setState,
  ] = useState<State>({
    isOpen: false,
  });

  useEffect(
    () =>
      registerDialog(id, (props) => {
        setState({
          itemToEdit: null,
          initialCollectibleId: null,
          initialCustomCollectibleValues: null,
          initialCustomCollectible: null,
          initialPurchaseDetails: null,
          isOpen: true,
          openInCollectibleEditMode: false,
          ...props,
        });
      }),
    [id]
  );

  const setAddNewCustomCollectible = useCallback<OnSwitchToManualFn>(() => {
    setState((s) => ({
      ...s,
      initialCollectibleId: null,
      initialCustomCollectible: null,
      initialCustomCollectibleValues:
        initialCustomCollectibleValues ??
        getCustomCollectibleInitialValues({ collectible: null, collectibleType, convertPriceFromUSD }),
      initialPurchaseDetails: getInitialPurchaseDetailValues(initialPurchaseDetails || null),
    }));
  }, [collectibleType, initialPurchaseDetails, initialCustomCollectibleValues, convertPriceFromUSD]);

  return (
    <AddOrEditCollectionItemDialog
      isOpen={isOpen}
      collectibleType={collectibleType}
      onClose={() =>
        setState((s) => ({
          ...s,
          itemToEdit: null,
          initialCollectibleId: null,
          initialCustomCollectibleValues: null,
          initialCustomCollectible: null,
          initialPurchaseDetails: null,
          isOpen: false,
        }))
      }
      onCreated={() => resolve?.()}
      onEdited={() => resolve?.()}
      itemToEdit={itemToEdit}
      initialCustomCollectibleValues={initialCollectibleId ? null : initialCustomCollectibleValues}
      initialCustomCollectible={initialCustomCollectible}
      initialCollectibleId={initialCollectibleId}
      onSwitchToManual={setAddNewCustomCollectible}
      initialPurchaseDetails={initialPurchaseDetails}
      openInCollectibleEditMode={openInCollectibleEditMode}
      initialNote={initialNote}
      initialCategoryId={initialCategoryId}
    />
  );
});
