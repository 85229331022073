import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import FieldLabelBox from 'sci-ui-components/forms/FieldLabelBox/FieldLabelBox';
import ModalDialog from 'sci-ui-components/ModalDialog/ModalDialog';
import PriceField from 'sci-ui-components/forms/PriceField/PriceField';
import FormFieldsRow from 'sci-ui-components/forms/FormFieldsRow/FormFieldsRow';
import FormSection from 'sci-ui-components/forms/FormSection/FormSection';
import { MarkAsSoldDialogInfoProps } from 'features/collection/MarkAsSoldDialog/types';
import { collectibleNames } from 'sci-ui-components/collectibles/constants';
import DateSelector from 'sci-ui-components/forms/DateSelector/DateSelector';
import NumberField from 'sci-ui-components/forms/NumberField/NumberField';
import { SellOrUpdateSoldCollectionItemResponse } from 'services/sciApi/collections';
import useCollectionItemMutations from 'features/collection/useCollectionItemMutations';
import useCollectionCategories from 'features/collection/useCollectionCategories';
import CollectionCategoryFilter from 'features/collection/CollectionCategoryFilter';

const formId = 'mark-as-sold-dialog';
const saleDateFieldId = `${formId}-saleDate`;
const saleQuantityFieldId = `${formId}-saleQuantity`;
const salePricePerItemFieldId = `${formId}-salePricePerItem`;
const salePriceTotalFieldId = `${formId}-salePriceTotal`;
const feesPerItemFieldId = `${formId}-feesPerItem`;
const feesTotalFieldId = `${formId}-feesTotal`;
const categoryIdFieldId = `${formId}-categoryId`;

export type MarkAsSoldFormValues = {
  saleDate: string;
  saleQuantity: number;
  salePricePerItem: number;
  salePriceTotal: number;
  feesPerItem?: number | null;
  feesTotal?: number | null;
  categoryId?: number | null;
};

const validationSchema: Yup.SchemaOf<MarkAsSoldFormValues> = Yup.object().shape({
  saleDate: Yup.string().required('Required'),
  saleQuantity: Yup.number().nullable().required('Required').min(1, 'Must be a positive number'),
  salePricePerItem: Yup.number().nullable().required('Required').min(0, 'Must be a positive number'),
  salePriceTotal: Yup.number().nullable().required('Required').min(0, 'Must be a positive number'),
  feesPerItem: Yup.number().nullable().min(0, 'Must be a positive number'),
  feesTotal: Yup.number().nullable().min(0, 'Must be a positive number'),
  categoryId: Yup.number().optional().nullable(),
});

interface Props extends MarkAsSoldDialogInfoProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit?: (response: SellOrUpdateSoldCollectionItemResponse | unknown) => void;
}

export default function MarkAsSoldDialog({
  isOpen,
  onClose,
  onSubmit,
  initialValues,
  collectibleType = 'sports-card',
}: Props) {
  const { markCollectionItemAsSold } = useCollectionItemMutations();
  const { data: categories } = useCollectionCategories({
    collectibleType,
  });

  const handleSubmit = async (
    { saleDate, saleQuantity, salePricePerItem, feesPerItem, categoryId }: MarkAsSoldFormValues,
    { setSubmitting, resetForm }: FormikHelpers<MarkAsSoldFormValues>
  ) => {
    const response = await markCollectionItemAsSold.mutateAsync({
      collectionItemId: initialValues.id,
      saleDate: saleDate!,
      salePricePerItem: salePricePerItem!,
      quantity: saleQuantity!,
      feesPerItem: feesPerItem ?? 0,
      category: categoryId ? categories?.find((category) => category.id === categoryId)! : undefined,
    });

    onSubmit?.(response);
    onClose();
    setSubmitting(false);
    resetForm();
  };

  return (
    <Formik<MarkAsSoldFormValues>
      initialValues={{
        saleQuantity: initialValues?.quantity ?? 1,
        saleDate: new Date().toISOString(),
        salePricePerItem: 0,
        salePriceTotal: 0,
        feesPerItem: 0,
        feesTotal: 0,
        categoryId: initialValues?.collectionCategories?.[0]?.id,
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnBlur={false}
    >
      {({ values, handleChange, errors, isSubmitting, resetForm }) => (
        <>
          <ModalDialog
            visible={isOpen}
            title={`Mark ${collectibleNames[collectibleType]?.shortSingular} as Sold`}
            okText={'Ok'}
            onCancel={() => {
              resetForm();
              onClose();
            }}
            cancelText="Cancel"
            okButtonProps={{
              htmlType: 'submit',
              form: formId,
              disabled: isSubmitting,
            }}
            cancelButtonProps={{
              disabled: isSubmitting,
            }}
          >
            <Form id={formId}>
              <FormSection>
                <FieldLabelBox label="Sale Date" fieldId={saleDateFieldId} error={errors.saleDate}>
                  <DateSelector
                    id={saleDateFieldId}
                    name="saleDate"
                    value={values.saleDate ?? ''}
                    onChange={(value, name) => handleChange({ target: { value, name } })}
                  />
                </FieldLabelBox>
                <FieldLabelBox label="Quantity" fieldId={saleQuantityFieldId} error={errors.saleQuantity}>
                  <NumberField
                    id={saleQuantityFieldId}
                    name="saleQuantity"
                    value={values.saleQuantity}
                    disabled={initialValues?.quantity === 1}
                    min={1}
                    max={initialValues?.quantity}
                    onChange={(value, name) => {
                      const quantity = value;
                      if (quantity) {
                        handleChange({ target: { name, value } });
                        handleChange({
                          target: {
                            name: 'salePriceTotal',
                            value: values?.salePricePerItem ? values?.salePricePerItem * quantity : 0,
                          },
                        });
                        handleChange({
                          target: {
                            name: 'feesTotal',
                            value: values?.feesPerItem ? values?.feesPerItem * quantity : 0,
                          },
                        });
                      }
                    }}
                  />
                </FieldLabelBox>
                <FormFieldsRow>
                  <FieldLabelBox
                    label="Sale Price Per Item"
                    fieldId={salePricePerItemFieldId}
                    error={errors.salePricePerItem}
                  >
                    <PriceField
                      id={salePricePerItemFieldId}
                      name="salePricePerItem"
                      value={values.salePricePerItem}
                      min={0}
                      onChange={(value, name) => {
                        const quantity = values.saleQuantity ?? 1;
                        handleChange({ target: { name: 'salePriceTotal', value: value ? value * quantity : 0 } });
                        handleChange({ target: { name, value } });
                      }}
                    />
                  </FieldLabelBox>
                  <FieldLabelBox
                    label={`Sale Price Total`}
                    fieldId={salePriceTotalFieldId}
                    error={errors.salePriceTotal}
                  >
                    <PriceField
                      value={values.salePriceTotal}
                      name="salePriceTotal"
                      onChange={(value, name) => {
                        const quantity = values.saleQuantity ?? 1;
                        handleChange({
                          target: { name: 'salePricePerItem', value: value && quantity ? value / quantity : 0 },
                        });
                        handleChange({ target: { value, name } });
                      }}
                      min={0}
                      id={salePriceTotalFieldId}
                    />
                  </FieldLabelBox>
                </FormFieldsRow>
                <FormFieldsRow>
                  <FieldLabelBox label="Fees Per Item" fieldId={feesPerItemFieldId} error={errors.feesPerItem}>
                    <PriceField
                      onChange={(value, name) => {
                        const quantity = values.saleQuantity ?? 1;
                        handleChange({ target: { name, value } });
                        handleChange({ target: { name: 'feesTotal', value: value ? value * quantity : 0 } });
                      }}
                      value={values.feesPerItem ?? 0}
                      min={0}
                      id={feesPerItemFieldId}
                      name="feesPerItem"
                    />
                  </FieldLabelBox>
                  <FieldLabelBox label={`Fees Total`} fieldId={feesTotalFieldId} error={errors.feesTotal}>
                    <PriceField
                      value={values.feesTotal ?? 0}
                      name="feesTotal"
                      onChange={(value, name) => handleChange({ target: { value, name } })}
                      min={0}
                      id={feesTotalFieldId}
                    />
                  </FieldLabelBox>
                </FormFieldsRow>
              </FormSection>
              <FormSection title="Optional Fields">
                <CollectionCategoryFilter
                  label="Category"
                  id={categoryIdFieldId}
                  error={errors.categoryId}
                  collectibleType={collectibleType}
                  onChange={(categoryId) => handleChange({ target: { name: 'categoryId', value: categoryId } })}
                  selectedCategoryId={values.categoryId ?? null}
                  noValueLabel="None"
                />
              </FormSection>
            </Form>
          </ModalDialog>
        </>
      )}
    </Formik>
  );
}
