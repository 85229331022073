import React from 'react';
import { RawCompletedSaleSearchItem } from '../../sales/raw/useRawCompletedSalesSearch';
import Table, { Column } from '../../../sci-ui-components/Table/Table';
import DefaultTitle from '../../../sci-ui-components/Table/presets/DefaultTitle';
import TextCell from '../../../sci-ui-components/Table/presets/TextCell';
import { formatDate } from '../../../sci-ui-components/utils/date';
import createStatColumn from '../../../sci-ui-components/Table/presets/StatColumn';
import MarketplaceLogo from '../../../sci-ui-components/sales/MarketplaceLogo/MarketplaceLogo';
import getSellerInfo from '../../../services/sciApi/sales/transformers/getSellerInfo';
import { listingTypeNames } from '../../admin/sales/completed/constants';
import Button from '../../../sci-ui-components/buttons/Button/Button';
import CollectibleImageModal from '../../../sci-ui-components/collectibles/CollectibleImageModal/CollectibleImageModal';
import { openSaleDetails } from '../../sales-history/SaleDetailsDialog';
import useFormatStatValue from '@/hooks/useFormatStatValue';

type Params = {
  items: RawCompletedSaleSearchItem[];
  isLoading: boolean;
  handleSaveValue: (params: { salePrice: number }) => void;
  searchQuery?: string;
  className?: string;
};

const RawSalesMinimalistList = ({ items, isLoading, handleSaveValue, searchQuery, className }: Params) => {
  const { formatStatValue } = useFormatStatValue();
  const columns: Column<RawCompletedSaleSearchItem, string, 'finalPrice' | 'saleDate'>[] = [
    {
      key: 'collectible-image',
      width: '80px',
      renderTitle: (props) => (
        <DefaultTitle {...props} justify="center">
          Image
        </DefaultTitle>
      ),
      onClick: () => {}, // this is here to suppress onRowClick for this cell
      renderData: (item) => {
        return (
          <TextCell justify={'center'}>
            <CollectibleImageModal
              url={item?.imageUrls[0]}
              alt={item?.displayTitle}
              size="medium"
              collectibleType={'sports-card'}
            />
          </TextCell>
        );
      },
    },
    {
      key: 'end-time',
      width: '120px',
      sortKey: 'saleDate',
      renderTitle: (props) => (
        <DefaultTitle {...props} justify="center">
          Date
        </DefaultTitle>
      ),
      renderData: (item) => {
        return <TextCell justify={'center'}>{item.endTime ? formatDate(item.endTime) : '-'}</TextCell>;
      },
    },
    {
      key: 'title',
      renderTitle: (props) => (
        <DefaultTitle {...props} justify="center">
          Title
        </DefaultTitle>
      ),
      renderData: (item) => <TextCell justify={'center'}>{item?.displayTitle}</TextCell>,
    },
    createStatColumn<RawCompletedSaleSearchItem, string, 'finalPrice' | 'saleDate'>({
      getValue: (item) => item?.offerPrice || item?.sellingPrice,
      getSubText: ({ offerPrice }) => (offerPrice ? 'Best Offer Accepted' : null),
      key: 'price',
      title: 'Price',
      type: 'price',
      width: '90px',
      formatStatValue,
    }),
    {
      key: 'platform',
      width: '90px',
      renderTitle: (props) => (
        <DefaultTitle {...props} justify="center">
          Platform
        </DefaultTitle>
      ),
      renderData: (item) => (
        <TextCell justify={'center'}>
          <MarketplaceLogo
            isKnownSeller={false}
            sellerName={null}
            marketplace={getSellerInfo(item.platformId, item.seller.name).marketplace}
            logoUrl={getSellerInfo(item.platformId, item.seller.name).logoUrl}
          />
        </TextCell>
      ),
    },
    {
      key: 'listing-type',
      width: '80px',
      renderTitle: (props) => (
        <DefaultTitle {...props} justify="center">
          Sale Type
        </DefaultTitle>
      ),
      renderData: (item) => (
        <TextCell justify={'center'}>{item?.listingType ? listingTypeNames[item?.listingType] : null}</TextCell>
      ),
    },
    {
      key: 'use_price',
      renderData: ({ finalPrice }) => {
        return (
          <TextCell justify="center">
            <Button
              type={'primary'}
              onClick={(ev) => {
                ev.stopPropagation();
                handleSaveValue({ salePrice: finalPrice });
              }}
              size={'small'}
              minWidth={100}
            >
              USE PRICE
            </Button>
          </TextCell>
        );
      },
      width: 'minmax(120px, auto)',
    },
  ];

  return (
    <Table<RawCompletedSaleSearchItem, RawCompletedSaleSearchItem, string, 'saleDate' | 'finalPrice'>
      className={className}
      items={items || []}
      getRowKey={({ _id }) => _id}
      sortedBy={'saleDate'}
      sortDirection={'desc'}
      stickyHeader
      isLoading={isLoading}
      emptyMessage="No results found for specified search criteria. Try adjusting your filters for better results."
      onRowClick={(item) => openSaleDetails({ sale: item, query: searchQuery || '' })}
      columns={columns}
    />
  );
};

export default RawSalesMinimalistList;
