import { CollectibleType } from '../../../sci-ui-components/types/collectibleType';
import FormSection from '../../../sci-ui-components/forms/FormSection/FormSection';
import CollectionCategoryFilter from '../CollectionCategoryFilter';

export default function CategoryForm({
  categoryId,
  onSubmit,
  dialogId,
  collectibleType,
}: {
  categoryId: number | null;
  onSubmit: (categoryId: number | null) => void;
  dialogId: string;
  collectibleType: CollectibleType;
}) {
  const categoryFieldId = `${dialogId}-category`;
  return (
    <FormSection>
      <CollectionCategoryFilter
        label="Category"
        id={categoryFieldId}
        collectibleType={collectibleType}
        onChange={onSubmit}
        selectedCategoryId={categoryId}
        noValueLabel="None"
        autoSelectAdded
      />
    </FormSection>
  );
}

export function isCategoryUpdated(initial: number | null, updated: number | null) {
  return initial !== updated;
}
