import { useMemo } from 'react';
import { RawCompletedSaleSearchItem } from './useRawCompletedSalesSearch';
import { formatDateInTimeZone } from '@/sci-ui-components/utils/date';

export function useLastDayAverageSalePrice({ items }: { items: RawCompletedSaleSearchItem[] }) {
  return useMemo(() => {
    let lastDayTimestamp: string | null = null;
    const lastDaySales = [...(items || [])]
      .sort((a, b) => (a.saleDate < b.saleDate ? 1 : -1))
      .filter(({ saleDate }) => {
        if (!lastDayTimestamp) {
          lastDayTimestamp = formatDateInTimeZone(saleDate);
        }
        return formatDateInTimeZone(saleDate) === lastDayTimestamp;
      });

    return lastDaySales.reduce((acc, { finalPrice }) => (acc += finalPrice), 0) / lastDaySales.length;
  }, [items]);
}
