import { formatter, formatterInteger, parser, parserInteger } from '../utils/numberFieldHelpes';
import NumberField, { NumberFieldProps } from '../NumberField/NumberField';

export type QuantityFieldProps = Omit<NumberFieldProps, 'formatter' | 'parser'> & {
  integer?: boolean;
};

export default function QuantityField({ placeholder = 'Enter qty..', integer = false, ...other }: QuantityFieldProps) {
  return (
    <NumberField
      {...other}
      placeholder={placeholder}
      formatter={integer ? formatterInteger : (value) => formatter(value, 2)}
      /* @ts-ignore-nex*/ // NOTE: bug in antd: empty values should not be forced to numbers
      parser={integer ? parserInteger : parser}
    />
  );
}
