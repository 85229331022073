import clsx from 'clsx';
import CollectibleImageUpload from '../../../../sci-ui-components/forms/CollectibleImageUpload/CollectibleImageUpload';
import { CollectibleType } from '../../../../sci-ui-components/types/collectibleType';
import { showError } from '../../../../services/toaster';
import { cropImage } from 'sci-ui-components/forms/ImageCropperDialog';
import { isDataUrl } from '@/sci-ui-components/utils/image';

export function CustomCollectiblePhotoSelector({
  id,
  collectibleType,
  currentImageUrl,
  onChange,
  value,
  className,
}: {
  id: string;
  collectibleType: CollectibleType;
  currentImageUrl: string | null;
  value: string | null;
  onChange: (newImageUrl: string | null) => void;
  className?: string;
}) {
  return (
    <CollectibleImageUpload
      className={clsx('w-[204px]', className)}
      collectibleType={collectibleType}
      id={id}
      defaultImageUrl={currentImageUrl}
      imageUrl={value}
      onChange={async (selection) => {
        if (!selection) {
          return;
        }
        const croppedImage = await cropImage({
          collectibleType,
          imageDataUrl: selection.imageDataUrl,
        });
        if (croppedImage) {
          onChange(croppedImage);
        }
      }}
      onReCrop={
        !!currentImageUrl && isDataUrl(currentImageUrl)
          ? async (imageUrl) => {
              const croppedImage = await cropImage({
                collectibleType,
                imageDataUrl: imageUrl,
              });
              if (croppedImage) {
                onChange(croppedImage);
              }
            }
          : undefined
      }
      onError={(error) => {
        showError({
          description: error?.message ?? 'Failed to select an image',
        });
      }}
    />
  );
}
