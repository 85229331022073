import { useFormikContext } from 'formik';
import * as Yup from 'yup';
import FieldLabelBox from '../../../../../sci-ui-components/forms/FieldLabelBox/FieldLabelBox';
import FormFieldsRow from '../../../../../sci-ui-components/forms/FormFieldsRow/FormFieldsRow';
import { CustomCollectible } from '../../../../../sci-ui-components/types/collectible';
import TextField from '../../../../../sci-ui-components/forms/TextField/TextField';
import { CustomCollectibleQueryFormValues } from './types';

export function CustomCollectibleQueryFields({ formId }: { formId: string }) {
  const { values, errors, handleChange } = useFormikContext<CustomCollectibleQueryFormValues>();
  const customQueryFieldId = `${formId}-qustom-query`;

  return (
    <FormFieldsRow>
      <FieldLabelBox label="Custom Query" fieldId={customQueryFieldId} error={errors.customQuery}>
        <TextField
          id={customQueryFieldId}
          name="customQuery"
          value={values.customQuery}
          onChange={(value, name) => handleChange({ target: { value, name } })}
          isMultiLine
          rows={2}
        />
      </FieldLabelBox>
    </FormFieldsRow>
  );
}

export const customCollectibleQueryValidationSchema: Yup.SchemaOf<CustomCollectibleQueryFormValues> =
  Yup.object().shape({
    customQuery: Yup.string().optional().nullable(),
  });

export function getCustomCollectibleQueryInitialValues(
  collectible: CustomCollectible | null
): CustomCollectibleQueryFormValues {
  if (!collectible) {
    return {
      customQuery: null,
    };
  }
  return {
    customQuery: collectible?.customQuery,
  };
}
