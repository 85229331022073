import { AssignedCardSetVariation } from '../../../../sci-ui-components/types/cardSet';
import { ApiAssignedCardSetVariation } from '../../types';

export default function assignedCardSetVariationFromApi(
  apiItem: ApiAssignedCardSetVariation
): AssignedCardSetVariation {
  return {
    id: apiItem.id,
    name: apiItem.name,
    setName: apiItem.set_name,
    setYear: Number(apiItem.set_year),
  };
}
