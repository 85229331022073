import { useMemo, useCallback } from 'react';
import type { CollectibleSportsCard, CollectibleSealedWax } from '@sportscardinvestor/schemas';
import {
  useAuthenticatedMMAPIQuery,
  useAuthenticatedMMAPIInfiniteQuery,
  MmApiInput,
  MmApiOutput,
  MmApiQueryOptions,
  MmApiInfiniteQueryOptions,
  mmApiClient,
} from '../../services/mmApiX/index';
import { SportsCardSearch, SealedWaxSearch, SearchBase, SearchItem } from '../../services/sciApi/search/types';

export type { SearchItem };

export type UseMultiSearchInput = Exclude<MmApiInput['private']['collectibles']['multiSearch'], void>;
export type UseMultiSearchOutput = MmApiOutput['private']['collectibles']['multiSearch'];
export type MultiSearchItems = Exclude<UseMultiSearchOutput, void | null>[number]['items'];
export type MultiSearchItem = MultiSearchItems[number];
export type UseMultiSearchInputFilters = UseMultiSearchInput['filters'][number];

export const useMultiSearchKeyPrefix = 'private.collectibles.multiSearch';
export type UseMultiSearchQueryKey = [typeof useMultiSearchKeyPrefix, UseMultiSearchInput];

export default function useMultiSearch(
  params: UseMultiSearchInput,
  options: MmApiQueryOptions<UseMultiSearchOutput, UseMultiSearchQueryKey> = {}
) {
  const queryKey: UseMultiSearchQueryKey = [useMultiSearchKeyPrefix, params];
  const result = useAuthenticatedMMAPIQuery(
    queryKey,
    () => mmApiClient.private.collectibles.multiSearch.query(params),
    {
      staleTime: 1000 * 60 * 60 * 1, // 1 hour
      ...options,
      enabled: params?.filters?.length > 0 && options?.enabled !== false,
    }
  );

  return result;
}

export function useFuzzySearch(
  params: UseMultiSearchInput,
  options: MmApiInfiniteQueryOptions<UseMultiSearchOutput, UseMultiSearchQueryKey> = {}
) {
  const queryKey: UseMultiSearchQueryKey = [useMultiSearchKeyPrefix, params];
  const { hasNextPage, fetchNextPage, data, ...result } = useAuthenticatedMMAPIInfiniteQuery(
    queryKey,
    ({ pageParam = 0 }) => mmApiClient.private.collectibles.multiSearch.query({ ...params, offset: pageParam }),
    {
      staleTime: 1000 * 60 * 60 * 1, // 1 hour
      ...options,
      getNextPageParam: (lastPage, allPages) => {
        if ((params.limit && lastPage.length < params.limit) || lastPage.length === 0) return undefined;
        return allPages
          .flat()
          .map((page) => page.items)
          .flat().length;
      },
      enabled: params?.filters?.length > 0 && options?.enabled !== false,
    }
  );

  const fetchNextPageIfAvailable = useCallback(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage]);

  const items = useMemo(
    () =>
      transformFuzzySearchToQuickSearch({
        collectibleType: params.collectibleType,
        items: data?.pages.map((page) => page[0].items.map(({ item }) => item)).flat() as MultiSearchItem['item'][],
      }) ?? [],
    [data, params.collectibleType]
  );

  return { fetchNextPageIfAvailable, data, items, hasNextPage, ...result };
}

export function transformFuzzySearchToQuickSearch({
  items = [],
  collectibleType,
}: {
  items: MultiSearchItem['item'][];
  collectibleType: UseMultiSearchInput['collectibleType'];
}) {
  const searchBases = items.map<SearchBase>((item) => ({
    id: Number(item.id),
    adminImageUrl: item.imageUrl,
    allEndAvg: item.stats.all.endAvgPrice,
    lastSaleDate: item.stats.lastSaleDate,
    imageUrl: item.imageUrl,
    last14Count: item.stats.last14.totalSalesCount,
    last14EndAvg: item.stats.last14.endAvgPrice,
    last14StartAvg: item.stats.last14.startAvgPrice,
    last14Total: item.stats.last14.totalSalesAmount,
    last14ChangeAmount: item.stats.last14.priceChangeAmount,
    last14ChangePercentage: item.stats.last14.priceChangePercentage,
  }));
  if (collectibleType === 'sealed-wax-card') {
    const collectibles = items as CollectibleSealedWax[];
    return collectibles.map<SealedWaxSearch>((collectible, i) => ({
      ...searchBases[i],
      sportName: collectible.sport.name,
      sportId: Number(collectible.sport.id),
      boxTypeName: collectible.boxType.name,
      boxTypeId: Number(collectible.boxType.id),
      setName: collectible.set.name,
      setId: Number(collectible.set.id),
      setYear: collectible.set.year as string,
    }));
  } else {
    const collectibles = items as CollectibleSportsCard[];
    return collectibles.map<SportsCardSearch>((collectible, i) => ({
      ...searchBases[i],
      sportName: collectible.sport.name,
      sportId: Number(collectible.sport.id),
      setName: collectible.set.name,
      setId: Number(collectible.set.id),
      setYear: collectible.set.year as string,
      specificQualifier: collectible.specificQualifier,
      gradeName: collectible.grade.name,
      printRun: collectible.setVariation.printRun,
      playerAlias: collectible.player.aliases?.[0],
      playerId: Number(collectible.player.id),
      gradeId: Number(collectible.grade.id),
      packOdds: collectible.setVariation.packOdds,
      playerName: collectible.player.name,
      cardNumber: collectible.cardNumber,
      rc: collectible.isRookie,
      variationId: Number(collectible.setVariation.variation.id),
      variationName: collectible.setVariation.variation.name,
      populationCount: collectible.stats.currentPopulationCount,
      imageUrl: collectible.gradelessImageUrl ?? collectible.imageUrl,
    }));
  }
}
