import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import FieldLabelBox from 'sci-ui-components/forms/FieldLabelBox/FieldLabelBox';
import ModalDialog from 'sci-ui-components/ModalDialog/ModalDialog';
import FormSection from 'sci-ui-components/forms/FormSection/FormSection';
import { EditCollectionItemNoteResponse } from 'services/sciApi/collections';
import useCollectionItemMutations from 'features/collection/useCollectionItemMutations';
import { CollectionItemNoteDialogInfoProps } from 'features/collection/CollectionItemNoteDialog/types';
import TextField from 'sci-ui-components/forms/TextField/TextField';

const formId = 'edit-collection-item-note-dialog';
const noteFieldId = `${formId}-note`;

type FormValues = {
  note?: string;
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object().shape({
  note: Yup.string(),
});

interface Props extends CollectionItemNoteDialogInfoProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit?: (response: EditCollectionItemNoteResponse | unknown) => void;
}

export default function CollectionItemNoteDialog({ isOpen, onClose, onSubmit, initialValues }: Props) {
  const { updateCollectionItem } = useCollectionItemMutations();

  const handleSubmit = async ({ note }: FormValues, { setSubmitting, resetForm }: FormikHelpers<FormValues>) => {
    const response = await updateCollectionItem.mutateAsync({
      note: {
        collectionItemId: initialValues.id,
        note: note ?? '',
      },
      collectibleType: initialValues.collectibleType,
    });

    onSubmit?.(response);
    onClose();
    setSubmitting(false);
    resetForm();
  };

  return (
    <Formik<FormValues>
      initialValues={{
        note: initialValues?.note ?? '',
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnBlur={false}
    >
      {({ values, handleChange, errors, isSubmitting, resetForm }) => (
        <ModalDialog
          visible={isOpen}
          title={`Update Collection Item Note`}
          okText={'Ok'}
          onCancel={() => {
            resetForm();
            onClose();
          }}
          cancelText="Cancel"
          okButtonProps={{
            htmlType: 'submit',
            form: formId,
            disabled: isSubmitting,
          }}
          cancelButtonProps={{
            disabled: isSubmitting,
          }}
        >
          <Form id={formId}>
            <FormSection>
              <FieldLabelBox label="Note" fieldId={noteFieldId} error={errors.note}>
                <TextField
                  id={noteFieldId}
                  name="note"
                  value={values.note}
                  onChange={(value, name) => handleChange({ target: { value, name } })}
                  isMultiLine
                />
              </FieldLabelBox>
            </FormSection>
          </Form>
        </ModalDialog>
      )}
    </Formik>
  );
}
