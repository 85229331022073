import { useRef, useCallback } from 'react';
import clsx from 'clsx';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-light-svg-icons';
import IconButton from '../../buttons/IconButton/IconButton';
import { PlatformNotification } from '../../types/platform';
import { CollectibleOption } from '../../collectibles/constants';

import classes from './PlatformNotifications.module.scss';

export default function PlatformNotifications({
  className,
  notifications,
  collectibleType = 'all',
}: {
  className?: string;
  notifications: PlatformNotification[];
  collectibleType?: CollectibleOption;
}) {
  const listRef = useRef<HTMLUListElement>(null);
  const handleNext = useCallback(() => {
    const container = listRef.current;
    if (!container) {
      console.error('Somehow handleNext is called when <ul> is not rendered');
      return; // should never happen
    }
    const isOnLastItem = container.scrollWidth - container.clientWidth * 1.5 < container.scrollLeft;
    if (isOnLastItem) {
      container.scrollTo({
        behavior: 'smooth',
        left: 0,
      });
    } else {
      container.scrollBy({
        behavior: 'smooth',
        left: container.getBoundingClientRect().width,
      });
    }
  }, [listRef]);
  const handlePrev = useCallback(() => {
    const container = listRef.current;
    if (!container) {
      console.error('Somehow handlePrev is called when <ul> is not rendered');
      return; // should never happen
    }
    const isOnFirstItem = container.scrollLeft < container.clientWidth * 0.5;
    if (isOnFirstItem) {
      container.scrollTo({
        behavior: 'smooth',
        left: container.scrollWidth - container.clientWidth,
      });
    } else {
      container.scrollBy({
        behavior: 'smooth',
        left: -container.getBoundingClientRect().width,
      });
    }
  }, [listRef]);

  const hasMultiple = notifications.length > 1;
  return (
    <div className={clsx(classes.root, className)}>
      {hasMultiple && (
        <IconButton
          className={clsx(classes.button, classes.buttonPrev)}
          size="small"
          faIcon={faAngleLeft}
          onClick={handlePrev}
          title="Show previous notification"
        />
      )}
      <ul className={classes.list} ref={listRef}>
        {notifications.map((notification) => {
          const { id, collectibleType: notificationCollectibleType = 'all' } = notification;
          if (
            collectibleType !== 'all' &&
            notificationCollectibleType !== 'all' &&
            notificationCollectibleType !== collectibleType
          ) {
            return null;
          }
          return <Notification key={id} {...notification} />;
        })}
      </ul>
      {hasMultiple && (
        <IconButton
          className={clsx(classes.button, classes.buttonNext)}
          size="small"
          faIcon={faAngleRight}
          onClick={handleNext}
          title="Show next notification"
        />
      )}
    </div>
  );
}

function Notification({ type, messageText }: PlatformNotification) {
  return (
    <li
      className={clsx(classes.listItem, {
        [classes.typeCritical]: type === 'critical',
        [classes.typeInfo]: type === 'info',
        [classes.typeWarning]: type === 'warning',
      })}
    >
      <div className={classes.notification}>
        <p className={classes.notificationText}>{messageText}</p>
      </div>
    </li>
  );
}
