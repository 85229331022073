import { useMemo, useCallback } from 'react';
import {
  useAuthenticatedMMAPIInfiniteQuery,
  useAuthenticatedMMAPIQuery,
  MmApiInfiniteQueryOptions,
  MmApiQueryOptions,
  MmApiInput,
  MmApiOutput,
  mmApiClient,
} from '../../services/mmApiX/index';
import { getOSTextSearchParams } from '../sales/helpers';
import { SealedWaxSearch, SportsCardSearch } from '../../services/sciApi/search/types';

export type UseCollectiblesSearchInput = MmApiInput['private']['collectibles']['search'];
export type UseCollectiblesSearchSortByValue = Exclude<UseCollectiblesSearchInput['sort'], void>[number]['sortBy'];
export type UseCollectiblesSearchOutput = MmApiOutput['private']['collectibles']['search'];
export type CollectiblesSearchItem = Exclude<UseCollectiblesSearchOutput, void | null>['items'][number];
export type Collectible = Omit<CollectiblesSearchItem['item'], 'indexedAt'>;

export const useCollectiblesSearchKeyPrefix = 'private.collectibles.search';
type QueryKey = [typeof useCollectiblesSearchKeyPrefix, UseCollectiblesSearchInput];

export const convertArrayToSearchItems = (items: CollectiblesSearchItem[]): (SportsCardSearch | SealedWaxSearch)[] => {
  return items.map(({ item }) => {
    if (item.collectibleType === 'sports-card') {
      return {
        sportName: item.sport.name,
        sportId: Number(item.sport.id),
        specificQualifier: item.specificQualifier,
        gradeName: item.grade.name,
        printRun: item.setVariation.printRun,
        playerAlias: item.player.aliases?.[0],
        playerId: Number(item.player.id),
        setName: item.set.name,
        gradeId: Number(item.grade.name),
        setId: Number(item.set.id),
        packOdds: item.setVariation.packOdds,
        playerName: item.player.name,
        setYear: item.set.year,
        cardNumber: item.cardNumber,
        rc: item.isRookie,
        variationId: Number(item.setVariation.variation.id),
        variationName: item.setVariation.variation.name,
        populationCount: item.stats.currentPopulationCount,
        id: Number(item.id),
        adminImageUrl: item.imageUrl,
        allEndAvg: item.stats.all.endAvgPrice,
        lastSaleDate: item.stats.lastSaleDate,
        imageUrl: item.gradelessImageUrl,
        last14Count: item.stats.last14.totalSalesCount,
        last14EndAvg: item.stats.last14.endAvgPrice,
        last14StartAvg: item.stats.last14.startAvgPrice,
        last14Total: item.stats.last14.totalSalesAmount,
        last14ChangeAmount: item.stats.last14.priceChangeAmount,
        last14ChangePercentage: item.stats.last14.priceChangePercentage,
      } as SportsCardSearch;
    }
    if (item.collectibleType === 'sealed-wax-card') {
      return {
        sportName: item.sport.name,
        sportId: Number(item.sport.id),
        boxTypeName: item.boxType.name,
        boxTypeId: Number(item.boxType.id),
        setName: item.set.name,
        setId: Number(item.set.id),
        setYear: item.set.year,
        id: Number(item.id),
        adminImageUrl: item.imageUrl,
        allEndAvg: item.stats.all.endAvgPrice,
        lastSaleDate: item.stats.lastSaleDate,
        imageUrl: item.imageUrl,
        last14Count: item.stats.last14.totalSalesCount,
        last14EndAvg: item.stats.last14.endAvgPrice,
        last14StartAvg: item.stats.last14.startAvgPrice,
        last14Total: item.stats.last14.totalSalesAmount,
        last14ChangeAmount: item.stats.last14.priceChangeAmount,
        last14ChangePercentage: item.stats.last14.priceChangePercentage,
      } as SealedWaxSearch;
    } else {
      return {} as SportsCardSearch;
    }
  });
};

const defaultSort: UseCollectiblesSearchInput['sort'] = [
  {
    sortBy: 'stats.last14.totalSalesCount',
    sortDirection: 'desc',
  },
  {
    sortBy: 'stats.all.endAvgPrice',
    sortDirection: 'desc',
  },
  {
    sortBy: 'id.keyword',
    sortDirection: 'asc',
  },
];

export function usePaginatedCollectiblesSearch(
  { sort = defaultSort, ...rest }: UseCollectiblesSearchInput,
  options?: MmApiQueryOptions<UseCollectiblesSearchOutput, QueryKey>
) {
  const input = {
    sort,
    ...rest,
  };
  const queryKey: QueryKey = [useCollectiblesSearchKeyPrefix, input];
  const { data, ...restOfResult } = useAuthenticatedMMAPIQuery(
    queryKey,
    async () => {
      const formattedInput = {
        ...input,
        ...{ searchQueryText: getOSTextSearchParams(input.searchQueryText || '') },
        disableSpellCheck: true,
      };
      return mmApiClient.private.collectibles.search.query(formattedInput);
    },
    options
  );

  const items = useMemo(() => convertArrayToSearchItems(data?.items ?? []), [data]);

  return {
    ...restOfResult,
    items,
    totalCount: data?.totalCount,
  };
}

export default function useCollectiblesSearch(
  { sort = defaultSort, ...rest }: UseCollectiblesSearchInput,
  options?: MmApiInfiniteQueryOptions<UseCollectiblesSearchOutput, QueryKey>
) {
  const input = {
    sort,
    ...rest,
  };
  const queryKey: QueryKey = [useCollectiblesSearchKeyPrefix, input];
  const { fetchNextPage, hasNextPage, ...result } = useAuthenticatedMMAPIInfiniteQuery(
    queryKey,
    async (props) => {
      const { pageParam } = props;
      const formattedInput = {
        ...input,
        ...{ searchQueryText: getOSTextSearchParams(input.searchQueryText || '') },
        disableSpellCheck: !!pageParam || input.disableSpellCheck,
        offset: pageParam ?? 0,
      };
      return mmApiClient.private.collectibles.search.query(formattedInput);
    },
    {
      ...(options ?? {}),
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage) {
          return null;
        }
        const totalFetched = pages.reduce((acc, page) => acc + page.items.length, 0);
        if (lastPage.totalCount <= totalFetched) {
          return null;
        }
        return totalFetched;
      },
    }
  );

  const [allItems, totalCount, verifiedSpellCheckedAlternatives] = useMemo(() => {
    return (
      result.data?.pages?.reduce<[(SportsCardSearch | SealedWaxSearch)[], number, string[]]>(
        (acc, page) => {
          const previousPageVerifiedSpellchecks = acc[2] || [];
          acc[0].push(...convertArrayToSearchItems(page.items));
          acc[1] = page.totalCount;
          acc[2] = previousPageVerifiedSpellchecks.length
            ? previousPageVerifiedSpellchecks
            : page.verifiedSuggestions || [];
          return acc;
        },
        [[], 0, []]
      ) ?? [[], 0, []]
    );
  }, [result.data]);

  const fetchNextPageIfAvailable = useCallback(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage]);

  return {
    ...result,
    allItems,
    verifiedSpellCheckedAlternatives,
    totalCount,
    fetchNextPageIfAvailable,
    fetchNextPage,
    hasNextPage,
  };
}
