import { z } from 'zod';
import { Controller, useFormContext } from 'react-hook-form';
import { getCollectibleImageUrl } from '@sportscardinvestor/collectible-helpers';
import { CollectibleType, CustomCollectible } from '@sportscardinvestor/schemas';
import { CustomCollectiblePhotoSelector } from './CustomCollectiblePhotoSelector';
import FieldLabelBox from '@/sci-ui-components/forms/FieldLabelBox/FieldLabelBox';

export const imageUrlFormSchema = z.object({
  imageUrl: z.string().nullable().default(null),
});

type ImageUrlFormSchema = z.input<typeof imageUrlFormSchema>;
type ImageUrlFormPayload = z.output<typeof imageUrlFormSchema>;

export function ImageUrlField({
  className,
  collectibleType,
  initialImageUrl,
}: {
  className?: string;
  collectibleType: CollectibleType;
  initialImageUrl: string | null | undefined;
}) {
  const form = useFormContext<ImageUrlFormSchema, unknown, ImageUrlFormPayload>();

  return (
    <Controller
      name="imageUrl"
      control={form.control}
      render={({ field, fieldState }) => (
        <FieldLabelBox className={className} label="Image" fieldId="imageUrl" error={fieldState.error?.message}>
          <CustomCollectiblePhotoSelector
            collectibleType={collectibleType}
            currentImageUrl={initialImageUrl ?? null}
            id="imageUrl"
            onChange={(newImageUrl) => form.setValue('imageUrl', newImageUrl)}
            value={field.value ?? null}
          />
        </FieldLabelBox>
      )}
    />
  );
}

export function getImageUrlFormInitialValues({
  collectible,
}: {
  collectible: CustomCollectible | null;
}): ImageUrlFormSchema {
  if (!collectible) {
    return {};
  }
  return {
    imageUrl: getCollectibleImageUrl(collectible),
  };
}
