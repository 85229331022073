import { PlatformNotification, PlatformNotificationType } from '../../../../sci-ui-components/types/platform';
import { ApiBanner } from '../types';

export default function platformNotificationFromApi({ banner }: ApiBanner): PlatformNotification | null {
  if (!banner) {
    return null;
  }
  return {
    id: String(banner.id),
    messageText: banner.text,
    type: getNotificationType(banner.url),
    collectibleType: 'all',
  };
}

function getNotificationType(url: string | undefined): PlatformNotificationType {
  if (url?.includes('/warning')) {
    return 'warning';
  }
  if (url?.includes('/critical')) {
    return 'critical';
  }
  if (url?.includes('/info')) {
    return 'info';
  }
  return 'info';
}
