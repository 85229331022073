import { useCallback } from 'react';
import { withErrorBoundary } from '../../../sci-ui-components/ErrorBoundary/ErrorBoundary';
import { useCustomCollectible } from '../useListCollectionItems';
import useCollectionItemMutations from '../useCollectionItemMutations';
import { useEditManualCollectibleValueDialogStore } from './store';
import { EditManualCollectibleValueForm, OnSubmitFn } from './EditManualCollectibleValueForm';
import ModalDialog from '@/sci-ui-components/ModalDialog/ModalDialog';
import useCurrencyFormatter from '@/hooks/useCurrencyFormatter';

export default withErrorBoundary(function EditManualCollectibleValueDialog() {
  const { collectibleType, customCollectibleId, close } = useEditManualCollectibleValueDialogStore();
  const { customCollectible } = useCustomCollectible(
    {
      collectibleId: customCollectibleId!,
      collectibleType,
    },
    {
      enabled: !!customCollectibleId,
    }
  );
  const { updateCollectionItem } = useCollectionItemMutations();
  const { convertPriceToUSD } = useCurrencyFormatter();

  const handleSaveValue = useCallback<OnSubmitFn>(
    ({ newCurrentPriceInDisplayCurency, newCurrentPrice, newCustomQuery }) => {
      // This function is invoked with a parameter when the Use Price buttons are clicked in the results list. Else it will use the newValue state value, which gets updated in case of manual input.
      if (customCollectibleId) {
        updateCollectionItem.mutateAsync(
          {
            customCollectible: {
              collectibleType: collectibleType,
              collectibleId: customCollectibleId,
              customQuery: newCustomQuery?.trim() || null,
              currentValue: newCurrentPrice
                ? newCurrentPrice
                : convertPriceToUSD({ value: newCurrentPriceInDisplayCurency }),
            },
            collectibleType: collectibleType,
          },
          {
            onSuccess: close,
          }
        );
      }
    },
    [customCollectibleId, collectibleType, convertPriceToUSD, updateCollectionItem, close]
  );

  const handleUpdateCustomQuery = useCallback(
    ({ newCustomQuery }: { newCustomQuery: string | null }) => {
      // This function is invoked with a parameter when the Use Price buttons are clicked in the results list. Else it will use the newValue state value, which gets updated in case of manual input.
      if (customCollectibleId) {
        updateCollectionItem.mutateAsync({
          customCollectible: {
            collectibleType: collectibleType,
            collectibleId: customCollectibleId,
            customQuery: newCustomQuery?.trim() || null,
          },
          collectibleType: collectibleType,
        });
      }
    },
    [customCollectibleId, collectibleType, updateCollectionItem]
  );

  return (
    <ModalDialog
      open={!!customCollectibleId}
      title={'Update Manual Card Value'}
      footer={null}
      onCancel={close}
      width={1000}
      withHeaderUnderline
    >
      {!!customCollectible && (
        <EditManualCollectibleValueForm
          customCollectible={customCollectible}
          isLoading={updateCollectionItem.isLoading}
          onCancel={close}
          onSubmit={handleSaveValue}
          onUpdateCustomQuery={handleUpdateCustomQuery}
        />
      )}
    </ModalDialog>
  );
});
