import React from 'react';
import NextLink from 'next/link';

import { faCircleXmark, faRotateRight } from '@fortawesome/pro-regular-svg-icons';
import { faAngleRight } from '@fortawesome/pro-light-svg-icons';
import SimpleSlider from '../../sci-ui-components/SimpleSlider/SimpleSlider';
import CollectibleImage from '../../sci-ui-components/collectibles/CollectibleImage/CollectibleImage';
import GradeIndicator from '../../sci-ui-components/sport-cards/GradeIndicator/GradeIndicator';
import IconButton from '../../sci-ui-components/buttons/IconButton/IconButton';
import Button from '../../sci-ui-components/buttons/Button/Button';
import { getSportsCardGrade } from '../../sci-ui-components/utils/collectibleDescription';
import { maxSelectableCollectibles } from '../../constants';
import { generateCollectiblesChartHref } from '../search/utils/collectibleChart';
import useCollectibleType from '../collectibles/useCollectibleType';
import { useCollectibles } from '../collectibles/useCollectible';
import useSelectedCollectibleIds from '../search/useSelectedCollectibleIds';

import classes from './SelectedCards.module.scss';

const items = Array(maxSelectableCollectibles).fill(0);

const SelectedCards: React.FC<{ onCardsChart: () => void }> = ({ onCardsChart }) => {
  const { selectedCollectibleIds, removeCollectibleId, clearCollectibleIds } = useSelectedCollectibleIds();
  const { collectibleType } = useCollectibleType();
  const { collectibles } = useCollectibles({ collectibleIds: selectedCollectibleIds, collectibleType });

  if (!selectedCollectibleIds.length) return null;

  const chartHref = generateCollectiblesChartHref({ collectibleIds: selectedCollectibleIds, collectibleType });

  return (
    <div className={classes.wrapper}>
      <SimpleSlider className={classes.slider} listClassName={classes.sliderItems}>
        {items.map((_, index) => {
          const collectible = collectibles[index];
          if (!collectible) {
            return <div key={index} className={classes.emptyCard}></div>;
          }
          return (
            <div key={index} className={classes.chart}>
              {!!collectible && (
                <>
                  <div className={classes.card}>
                    <CollectibleImage
                      url={collectible.imageUrl}
                      collectibleType="sports-card"
                      alt={'name'}
                      className={classes.image}
                      size="small"
                    />
                    <GradeIndicator
                      grade={getSportsCardGrade(collectible)}
                      className={classes.grade}
                      gradeTextClassName={classes.gradeText}
                    />
                  </div>
                  <IconButton
                    onClick={() => removeCollectibleId(collectible.id)}
                    title="Remove"
                    faIcon={faCircleXmark}
                    size="small"
                    color="default"
                    className={classes.closeIcon}
                  />
                </>
              )}
            </div>
          );
        })}
      </SimpleSlider>
      <div className={classes.actions}>
        <Button
          className={classes.resetButton}
          size="small"
          type="text"
          onClick={() => clearCollectibleIds()}
          color="default"
          shape="round"
          minWidth={0}
          faIconRight={faRotateRight}
        >
          RESET
        </Button>
        <NextLink href={chartHref} legacyBehavior>
          <Button
            href={chartHref}
            size="small"
            shape="round"
            className={classes.chartCardsButton}
            onClick={onCardsChart}
            type="primary"
            color="primary"
            minWidth={0}
            faIconRight={faAngleRight}
          >
            CHART
          </Button>
        </NextLink>
      </div>
    </div>
  );
};

export default SelectedCards;
