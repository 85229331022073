import { DetailedHTMLProps, HTMLAttributes } from 'react';
import clsx from 'clsx';
import { marketplaceNames, SellerInfo } from '../../types/sales';

import classes from './MarketplaceLogo.module.scss';

export type MarketplaceLogoProps = SellerInfo & {
  displaySellerName?: boolean;
} & DetailedHTMLProps<HTMLAttributes<HTMLImageElement>, HTMLImageElement>;

export default function MarketplaceLogo({
  className,
  logoUrl,
  marketplace,
  sellerName,
  displaySellerName = false,
  isKnownSeller,
  ...otherProps
}: MarketplaceLogoProps) {
  return (
    <label className={clsx(classes.root, className)}>
      <img
        {...otherProps}
        alt={`${marketplaceNames[marketplace]} ${sellerName ?? ''}`}
        src={logoUrl ?? '/icons/platforms/ebay.png'}
        className={classes.image}
        loading="lazy"
      />
      {displaySellerName && !!sellerName && !isKnownSeller && <span className={classes.name}>{sellerName}</span>}
    </label>
  );
}
