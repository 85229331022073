import { CollectibleType } from '@sportscardinvestor/schemas';
import { create } from 'zustand';

interface EditCustomCollectionItemDialogState {
  collectionItemId: number | null;
  collectibleType: CollectibleType;
  close: () => void;
}

const defaultCollectibleType: CollectibleType = 'sports-card';

export const useEditCustomCollectionItemDialogStore = create<EditCustomCollectionItemDialogState>()((set) => ({
  collectionItemId: null,
  collectibleType: defaultCollectibleType,
  close: () => set({ collectionItemId: null, collectibleType: defaultCollectibleType }),
}));

export interface EditCustomCollectionItemDialogProps {
  collectionItemId: number;
  collectibleType: CollectibleType;
}
export function showEditCustomCollectionItemDialog({
  collectionItemId,
  collectibleType,
}: EditCustomCollectionItemDialogProps) {
  useEditCustomCollectionItemDialogStore.setState({
    collectionItemId,
    collectibleType,
  });
}
