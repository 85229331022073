import { Separator } from '@sportscardinvestor/sci-ui/src/components/ui/separator';
import { useCollectibleStats } from '../../stats/useCollectibleStats';
import { useCollectible } from '../useCollectible';
import { temp_convertCustomCollectibleToOldCustomCollectible } from '../helpers/temp_convertCustomCollectibleToOldCustomCollectible';
import { useShallowFlyoutStore } from './store';
import FlyoutDateRangeSelector from './FlyoutDateRangeSelector';
import CollectibleStats from './CollectibleStats';
import CollectiblesForSale from './CollectiblesForSale';
import CollectiblesCompletedSales from './CollectiblesCompletedSales';
import NonCustomCollectibleInfo from './NonCustomCollectibleInfo';
import CustomCollectibleInfo from './CustomCollectibleInfo';
import CollectionInfo from './CollectionInfo';
import FlyoutChart from './FlyoutChart';
import { isNonCustomCollectible } from '@/sci-ui-components/types/collectible';
import LoadingSpinner from '@/sci-ui-components/LoadingSpinner/LoadingSpinner';
import useAuth from '@/features/auth/useAuth';
import { useCollectibleInCollection } from '@/features/collection/useListCollectionItems';

export function CollectibleDetails() {
  const { collectibleId, collectibleType, isCustom } = useShallowFlyoutStore(
    'collectibleId',
    'collectibleType',
    'isCustom'
  );
  const { isLoggedIn } = useAuth();
  const isPublic = !isLoggedIn;

  const { data, isLoading: isLoadingCollectible } = useCollectible(
    {
      id: collectibleId!,
      collectibleType,
      isPublic,
    },
    {
      enabled: !isCustom && !!collectibleId,
    }
  );
  const { collectibleInCollection, isLoading: isLoadingCustomCollectible } = useCollectibleInCollection(
    {
      collectibleId,
      collectibleType,
      isCustom,
    },
    {
      enabled: isCustom,
    }
  );
  const collectible =
    (collectibleInCollection?.customCollectible
      ? temp_convertCustomCollectibleToOldCustomCollectible(collectibleInCollection.customCollectible)
      : data) ?? null;

  // this is here just to load in parallel with the collectible call, before showing details.
  const { isLoading: isLoadingStats } = useCollectibleStats(
    {
      collectibleType,
      collectibleId: collectibleId!,
      isPublic,
    },
    {
      enabled: !isCustom && !!collectibleId,
    }
  );

  if (isLoadingCollectible || isLoadingStats || (isCustom && isLoadingCustomCollectible))
    return (
      <div className="flex h-full w-full items-center justify-center">
        <LoadingSpinner size="large" />
      </div>
    );

  if (!collectible) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4 ml-4 mb-4 mt-4 w-full overflow-hidden">
      {isNonCustomCollectible(collectible) && <NonCustomCollectibleInfo nonCustomCollectible={collectible} />}
      {collectibleInCollection?.isCustom && <CustomCollectibleInfo collectibleInCollection={collectibleInCollection} />}
      <div className="flex flex-col gap-4 overflow-y-auto pr-4">
        {!isCustom && (
          <>
            <Separator className="bg-gray-300" />
            <FlyoutChart />
            <FlyoutDateRangeSelector />
            <Separator className="bg-gray-300" />
            <CollectibleStats />
          </>
        )}
        <CollectionInfo />
        {!isCustom && (
          <>
            <Separator className="bg-gray-300" />
            <CollectiblesCompletedSales />
            <Separator className="bg-gray-300" />
            <CollectiblesForSale />
          </>
        )}
      </div>
    </div>
  );
}
