import { PlatformNotification } from '../../../sci-ui-components/types/platform';
import authenticatedFetchFromSciApi from '../authenticatedFetchFromSciApi';
import fetchFromSciApi from '../fetchFromSciApi';
import platformNotificationFromApi from './transformers/platformNotificationFromApi';
import { ApiBanner } from './types';

export interface GetPlatformNotificationsParams {
  url?: string;
  isPublic?: boolean;
}

// TODO: modify API to enable returning more than 1 banner at a time
export async function getPlatformNotifications(
  { url, isPublic }: GetPlatformNotificationsParams,
  signal: AbortSignal | undefined
) {
  const banners = await Promise.all([
    getPlatformNotification({ url: '/all/critical' }, signal, isPublic),
    getPlatformNotification({ url: '/all/warning' }, signal, isPublic),
    getPlatformNotification({ url }, signal, isPublic),
    getPlatformNotification({ url: '/all/info' }, signal, isPublic),
  ]);
  return banners.filter((b) => !!b) as PlatformNotification[];
}

export async function getPlatformNotification(
  { url }: GetPlatformNotificationsParams,
  signal: AbortSignal | undefined,
  isPublic?: boolean
) {
  const fetchFunction = isPublic ? fetchFromSciApi : authenticatedFetchFromSciApi;
  const apiItem = await fetchFunction<ApiBanner>(`/info-banner/check${isPublic ? '/public' : ''}`, {
    method: 'POST',
    body: JSON.stringify({
      clientUrl: url ?? '/all',
    }),
    signal,
  });
  return platformNotificationFromApi(apiItem);
}
