import { useShallowFlyoutStore } from './store';
import useAuth from '@/features/auth/useAuth';
import { useCollectibleStats } from '@/features/stats/useCollectibleStats';
import LoadingSpinner from '@/sci-ui-components/LoadingSpinner/LoadingSpinner';
import DashboardStat from '@/sci-ui-components/stats/DashboardStat/DashboardStat';

export default function CollectibleStats() {
  const { collectibleId, collectibleType, dateRangeInDays } = useShallowFlyoutStore(
    'collectibleId',
    'collectibleType',
    'dateRangeInDays'
  );
  const { isLoggedIn } = useAuth();

  const { data: stats, isLoading } = useCollectibleStats(
    {
      collectibleType,
      collectibleId: collectibleId!,
      isPublic: !isLoggedIn,
    },
    {
      enabled: !!collectibleId,
    }
  );

  const statsForDateRange = stats?.[`last${dateRangeInDays}`] ?? null;

  if (isLoading)
    return (
      <div className="flex h-[40] items-center justify-center">
        <LoadingSpinner size="large" />
      </div>
    );

  if (!stats || !statsForDateRange) return <div className="flex items-center justify-center">No sales data.</div>;

  return (
    <div className="flex">
      <DashboardStat
        label={`${dateRangeInDays} Day Avg`}
        value={statsForDateRange.avg ?? null}
        type="price"
        size="medium"
        textClassName="!font-semibold"
        className="flex-1"
      />
      <DashboardStat
        label="Sales Count"
        value={statsForDateRange.count ?? null}
        type="count"
        size="medium"
        textClassName="!font-semibold"
        className="flex-1"
      />
    </div>
  );
}
