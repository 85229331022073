import { useCallback, useEffect, useMemo } from 'react';
import useQueryString, { getNumericArrayParam } from '../../hooks/useQueryString';
import { collectibleNames } from '../collectibles/constants';
import useCollectibleType from '../collectibles/useCollectibleType';
import useAnalytics from '../analytics/useAnalytics';
import { showWarning } from '../../services/toaster';
import { maxSelectableCollectibles } from '../../constants';
import unique from '../../utils/unique';
import { queryKey } from './utils/collectibleChart';

export default function useSelectedCollectibleIds() {
  const { query, setQueryParam } = useQueryString();
  const { collectibleType } = useCollectibleType();
  const { trackEvent } = useAnalytics();

  const selectedCollectibleIds = useMemo(() => unique(getNumericArrayParam<number[]>(query, queryKey, [])), [query]);
  const isEmpty = !selectedCollectibleIds.length;
  const remainingSelections = Math.max(maxSelectableCollectibles - selectedCollectibleIds.length, 0);
  const addCollectibleId = useCallback(
    (collectibleId: number) => {
      if (selectedCollectibleIds.includes(collectibleId)) {
        return;
      }
      if (remainingSelections > 0) {
        setQueryParam(queryKey, unique([...selectedCollectibleIds, collectibleId]));
        trackEvent({
          eventName: 'SEARCH_SELECTED_COLLECTIBLES_ADDED',
          collectibleId,
          collectibleType,
        });
      } else {
        showWarning({
          message: `Maximum ${collectibleNames[collectibleType].shortPlural} selected`,
          description: `You can add up to ${maxSelectableCollectibles} ${collectibleNames[collectibleType].shortPlural}`,
        });
      }
    },
    [selectedCollectibleIds, setQueryParam, remainingSelections, collectibleType, trackEvent]
  );
  const removeCollectibleId = useCallback(
    (collectibleId: number) => {
      setQueryParam(
        queryKey,
        selectedCollectibleIds.filter((id) => id !== collectibleId)
      );
      trackEvent({
        eventName: 'SEARCH_SELECTED_COLLECTIBLES_REMOVED',
        collectibleId,
        collectibleType,
      });
    },
    [selectedCollectibleIds, setQueryParam, trackEvent, collectibleType]
  );
  const toggleSelectCollectibleId = useCallback(
    (collectibleId: number) => {
      selectedCollectibleIds.includes(collectibleId)
        ? removeCollectibleId(collectibleId)
        : addCollectibleId(collectibleId);
    },
    [selectedCollectibleIds, removeCollectibleId, addCollectibleId]
  );
  const replaceCollectibleId = useCallback(
    (fromCollectibleId: number, toCollectibleId: number) => {
      setQueryParam(
        queryKey,
        unique(selectedCollectibleIds.map((id) => (id === fromCollectibleId ? toCollectibleId : id)))
      );
      trackEvent({
        eventName: 'SEARCH_SELECTED_COLLECTIBLES_REPLACED',
        fromCollectibleId,
        toCollectibleId,
        collectibleType,
      });
    },
    [selectedCollectibleIds, setQueryParam, trackEvent, collectibleType]
  );
  const clearCollectibleIds = useCallback(() => {
    setQueryParam(queryKey, []);
    trackEvent({
      eventName: 'SEARCH_SELECTED_COLLECTIBLES_CLEARED',
      collectibleType,
    });
  }, [setQueryParam, collectibleType, trackEvent]);

  const limitedCollectibleIds = useMemo(
    () => selectedCollectibleIds.slice(0, maxSelectableCollectibles),
    [selectedCollectibleIds]
  );
  const isLimitExceeded = limitedCollectibleIds.length < selectedCollectibleIds.length;
  useEffect(() => {
    if (isLimitExceeded) {
      showWarning({
        message: `Too many ${collectibleNames[collectibleType].shortPlural} selected`,
        description: `You can add up to ${maxSelectableCollectibles} ${collectibleNames[collectibleType].shortPlural}`,
      });
      trackEvent({
        eventName: 'SEARCH_SELECTED_COLLECTIBLES_MAX_EXCEEDED',
        collectibleType,
      });
    }
  }, [isLimitExceeded, collectibleType, trackEvent]);
  useEffect(() => {
    if (remainingSelections === 0) {
      trackEvent({
        eventName: 'SEARCH_SELECTED_COLLECTIBLES_MAX_REACHED',
        collectibleType,
      });
    }
  }, [remainingSelections, collectibleType, trackEvent]);

  const selectOnlyCollectibleId = useCallback(
    (collectibleId: number) => {
      setQueryParam(queryKey, [collectibleId]);
      trackEvent({
        eventName: 'SEARCH_SELECTED_COLLECTIBLES_ADDED',
        collectibleId,
        collectibleType,
      });
    },
    [setQueryParam, collectibleType, trackEvent]
  );

  return {
    selectedCollectibleIds: limitedCollectibleIds,
    addCollectibleId,
    removeCollectibleId,
    toggleSelectCollectibleId,
    replaceCollectibleId,
    isEmpty,
    remainingSelections,
    maxSelectableCollectibles,
    selectOnlyCollectibleId,
    clearCollectibleIds,
  };
}
