import React from 'react';
import clsx from 'clsx';
import CollectibleImage from '../../../collectibles/CollectibleImage/CollectibleImage';
import useCollectibleType from '../../../../features/collectibles/useCollectibleType';
import CollectibleText from '../../../collectibles/CollectibleText/CollectibleText';
import { RawCompletedSaleSearchItem } from '../../../../features/sales/raw/useRawCompletedSalesSearch';
import { formatDateInTimeZone } from '../../../utils/date';
import getSellerInfo from '../../../../services/sciApi/sales/transformers/getSellerInfo';
import classes from './SearchItem.module.scss';
import useFormatStatValue from 'hooks/useFormatStatValue';

const RawSaleSearchItem = ({
  item,
  onClick,
}: {
  item: RawCompletedSaleSearchItem;
  onClick: (item: RawCompletedSaleSearchItem) => {};
}) => {
  const { collectibleType } = useCollectibleType();
  const { formatStatValue } = useFormatStatValue();

  return (
    <>
      <div className={clsx(classes.searchItemWrapper, classes.rawSaleItemContainer)} onClick={() => onClick(item)}>
        <CollectibleImage
          className={classes.image}
          url={item?.imageUrls[0]}
          collectibleType={collectibleType}
          alt={item?.displayTitle}
        />
        <div className={classes.rightSideContainer}>
          <CollectibleText className={classes.descriptionText} type={'title'}>
            {item.displayTitle}
          </CollectibleText>
          <div className={clsx(classes.stats, classes.detailsContainer)}>
            <p className={classes.priceDetail}>
              {formatStatValue({
                value: item.offerPrice ?? item.sellingPrice,
                type: 'price',
              })}
            </p>
            <p className={classes.dateDetail}>{formatDateInTimeZone(item.saleDate)}</p>
            <img
              src={getSellerInfo(item.platformId, null).logoUrl}
              alt={getSellerInfo(item.platformId, null).marketplace}
              className={classes.platformLogo}
            />
          </div>
        </div>
      </div>
      <hr className={classes.divider} />
    </>
  );
};

export default RawSaleSearchItem;
