import { useEffect, useMemo } from 'react';
import { isCustomCollectible } from '@sportscardinvestor/collectible-helpers';
import { getCollectionCollectible } from '@sportscardinvestor/collection-helpers';
import ModalDialog from '../../../sci-ui-components/ModalDialog/ModalDialog';
import useCollectionItemMutations from '../useCollectionItemMutations';
import { useCollectionItem } from '../useListCollectionItems';
import { useEditCollectionItemPurchaseDetailsDialogStore } from './store';
import {
  EditCollectionItemPurchaseDetailsForm,
  getEditCollectionItemPurchaseDetailsFormInitialValues,
} from './EditCollectionItemPurchaseDetailsForm';
import useAnalytics from '@/features/analytics/useAnalytics';
import useCurrencyFormatter from '@/hooks/useCurrencyFormatter';
import { withErrorBoundary } from '@/sci-ui-components/ErrorBoundary/ErrorBoundary';

const formId = 'EditCollectionItemPurchaseDetailsForm';

export const EditCollectionItemPurchaseDetailsDialog = withErrorBoundary(
  function EditCollectionItemPurchaseDetailsDialog() {
    const { collectionItemId, collectibleType, close } = useEditCollectionItemPurchaseDetailsDialogStore();
    const { collectionItem } = useCollectionItem({
      collectibleType,
      collectionItemId,
    });
    const { updateCollectionItem } = useCollectionItemMutations();
    const { convertPriceToUSD, convertPriceFromUSD } = useCurrencyFormatter();
    const { trackEvent } = useAnalytics();
    useEffect(() => {
      if (collectionItemId) {
        trackEvent({
          eventName: 'COLLECTION_ITEM_PURCHASE_DETAILS_STARTED',
          collectionItemId,
        });
      }
    }, [trackEvent, collectionItemId]);

    const initialValues = useMemo(
      () =>
        getEditCollectionItemPurchaseDetailsFormInitialValues({
          collectionItem,
          convertPriceFromUSD,
        }),
      [collectionItem, convertPriceFromUSD]
    );

    return (
      <ModalDialog
        open={!!collectionItem}
        title="Edit Purchase Details"
        onCancel={close}
        footer={null}
        variant="primary"
        width={960}
        withHeaderUnderline
      >
        {!!collectionItem && (
          <EditCollectionItemPurchaseDetailsForm
            formId={formId}
            initialValues={initialValues}
            collectible={getCollectionCollectible(collectionItem)}
            onSubmit={({
              categoryId,
              gradingPricePerItemInDisplayCurrency,
              notes,
              purchaseDate,
              purchasePricePerItemInDisplayCurrency,
              quantity,
            }) =>
              updateCollectionItem.mutate(
                {
                  collectibleType,
                  category: {
                    collectionItemId: collectionItem.id,
                    collectionCategoryId: categoryId,
                  },
                  note: {
                    collectionItemId: collectionItem.id,
                    note: notes ?? '',
                  },
                  purchaseDetails: {
                    collectibleType,
                    collectionItemId: collectionItem.id,
                    isCustom: isCustomCollectible(getCollectionCollectible(collectionItem)),
                    purchaseDate,
                    purchasePricePerItem: convertPriceToUSD({ value: purchasePricePerItemInDisplayCurrency }) ?? 0,
                    quantity,
                    gradingPricePerItem: convertPriceToUSD({ value: gradingPricePerItemInDisplayCurrency }),
                  },
                },
                {
                  onSuccess: close,
                }
              )
            }
            onCancel={close}
            isLoading={updateCollectionItem.isLoading}
          />
        )}
      </ModalDialog>
    );
  }
);
