import { useMutation, useQueryClient } from 'react-query';
import { getUseAlertIdsQueryKey } from './useAlertIds';
import {
  CreatePriceAlertParams,
  createPriceAlert,
  EditPriceAlertParams,
  editPriceAlert,
  DeletePriceAlertParams,
  deletePriceAlert,
  ReactivatePriceAlertParams,
  reactivatePriceAlert,
} from 'services/sciApi/alerts/index';
import { getAlertsKey, usePriceAlerts } from 'features/alerts/usePriceAlerts';
import { showError, showSuccess } from 'services/toaster';
import { waitForConfirmation } from 'sci-ui-components/ConfirmationDialog';
import useAnalytics from 'features/analytics/useAnalytics';
import { getLimitForMembershipTierPermissionCategory } from 'features/user/utils';
import useUser from 'features/user/useUser';
import { AddEditPriceAlertResponse } from 'sci-ui-components/types/priceAlert';
import useCollectibleType from 'features/collectibles/useCollectibleType';
import { displayLimitReachedMessageForUserAndCategory } from 'features/user/membership-tier-messaging';
import useCurrencyFormatter from 'hooks/useCurrencyFormatter';

async function doNothing({} = {}): Promise<AddEditPriceAlertResponse> {
  return Promise.resolve({
    id: -1,
    threshold: 'Below',
    name: '',
    createdAt: '',
    updatedAt: '',
    triggeredAt: null,
    userId: -1,
    alertPrice: -1,
    status: 'Expired',
    collectibleType: 'sports-card',
    collectibleId: -1,
  });
}

export default function usePriceAlertMutations() {
  const queryClient = useQueryClient();
  const { trackEvent } = useAnalytics();
  const { data: user } = useUser();
  const { collectibleType } = useCollectibleType();
  const { data: alerts } = usePriceAlerts({ collectibleType });
  const { convertPriceToUSD } = useCurrencyFormatter();

  function isUserAtAlertsLimit(): boolean {
    const limit = getLimitForMembershipTierPermissionCategory(user, 'alerts');

    return limit !== Infinity && !!alerts && alerts.length >= limit;
  }

  const createAlert = useMutation({
    mutationFn: (params: CreatePriceAlertParams) => {
      if (isUserAtAlertsLimit()) {
        displayLimitReachedMessageForUserAndCategory(user, 'alerts');
        return doNothing({});
      }

      return createPriceAlert({
        ...params,
        value: convertPriceToUSD({ value: params.value })!,
      });
    },
    mutationKey: `create-price-alert`,
    onSuccess: ({ collectibleId, collectibleType, id }) => {
      if (isUserAtAlertsLimit()) {
        return;
      }
      queryClient.invalidateQueries({
        queryKey: ['collectible', collectibleType, collectibleId],
      });
      queryClient.refetchQueries(getAlertsKey(collectibleType));
      queryClient.refetchQueries(getUseAlertIdsQueryKey({ collectibleType }));
      showSuccess({
        description: 'Successfully created new alert',
      });

      trackEvent({
        eventName: 'ALERTS_CREATED',
        collectibleId,
        collectibleType,
        alertId: id,
      });
    },
    onError: () => {
      showError({
        description: 'Failed to create new alert. Please try again.',
      });
    },
  });

  const editAlert = useMutation({
    mutationFn: (params: EditPriceAlertParams) =>
      editPriceAlert({ ...params, value: convertPriceToUSD({ value: params.value })! }),
    mutationKey: ['edit-price-alert'],
    onSuccess: ({ collectibleType, collectibleId, id }) => {
      queryClient.refetchQueries(getAlertsKey(collectibleType));
      queryClient.refetchQueries(getUseAlertIdsQueryKey({ collectibleType }));

      showSuccess({
        description: 'Successfully updated alert',
      });
      trackEvent({
        eventName: 'ALERTS_UPDATED',
        collectibleId,
        collectibleType,
        alertId: id,
      });
    },
    onError: () => {
      showError({
        description: 'Failed to update alert. Please try again.',
      });
    },
  });

  const deleteAlert = useMutation({
    mutationFn: (params: DeletePriceAlertParams) => deletePriceAlert(params),
    mutationKey: ['delete-price-alert'],
    onSuccess: async (_, { id }) => {
      await queryClient.refetchQueries(getAlertsKey());
      queryClient.refetchQueries(getUseAlertIdsQueryKey({ collectibleType }));

      showSuccess({
        description: 'Successfully deleted alert',
      });
      trackEvent({
        eventName: 'ALERTS_DELETED',
        alertId: id,
      });
    },
    onError: () => {
      showError({
        description: 'Failed to delete alert. Please try again.',
      });
    },
  });

  const deleteWithConfirmation = async (id: number) => {
    await waitForConfirmation({
      title: 'Delete price alert',
      description: `Are you sure you want to delete this price alert?`,
      onConfirm: async () =>
        await deleteAlert.mutateAsync({
          id,
        }),
      onCancel: () =>
        trackEvent({
          eventName: 'ALERTS_DELETE_CANCELLED',
          alertId: id,
        }),
    });
  };

  const reactivateAlert = useMutation({
    mutationFn: (params: ReactivatePriceAlertParams) => reactivatePriceAlert(params),
    mutationKey: ['reactivate-price-alert'],
    onSuccess: ({ collectibleType, collectibleId, id }) => {
      queryClient.refetchQueries(getAlertsKey(collectibleType));
      queryClient.refetchQueries(getUseAlertIdsQueryKey({ collectibleType }));

      showSuccess({
        description: 'Successfully reactivated alert',
      });
      trackEvent({
        eventName: 'ALERTS_REACTIVATED',
        collectibleId,
        collectibleType,
        alertId: id,
      });
    },
    onError: () => {
      showError({
        description: 'Failed to reactivate alert. Please try again.',
      });
    },
  });

  return {
    createAlert,
    editAlert,
    deleteWithConfirmation,
    reactivateAlert,
  };
}
