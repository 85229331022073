import React from 'react';
import { Tooltip } from 'antd';
import clsx from 'clsx';
import { getCollectibleId } from '@sportscardinvestor/collectible-helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockRotateLeft } from '@fortawesome/pro-light-svg-icons';
import { CollectibleType } from '@sportscardinvestor/schemas';
import { getNumberOfDaysInDateRange } from '../../../sci-ui-components/utils/date';
import { useCustomCollectible } from '../useListCollectionItems';
import classes from './ManualCollectibleValuerTriggerSpan.module.scss';
import { showEditManualCollectibleValueDialog } from './store';

const DAYS_SINCE_LAST_UPDATE_TRIGGER = 30;

const ManualCollectibleValueTriggerSpan = ({
  collectibleId,
  collectibleType,
  className,
}: {
  collectibleId: number;
  collectibleType: CollectibleType;
  className?: string;
}) => {
  const { customCollectible } = useCustomCollectible({
    collectibleId,
    collectibleType,
  });

  const shouldShowTimeNotification =
    !!customCollectible &&
    getNumberOfDaysInDateRange([customCollectible.priceDate, new Date().toISOString()]) >
      DAYS_SINCE_LAST_UPDATE_TRIGGER;

  const Wrapper = ({ children }: { children: React.ReactNode }) =>
    shouldShowTimeNotification ? (
      <Tooltip title={`You haven't updated the value of this collectible in over 30 days.`}>{children}</Tooltip>
    ) : (
      <>{children}</>
    );

  if (!customCollectible) {
    return null;
  }

  return (
    <span
      className={clsx(className, classes.updatePriceSpan, shouldShowTimeNotification && classes.warningColor)}
      onClick={(event) => {
        event.stopPropagation();
        showEditManualCollectibleValueDialog({
          collectibleType,
          customCollectibleId: getCollectibleId(customCollectible),
        });
      }}
    >
      <Wrapper>
        {shouldShowTimeNotification ? <FontAwesomeIcon icon={faClockRotateLeft} className={classes.clockIcon} /> : null}
        Check Value
      </Wrapper>
    </span>
  );
};

export default ManualCollectibleValueTriggerSpan;
