import { ReactNode } from 'react';
import { z } from 'zod';
import clsx from 'clsx';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { getCollectibleType, getIsCollectibleGraded } from '@sportscardinvestor/collectible-helpers';
import { Collectible, CustomCollectible, CollectionItem } from '@sportscardinvestor/schemas';
import CollectionCategoryFilter from '../CollectionCategoryFilter';
import CollectionItemCollectible from '../CollectionItemCollectible';
import { useOnValueChange } from '@/hooks/useOnValueChange';
import NumberField from '@/sci-ui-components/forms/NumberField/NumberField';
import FieldLabelBox from '@/sci-ui-components/forms/FieldLabelBox/FieldLabelBox';
import { collectibleNames } from '@/sci-ui-components/collectibles/constants';
import PriceField from '@/sci-ui-components/forms/PriceField/PriceField';
import { EditForm, EditFormButtons } from '@/sci-ui-components/forms/EditForm';
import { ConvertPriceFn } from '@/hooks/useCurrencyFormatter';
import DateSelector from '@/sci-ui-components/forms/DateSelector/DateSelector';
import TextField from '@/sci-ui-components/forms/TextField/TextField';
import { formatISODate } from '@/sci-ui-components/utils/date';

const formSchema = z.object({
  purchaseDate: z.string({
    required_error: 'Please enter purchase date',
  }),
  quantity: z
    .number({
      required_error: 'Please enter quantity',
    })
    .min(1, 'Must be at least 1'),
  purchasePricePerItemInDisplayCurrency: z.number().default(0),
  purchasePriceTotalInDisplayCurrency: z.number().default(0),
  gradingPricePerItemInDisplayCurrency: z.number().default(0),
  gradingPriceTotalInDisplayCurrency: z.number().default(0),
  categoryId: z.number().nullable().default(null),
  notes: z.string().nullable().default(null),
});

type FormSchema = z.input<typeof formSchema>;
type FormPayload = z.output<typeof formSchema>;

export type OnEditCollectionItemPurchaseDetailsFormSubmitFn = (payload: FormPayload) => void;

export function EditCollectionItemPurchaseDetailsForm({
  onSubmit,
  onCancel,
  formId,
  initialValues,
  collectible,
  className,
  isLoading,
}: {
  onSubmit: OnEditCollectionItemPurchaseDetailsFormSubmitFn;
  onCancel: () => void;
  formId: string;
  initialValues: FormSchema;
  collectible: Collectible | CustomCollectible;
  className?: string;
  isLoading?: boolean;
}) {
  const form = useForm<FormSchema, unknown, FormPayload>({
    resolver: zodResolver(formSchema),
    defaultValues: initialValues,
    mode: 'onSubmit',
  });
  useOnValueChange(initialValues, () => form.reset(initialValues));

  const handleSubmit = form.handleSubmit((payload: FormPayload) => onSubmit(payload));

  const collectibleType = getCollectibleType(collectible);
  const isGradedCollectible = getIsCollectibleGraded(collectible);
  const collectibleName = collectibleNames[collectibleType].shortSingular;

  return (
    <EditForm onSubmit={handleSubmit} id={formId} className={clsx('flex flex-col gap-6', className)}>
      <CollectionItemCollectible collectible={collectible} />
      <section className="grid grid-cols-3 gap-4 md:gap-6">
        <FieldsPairContainer className="col-span-3 md:col-span-2">
          <Controller
            name="purchaseDate"
            control={form.control}
            render={({ field, fieldState }) => (
              <FieldLabelBox
                className="col-span-3 md:col-span-1"
                label="Purchase Date"
                fieldId="purchaseDate"
                error={fieldState.error?.message}
                variant="neater"
              >
                <DateSelector {...field} id="purchaseDate" />
              </FieldLabelBox>
            )}
          />
          <span />
          <Controller
            name="quantity"
            control={form.control}
            render={({ field, fieldState }) => (
              <FieldLabelBox
                className="col-span-3 md:col-span-1"
                label="Quantity:"
                fieldId="quantity"
                error={fieldState.error?.message}
                variant="neater"
              >
                <NumberField
                  {...field}
                  onChange={(value, name) => {
                    field.onChange({ target: { value, name } });
                    const values = form.getValues();
                    if (values.purchasePricePerItemInDisplayCurrency && value) {
                      form.setValue(
                        'purchasePriceTotalInDisplayCurrency',
                        values.purchasePricePerItemInDisplayCurrency * value
                      );
                    }
                    if (values.gradingPricePerItemInDisplayCurrency && value) {
                      form.setValue(
                        'gradingPricePerItemInDisplayCurrency',
                        values.gradingPricePerItemInDisplayCurrency * value
                      );
                    }
                  }}
                  id="quantity"
                  min={1}
                />
              </FieldLabelBox>
            )}
          />
        </FieldsPairContainer>
        <FieldsPairContainer className="col-span-3 md:col-span-2">
          <Controller
            name="purchasePricePerItemInDisplayCurrency"
            control={form.control}
            render={({ field, fieldState }) => (
              <FieldLabelBox
                className="col-span-3 md:col-span-1"
                label={
                  <>
                    Purchase Price<span className="md:hidden">&nbsp;per {collectibleName}</span>:
                  </>
                }
                fieldId="purchasePricePerItemInDisplayCurrency"
                error={fieldState.error?.message}
                optional
                variant="neater"
              >
                <PriceField
                  {...field}
                  onChange={(value, name) => {
                    field.onChange({ target: { value, name } });
                    const values = form.getValues();
                    if (values.quantity) {
                      form.setValue('purchasePriceTotalInDisplayCurrency', values.quantity * (value ?? 0));
                    }
                  }}
                  id="purchasePricePerItemInDisplayCurrency"
                  placeholder={`Per ${collectibleName}`}
                  min={0}
                />
              </FieldLabelBox>
            )}
          />
          <OrText />
          <Controller
            name="purchasePriceTotalInDisplayCurrency"
            control={form.control}
            render={({ field, fieldState }) => (
              <FieldLabelBox
                className="col-span-3 md:col-span-1"
                label={
                  <>
                    Total<span className="md:hidden">&nbsp;Purchase Price</span>:
                  </>
                }
                fieldId="purchasePriceTotalInDisplayCurrency"
                error={fieldState.error?.message}
                optional
                variant="neater"
                labelClassName="md:invisible"
              >
                <PriceField
                  {...field}
                  onChange={(value, name) => {
                    field.onChange({ target: { value, name } });
                    const values = form.getValues();
                    if (values.quantity) {
                      form.setValue('purchasePricePerItemInDisplayCurrency', (value ?? 0) / values.quantity);
                    }
                  }}
                  id="purchasePriceTotalInDisplayCurrency"
                  placeholder="Total"
                  min={0}
                />
              </FieldLabelBox>
            )}
          />
        </FieldsPairContainer>
        {isGradedCollectible && (
          <FieldsPairContainer className="col-span-3 md:col-span-2">
            <Controller
              name="gradingPricePerItemInDisplayCurrency"
              control={form.control}
              render={({ field, fieldState }) => (
                <FieldLabelBox
                  className="col-span-3 md:col-span-1"
                  label={
                    <>
                      Grading Cost<span className="md:hidden">&nbsp;per {collectibleName}</span>:
                    </>
                  }
                  fieldId="gradingPricePerItemInDisplayCurrency"
                  error={fieldState.error?.message}
                  optional
                  variant="neater"
                >
                  <PriceField
                    {...field}
                    onChange={(value, name) => {
                      field.onChange({ target: { value, name } });
                      const values = form.getValues();
                      if (values.quantity) {
                        form.setValue('gradingPriceTotalInDisplayCurrency', values.quantity * (value ?? 0));
                      }
                    }}
                    id="gradingPricePerItemInDisplayCurrency"
                    placeholder={`Per ${collectibleName}`}
                    min={0}
                  />
                </FieldLabelBox>
              )}
            />
            <OrText />
            <Controller
              name="gradingPriceTotalInDisplayCurrency"
              control={form.control}
              render={({ field, fieldState }) => (
                <FieldLabelBox
                  className="col-span-3 md:col-span-1"
                  label={
                    <>
                      Total<span className="md:hidden">&nbsp;Grading Cost</span>:
                    </>
                  }
                  fieldId="gradingPriceTotalInDisplayCurrency"
                  error={fieldState.error?.message}
                  optional
                  variant="neater"
                  labelClassName="md:invisible"
                >
                  <PriceField
                    {...field}
                    onChange={(value, name) => {
                      field.onChange({ target: { value, name } });
                      const values = form.getValues();
                      if (values.quantity) {
                        form.setValue('gradingPricePerItemInDisplayCurrency', (value ?? 0) / values.quantity);
                      }
                    }}
                    id="gradingPriceTotalInDisplayCurrency"
                    placeholder="Total"
                    min={0}
                  />
                </FieldLabelBox>
              )}
            />
          </FieldsPairContainer>
        )}
        <Controller
          name="categoryId"
          control={form.control}
          render={({ field, fieldState }) => (
            <CollectionCategoryFilter
              className="col-span-3 md:col-span-2"
              label="Category"
              id="categoryId"
              collectibleType={collectibleType}
              onChange={(value) => field.onChange({ target: { value, name: field.name } })}
              selectedCategoryId={field.value ?? null}
              noValueLabel="None"
              autoSelectAdded
              error={fieldState.error?.message}
              variant="neater"
            />
          )}
        />
        <Controller
          name="notes"
          control={form.control}
          render={({ field, fieldState }) => (
            <FieldLabelBox
              className={clsx('col-span-3 md:row-start-1 md:col-span-1 md:col-start-3', {
                'md:row-span-3': isGradedCollectible,
                'row-span-2': !isGradedCollectible,
              })}
              label="Notes:"
              fieldId="notes"
              extraInfo="Do not use commas if you plan to Download to CSV"
              error={fieldState.error?.message}
              variant="neater"
            >
              <TextField {...field} id="notes" isMultiLine rows={5} />
            </FieldLabelBox>
          )}
        />
        <EditFormButtons
          className="col-span-3 md:col-span-1 md:col-start-3 md:row-start-4"
          onCancel={onCancel}
          isLoading={isLoading}
        />
      </section>
    </EditForm>
  );
}

function FieldsPairContainer({ children, className }: { children: ReactNode; className?: string }) {
  return <section className={clsx('grid grid-cols-[1fr_0px_1fr] gap-4 md:gap-6', className)}>{children}</section>;
}

function OrText({ className }: { className?: string }) {
  return (
    <div
      className={clsx(
        'col-span-3 md:col-span-1 w-full overflow-visible hidden md:flex items-center justify-center',
        className
      )}
    >
      <p className="-left-1/2 uppercase font-semibold text-lg m-0">OR</p>
    </div>
  );
}

export function getEditCollectionItemPurchaseDetailsFormInitialValues({
  collectionItem,
  convertPriceFromUSD,
}: {
  convertPriceFromUSD: ConvertPriceFn;
  collectionItem: CollectionItem | null | undefined;
}): FormSchema {
  const quantity = collectionItem?.quantity ?? 1;
  const purchasePricePerItemInDisplayCurrency =
    convertPriceFromUSD({ value: collectionItem?.purchasePricePerItem }) ?? 0;
  const gradingPricePerItemInDisplayCurrency = convertPriceFromUSD({ value: collectionItem?.gradingPricePerItem }) ?? 0;
  return {
    quantity,
    purchasePricePerItemInDisplayCurrency,
    purchasePriceTotalInDisplayCurrency: quantity * purchasePricePerItemInDisplayCurrency,
    gradingPricePerItemInDisplayCurrency,
    gradingPriceTotalInDisplayCurrency: quantity * gradingPricePerItemInDisplayCurrency,
    purchaseDate: collectionItem?.datePurchased ?? formatISODate(new Date()),
    categoryId: collectionItem?.collectionCategories?.[0]?.id ?? null,
    notes: collectionItem?.note ?? '',
  };
}
