import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { collectibleNames } from '../../../../sci-ui-components/collectibles/constants';
import FieldLabelBox from '../../../../sci-ui-components/forms/FieldLabelBox/FieldLabelBox';
import { CollectibleType } from '../../../../sci-ui-components/types/collectibleType';
import FormFieldsRow from '../../../../sci-ui-components/forms/FormFieldsRow/FormFieldsRow';
import QuantityField from '../../../../sci-ui-components/forms/QuantityField/QuantityField';
import DateSelector from '../../../../sci-ui-components/forms/DateSelector/DateSelector';
import PriceField from '../../../../sci-ui-components/forms/PriceField/PriceField';
import useToday, { getCurrentDay } from '../../../../sci-ui-components/hooks/useToday';
import { formatISODate } from '../../../../sci-ui-components/utils/date';
import { CollectionItem, SportsCardCollectionItem } from '../../../../sci-ui-components/types/collectionItem';
import FormSection from '../../../../sci-ui-components/forms/FormSection/FormSection';
import useCollectionItemMutations from '../../useCollectionItemMutations';
import FormButtons from '../FormButtons';
import { PurchaseDetailsFormValues } from './types';
import useCurrencyFormatter, { ConvertPriceFn } from '@/hooks/useCurrencyFormatter';

export default function PurchaseDetailsForm({
  collectibleType,
  initialPurchaseDetails,
  onSave,
  onCancel,
  dialogId,
  collectionItemId,
  isCustom,
  isGradedCollectible,
}: {
  collectibleType: CollectibleType;
  initialPurchaseDetails: PurchaseDetailsFormValues | null;
  onSave: (values: PurchaseDetailsFormValues) => void;
  onCancel: () => void;
  dialogId: string;
  collectionItemId: number | null;
  isCustom: boolean;
  isGradedCollectible: boolean;
}) {
  const today = useToday();
  const { convertPriceToUSD } = useCurrencyFormatter();
  const { updateCollectionItem } = useCollectionItemMutations();

  const formId = `${dialogId}-purchase`;
  const purchaseQuantityFieldId = `${formId}-purchaseQuantity`;
  const purchasePricePerItemFieldId = `${formId}-purchasePricePerItem`;
  const purchasePriceTotalFieldId = `${formId}-purchasePriceTotal`;
  const gradingPricePerItemFieldId = `${formId}-gradingPricePerItem`;
  const gradingPriceTotalFieldId = `${formId}-gradingPriceTotal`;
  const purchaseDateFieldId = `${formId}-purchaseDate`;

  const handleSubmit = async (values: PurchaseDetailsFormValues) => {
    if (collectionItemId) {
      await updateCollectionItem.mutateAsync({
        purchaseDetails: {
          collectibleType,
          collectionItemId,
          isCustom,
          quantity: values.purchaseQuantity!,
          purchaseDate: values.purchaseDate!,
          purchasePricePerItem: convertPriceToUSD({ value: values.purchasePricePerItemInDisplayCurrency }) ?? 0,
          gradingPricePerItem: convertPriceToUSD({ value: values.gradingPricePerItemInDisplayCurrency }),
        },
        collectibleType,
      });
    }
    onSave(values);
  };

  const isEdit = !!collectionItemId;

  return (
    <Formik<PurchaseDetailsFormValues>
      initialValues={
        initialPurchaseDetails ?? {
          purchaseDate: formatISODate(today),
          purchaseQuantity: 1,
          purchasePricePerItemInDisplayCurrency: 0,
          purchasePriceTotalInDisplayCurrency: null,
          gradingPricePerItemInDisplayCurrency: null,
          gradingPriceTotalInDisplayCurrency: null,
        }
      }
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ values, handleChange, errors }) => (
        <Form id={formId}>
          <FormSection title="Purchase Details">
            <FormFieldsRow>
              <FieldLabelBox label="Quantity" fieldId={purchaseQuantityFieldId} error={errors.purchaseQuantity}>
                <QuantityField
                  value={values.purchaseQuantity}
                  name="purchaseQuantity"
                  onChange={(value, name) => {
                    handleChange({ target: { value, name } });
                    if (values.purchasePricePerItemInDisplayCurrency && value) {
                      handleChange({
                        target: {
                          value: values.purchasePricePerItemInDisplayCurrency * value,
                          name: 'purchasePriceTotalInDisplayCurrency',
                        },
                      });
                    }
                    if (values.gradingPricePerItemInDisplayCurrency && value) {
                      handleChange({
                        target: {
                          value: values.gradingPricePerItemInDisplayCurrency * value,
                          name: 'gradingPricePerItemInDisplayCurrency',
                        },
                      });
                    }
                  }}
                  placeholder="Enter qty.."
                  min={1}
                  id={purchaseQuantityFieldId}
                  integer
                />
              </FieldLabelBox>
              <FieldLabelBox label="Purchase Date" fieldId={purchaseDateFieldId} error={errors.purchaseDate}>
                <DateSelector
                  value={values.purchaseDate ?? ''}
                  name="purchaseDate"
                  onChange={(value, name) => handleChange({ target: { value, name } })}
                  id={purchaseDateFieldId}
                />
              </FieldLabelBox>
            </FormFieldsRow>
            <FormFieldsRow>
              <FieldLabelBox
                label={`Purchase Price Per ${collectibleNames[collectibleType]?.shortSingular}`}
                fieldId={purchasePricePerItemFieldId}
                error={errors.purchasePricePerItemInDisplayCurrency}
              >
                <PriceField
                  value={values.purchasePricePerItemInDisplayCurrency}
                  name="purchasePricePerItemInDisplayCurrency"
                  onChange={(value, name) => {
                    handleChange({ target: { value, name } });
                    if (values.purchaseQuantity && (value || value === 0)) {
                      handleChange({
                        target: { name: 'purchasePriceTotalInDisplayCurrency', value: value * values.purchaseQuantity },
                      });
                    }
                  }}
                  placeholder="Enter price.."
                  min={0}
                  id={purchasePricePerItemFieldId}
                />
              </FieldLabelBox>
              <FieldLabelBox
                label={`Purchase Price Total`}
                fieldId={purchasePriceTotalFieldId}
                error={errors.purchasePriceTotalInDisplayCurrency}
              >
                <PriceField
                  value={values.purchasePriceTotalInDisplayCurrency}
                  name="purchasePriceTotalInDisplayCurrency"
                  onChange={(value, name) => {
                    handleChange({ target: { value, name } });
                    if (values.purchaseQuantity && (value || value === 0)) {
                      handleChange({
                        target: {
                          name: 'purchasePricePerItemInDisplayCurrency',
                          value: value / values.purchaseQuantity,
                        },
                      });
                    }
                  }}
                  min={0}
                  id={purchasePriceTotalFieldId}
                />
              </FieldLabelBox>
            </FormFieldsRow>
            {!!isGradedCollectible && (
              <FormFieldsRow>
                <FieldLabelBox
                  label={`Grading Price Per ${collectibleNames[collectibleType]?.shortSingular}`}
                  fieldId={gradingPricePerItemFieldId}
                  error={errors.gradingPricePerItemInDisplayCurrency}
                >
                  <PriceField
                    value={values.gradingPricePerItemInDisplayCurrency}
                    name="gradingPricePerItemInDisplayCurrency"
                    onChange={(value, name) => {
                      handleChange({ target: { value, name } });
                      if (values.purchaseQuantity && (value || value === 0)) {
                        handleChange({
                          target: {
                            name: 'gradingPriceTotalInDisplayCurrency',
                            value: value * values.purchaseQuantity,
                          },
                        });
                      }
                    }}
                    placeholder="Enter price.."
                    min={0}
                    id={gradingPricePerItemFieldId}
                  />
                </FieldLabelBox>
                <FieldLabelBox
                  label={`Grading Price Total`}
                  fieldId={gradingPriceTotalFieldId}
                  error={errors.gradingPriceTotalInDisplayCurrency}
                >
                  <PriceField
                    value={values.gradingPriceTotalInDisplayCurrency}
                    name="gradingPriceTotalInDisplayCurrency"
                    onChange={(value, name) => {
                      handleChange({ target: { value, name } });
                      if (values.purchaseQuantity && (value || value === 0)) {
                        handleChange({
                          target: {
                            name: 'gradingPricePerItemInDisplayCurrency',
                            value: value / values.purchaseQuantity,
                          },
                        });
                      }
                    }}
                    min={0}
                    id={gradingPriceTotalFieldId}
                  />
                </FieldLabelBox>
              </FormFieldsRow>
            )}
          </FormSection>
          <FormButtons isEdit={isEdit} isLoading={updateCollectionItem.isLoading} onCancel={onCancel} />
        </Form>
      )}
    </Formik>
  );
}

const validationSchema: Yup.SchemaOf<PurchaseDetailsFormValues> = Yup.object().shape({
  purchaseQuantity: Yup.number().default(1).min(1, 'Must be at least 1').typeError('Must be a number'),
  purchasePricePerItemInDisplayCurrency: Yup.number()
    .default(0)
    .min(0, 'Must be a positive number')
    .typeError('Must be a number')
    .required('Required'),
  purchasePriceTotalInDisplayCurrency: Yup.number()
    .nullable() // NOTE: we do not actually store this field so it is only populated on UI for UX
    .default(null)
    .min(0, 'Must be a positive number')
    .typeError('Must be a number'),
  gradingPricePerItemInDisplayCurrency: Yup.number()
    .nullable()
    .default(null)
    .min(0, 'Must be a positive number')
    .typeError('Must be a number')
    .notRequired(),
  gradingPriceTotalInDisplayCurrency: Yup.number()
    .nullable()
    .default(null)
    .min(0, 'Must be a positive number')
    .typeError('Must be a number')
    .notRequired(),
  purchaseDate: Yup.string().nullable().required('Required'),
});

export function getPurchaseDetailsInitialValuesFromCollectionItem({
  collectionItem,
  convertPriceFromUSD,
}: {
  collectionItem: CollectionItem | null;
  convertPriceFromUSD: ConvertPriceFn;
}): PurchaseDetailsFormValues | null {
  if (!collectionItem) {
    return null;
  }
  return {
    purchaseDate: collectionItem.datePurchased,
    purchaseQuantity: collectionItem.quantity,
    purchasePricePerItemInDisplayCurrency: convertPriceFromUSD({ value: collectionItem.purchasePricePerItem }) ?? 0,
    purchasePriceTotalInDisplayCurrency: convertPriceFromUSD({ value: collectionItem.purchasePriceTotal }) ?? 0,
    gradingPricePerItemInDisplayCurrency:
      convertPriceFromUSD({ value: (collectionItem as SportsCardCollectionItem).gradingPricePerItem }) ?? 0,
    gradingPriceTotalInDisplayCurrency:
      convertPriceFromUSD({ value: (collectionItem as SportsCardCollectionItem).gradingPriceTotal }) ?? 0,
  };
}

export function getInitialPurchaseDetailValues(
  initialPurchaseDetails?: PurchaseDetailsFormValues | null
): PurchaseDetailsFormValues {
  return {
    purchaseDate: initialPurchaseDetails?.purchaseDate ?? formatISODate(getCurrentDay()),
    purchaseQuantity: initialPurchaseDetails?.purchaseQuantity ?? 1,
    purchasePriceTotalInDisplayCurrency: initialPurchaseDetails?.purchasePriceTotalInDisplayCurrency ?? null,
    purchasePricePerItemInDisplayCurrency: initialPurchaseDetails?.purchasePricePerItemInDisplayCurrency ?? null,
    gradingPriceTotalInDisplayCurrency: initialPurchaseDetails?.gradingPriceTotalInDisplayCurrency ?? null,
    gradingPricePerItemInDisplayCurrency: initialPurchaseDetails?.gradingPricePerItemInDisplayCurrency ?? null,
  };
}
