import { z } from 'zod';
import { Controller, useFormContext } from 'react-hook-form';
import { CustomCollectible } from '@sportscardinvestor/schemas';
import FieldLabelBox from '@/sci-ui-components/forms/FieldLabelBox/FieldLabelBox';
import TextField from '@/sci-ui-components/forms/TextField/TextField';

export const customQueryFormSchema = z.object({
  customQuery: z.string().nullable().default(null),
});

type CustomQueryFormSchema = z.input<typeof customQueryFormSchema>;
type CustomQueryFormPayload = z.output<typeof customQueryFormSchema>;

export function CustomQueryField({ className }: { className?: string }) {
  const form = useFormContext<CustomQueryFormSchema, unknown, CustomQueryFormPayload>();

  return (
    <Controller
      name="customQuery"
      control={form.control}
      render={({ field, fieldState }) => (
        <FieldLabelBox
          className={className}
          label="Custom Query"
          fieldId="customQuery"
          error={fieldState.error?.message}
        >
          <TextField {...field} id="customQuery" isMultiLine rows={2} />
        </FieldLabelBox>
      )}
    />
  );
}

export function getCustomQueryInitialValues({
  collectible,
}: {
  collectible: CustomCollectible | null;
}): CustomQueryFormSchema {
  if (!collectible) {
    return {};
  }
  return {
    customQuery: collectible.customQuery,
  };
}
