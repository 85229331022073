import { z } from 'zod';
import { Controller, useFormContext } from 'react-hook-form';
import { CustomCollectible } from '@sportscardinvestor/schemas';
import {
  getCollectibleSetName,
  getCollectibleSetYear,
  getCollectibleSportName,
  isSealedCollectible,
  isSportsCardCollectible,
} from '@sportscardinvestor/collectible-helpers';
import FieldLabelBox from '@/sci-ui-components/forms/FieldLabelBox/FieldLabelBox';
import { CardSetsAutoComplete } from '@/features/cardSets/CardSetsAutoComplete';

export const setFormSchema = z.object({
  sportName: z.string().trim().nullable().default(null),
  setYear: z.string().trim().nullable().default(null),
  setName: z
    .string({
      required_error: 'Please enter set name',
    })
    .trim()
    .min(1),
  setId: z.number().nullable().default(null),
});

type SetFormSchema = z.input<typeof setFormSchema>;
type SetFormPayload = z.output<typeof setFormSchema>;

export function SetField({ className }: { className?: string }) {
  const form = useFormContext<SetFormSchema, unknown, SetFormPayload>();

  return (
    <Controller
      name="setName"
      control={form.control}
      render={({ field, fieldState }) => (
        <FieldLabelBox className={className} label="Set" fieldId="setName" error={fieldState.error?.message}>
          <CardSetsAutoComplete
            id="setName"
            value={field.value}
            onSelect={(data) => {
              form.setValue('setId', data?.id ?? null);
              form.setValue('setName', data?.displayText ?? '');
              form.setValue('setYear', data?.year);
              form.setValue('sportName', data?.sport?.name ?? null);
            }}
            onChange={(value) => {
              form.setValue('setId', null);
              form.setValue('setName', value);
              form.setValue('setYear', null);
              form.setValue('sportName', null);
            }}
          />
        </FieldLabelBox>
      )}
    />
  );
}

export function getSetInitialValues({ collectible }: { collectible: CustomCollectible | null }): SetFormSchema {
  if (!collectible) {
    return {
      setName: '',
    };
  }
  return {
    setName: getCollectibleSetName(collectible) ?? '',
    sportName: getCollectibleSportName(collectible),
    setId:
      (isSportsCardCollectible(collectible) || isSealedCollectible(collectible)) && collectible.set?.id
        ? Number(collectible.set?.id)
        : null,
    setYear: getCollectibleSetYear(collectible),
  };
}
