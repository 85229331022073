import { useEffect, useState } from 'react';
import CollectionItemNoteDialog from './CollectionItemNoteDialog';
import { registerDialog, CollectionItemNoteDialogProviderProps } from './index';

type State = Partial<CollectionItemNoteDialogProviderProps> & {
  isOpen: boolean;
};

export default function CollectionItemNoteDialogProvider({ id }: { id: string }) {
  const [{ isOpen, resolve, initialValues }, setState] = useState<State>({
    isOpen: false,
  });

  useEffect(
    () =>
      registerDialog(id, (props) => {
        setState({
          isOpen: true,
          ...props,
        });
      }),
    [id]
  );

  return (
    <CollectionItemNoteDialog
      isOpen={isOpen}
      initialValues={initialValues!}
      onClose={() => setState((s) => ({ ...s, isOpen: false }))}
      onSubmit={() => resolve?.()}
    />
  );
}
