import clsx from 'clsx';
import { CollectibleType } from '../types/collectibleType';
import { PlatformNotification, PlatformStats } from '../types/platform';
import PlatformStatsSummary from './PlatformStatsSummary/PlatformStatsSummary';
import PlatformNotifications from './PlatformNotifications/PlatformNotifications';

import classes from './InfoBar.module.scss';

export default function InfoBar({
  className,
  platformStats,
  collectibleType,
  notifications,
}: {
  className?: string;
  platformStats?: PlatformStats | null;
  collectibleType: CollectibleType;
  notifications: PlatformNotification[];
}) {
  return (
    <div className={clsx(classes.root, className)}>
      <PlatformNotifications
        className={classes.notifications}
        notifications={notifications}
        collectibleType={collectibleType}
      />
      <PlatformStatsSummary className={classes.stats} platformStats={platformStats} collectibleType={collectibleType} />
    </div>
  );
}
