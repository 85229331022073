import { useMemo } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { CardSetVariationExtended } from '../../sci-ui-components/types/cardSetVariationExtended';
import { getCardSetVariations } from '../../services/sciApi/cardSets/index';
import useFilteredData from '../../hooks/useFilteredData';
import { ApiError } from '../../utils/api';
import useDateRangeState from '../../hooks/useDateRangeState';
import useAuth from '../auth/useAuth';
import useCollectibleType from '../collectibles/useCollectibleType';
import useCardSetVariationFilterOptions from './useCardSetVariationFilterOptions';

export type UseCardSetVariationsParams = {
  searchText?: string | null;
  cardSetYears?: number[] | null;
  cardSetNames?: string[] | null;
  cardSetIds?: number[] | null;
  sportIds?: number[] | null;
  playerIds?: number[] | null;
};

export const keyPrefix = 'card-set-vars';
type Key = [typeof keyPrefix];

export default function useCardSetVariations(
  { searchText, cardSetYears, cardSetNames, cardSetIds, sportIds, playerIds }: UseCardSetVariationsParams = {},
  options: UseQueryOptions<CardSetVariationExtended[], ApiError, CardSetVariationExtended[], Key> = {}
) {
  const { isLoggedIn } = useAuth();
  const enabled = !!isLoggedIn && (options?.enabled ?? true);
  const {
    data = [],
    isLoading: isLoadingVariations,
    ...otherQueryResult
  } = useQuery([keyPrefix], ({ signal }) => getCardSetVariations(signal), {
    staleTime: 1000 * 60 * 60 * 24, // 1 day
    ...options,
    enabled,
  });

  // NOTE: hack: useCardSetVariationFilterOptions is lacking data and getCardSetVariations is lacking ability to filter by playerIds => combining them to achieve both
  const { collectibleType } = useCollectibleType();
  const [[isoStartDate, isoEndDate]] = useDateRangeState('All Data');
  const { data: variationOptionsFilteredByPlayer = [], isLoading: isLoadingVariationsFilteredByPlayers } =
    useCardSetVariationFilterOptions(
      {
        sportIds,
        playerIds,
        cardSetIds,
        collectibleType,
        isoEndDate: isoStartDate!,
        isoStartDate: isoEndDate!,
      },
      {
        enabled: enabled && !!playerIds?.length,
      }
    );
  const filterByPlayers = useMemo(() => {
    const setVariationIds = new Set(variationOptionsFilteredByPlayer.map((v) => v.value));
    return ({ setVariationId }: CardSetVariationExtended) =>
      !setVariationIds.size || setVariationIds.has(setVariationId);
  }, [variationOptionsFilteredByPlayer]);
  // NOTE: end of hack

  const filterByYears = useMemo(() => {
    const cardSetYearsSet = new Set(cardSetYears);
    return ({ cardSet: { year } }: CardSetVariationExtended) =>
      !cardSetYearsSet.size || cardSetYearsSet.has(Number(year));
  }, [cardSetYears]);
  const filterBySetNames = useMemo(() => {
    const cardSetNamesSet = new Set(cardSetNames);
    return ({ cardSet: { name } }: CardSetVariationExtended) => !cardSetNamesSet.size || cardSetNamesSet.has(name);
  }, [cardSetNames]);
  const filterBySetIds = useMemo(() => {
    const cardSetIdsSet = new Set(cardSetIds);
    return ({ cardSet: { id } }: CardSetVariationExtended) => !cardSetIdsSet.size || cardSetIdsSet.has(id);
  }, [cardSetIds]);
  const filterBySportIds = useMemo(() => {
    const sportIdsSet = new Set(sportIds);
    return ({ cardSet: { sportId } }: CardSetVariationExtended) => !sportIdsSet.size || sportIdsSet.has(sportId);
  }, [sportIds]);
  const filteredData = useFilteredData(data, 'displayText', searchText, [
    filterByYears,
    filterBySetNames,
    filterBySetIds,
    filterBySportIds,
    filterByPlayers,
  ]);

  return {
    ...otherQueryResult,
    isLoading: isLoadingVariations || isLoadingVariationsFilteredByPlayers,
    data: filteredData,
  };
}
