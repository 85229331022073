import { CollectibleType } from '@sportscardinvestor/schemas';
import { create } from 'zustand';

interface EditManualCollectibleValueDialogState {
  customCollectibleId: number | null;
  collectibleType: CollectibleType;
  close: () => void;
}

const defaultCollectibleType: CollectibleType = 'sports-card';

export const useEditManualCollectibleValueDialogStore = create<EditManualCollectibleValueDialogState>()((set) => ({
  customCollectibleId: null,
  collectibleType: defaultCollectibleType,
  close: () => set({ customCollectibleId: null, collectibleType: defaultCollectibleType }),
}));

export interface EditManualCollectibleValueDialogProps {
  customCollectibleId: number;
  collectibleType: CollectibleType;
}
export function showEditManualCollectibleValueDialog({
  customCollectibleId,
  collectibleType,
}: EditManualCollectibleValueDialogProps) {
  useEditManualCollectibleValueDialogStore.setState({
    customCollectibleId,
    collectibleType,
  });
}
