enum GradingCompanies {
  psa = 'psa',
  bgs = 'bgs',
  sgc = 'sgc',
  cgc = 'cgc',
  raw = 'raw',
}

const colorMap: Record<GradingCompanies, string> = {
  [GradingCompanies.psa]: '#0050a2',
  [GradingCompanies.bgs]: '#76c2c7',
  [GradingCompanies.sgc]: '#000000',
  [GradingCompanies.cgc]: '#be333c',
  [GradingCompanies.raw]: '#898989',
};

function isGradeCompany(value: string): value is GradingCompanies {
  return value in GradingCompanies;
}

export default function GradeChip({ grade = '' }: { grade: string }) {
  const gradeCompany = grade.split(' ')[0].toLowerCase();

  const gradeColor = isGradeCompany(gradeCompany) ? colorMap[gradeCompany] : colorMap[GradingCompanies.raw];

  return (
    <span
      className="flex justify-center w-16 rounded-sm text-white cursor-pointer"
      style={{ backgroundColor: gradeColor }}
    >
      {grade}
    </span>
  );
}
